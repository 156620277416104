import React, { useEffect, useRef, useState } from "react";
import { Dropzone } from "@commonsku/styles";

import { usePublicViewEntityContext } from "../../../context/PublicViewEntityProvider";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";

export default function ShopTitle(props) {
  const {
    editing,
    title,
    title_type,
    isEditable,
    onDropImage,
    onChangeTitle,
    onBlurTitle,
    handleClickTitle,
    PreviewShopTitle,
    style={},
    titleClassName='',
  } = props;
  const {
    entityBuyInventory,
  } = usePublicViewEntityContext();
  const {
    templateColor: template_color,
  } = usePublicViewTemplateDataContext();

  /**
   * @type {React.MutableRefObject<HTMLHeadingElement>}
   */
  const ref = useRef(null);
  const [elemWidths, setElemWidths] = useState({
    clientWidth: 0,
    scrollWidth: 0,
  });

  const h1Style = isEditable && 'title' !== editing ? {
    minWidth: '200px',
    minHeight: '50px',
    cursor: 'pointer',
  } : {};
  const tooltipProps = elemWidths.scrollWidth > elemWidths.clientWidth
    && 'text' === title_type
      ? {
        'data-for': "shop-title-tooltip",
        'data-effect': "solid",
        'data-html': true,
        'data-tip': `${title}${
          !!entityBuyInventory ? '<p style="margin: 0; font-size: "small";}>(Buy Inventory)</p>' : ''
        }`,
      } : {};

  useEffect(() => {
    function updateElemInfo(elem, enteries) {
      let element = elem || ref.current;
      if (elem?.target && elem?.type) {
        element = elem?.target;
      }

      if (!element) { return; }
      setElemWidths({
        scrollWidth: element.scrollWidth || 0,
        clientWidth: element.clientWidth || 0,
      });
    };

    const elem = ref.current;
    if (!elem) { return; }

    const observer = new ResizeObserver((entries) => {
      updateElemInfo(elem, entries[0]);
    });
    observer.observe(elem);

    updateElemInfo(elem);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      id="shop-title"
      style={{ zIndex: 1, paddingRight: 0, ...style }}
      onClick={handleClickTitle}
    >
      <h1 ref={ref}
        className={titleClassName}
        style={{
          ...h1Style,
          lineHeight: 'inherit',
          color: !!entityBuyInventory ? 'white' : template_color,
        }}
        {...tooltipProps}
      >
        {'title' === editing
          ? <div>
              <input
                type="text"
                value={'text' === title_type ? title : ''}
                style={{ float: 'left', width: '67%', marginRight: '0.25rem' }}
                onChange={e => onChangeTitle(e.target.value)}
                onBlur={e => onBlurTitle(e.target.value)}
              />
              <Dropzone
                onDrop={onDropImage}
                style={{ border: 'none', float: 'left' }}
                multiple={false}
                accept="image/*"
              >
                <a className="button" style={{ color: 'white' }}>Use&nbsp;Image</a>
              </Dropzone>
            </div>
          : PreviewShopTitle}
      </h1>
    </div>
  );
}
