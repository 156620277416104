import React from "react";

export default function LinkItem({
  children,
  buy_inventory,
  template_color,
  onClick,
  overrideStyle,
  fontOverrideStyles,
  active,
  underline,
  ...props
}) {
  return (
    <div
      style={{ padding: "16px 16px", margin: 0, width: "100%", ...overrideStyle }}
    >
      <a
        style={{
          color: !!buy_inventory ? "white" : template_color,
          fontFamily: "var(--font-family-bold)",
          fontSize: 21,
          ...fontOverrideStyles,
          ...(underline
            ? {
                background: "#fff",
                ...(active
                  ? {
                      color: template_color,
                      textDecorationLine: "underline",
                      textUnderlinePosition: "under",
                      textDecorationThickness: 2,
                    }
                  : {}),
              }
            : {}),
        }}
        onClick={onClick}
        {...props}
      >
        {children}
      </a>
    </div>
  );
}
