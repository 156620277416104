import React from 'react';
import { Csku } from '@commonsku/styles';

export default function ArrowIcon({
  children,
  template_color,
  onClick,
  direction='left',
  left=null,
  right=null,
  xsSize = '4em',
  mdSize = '5em',
}) {
  return (
    <Csku as="a"
      forceStyles
      onClick={onClick}
      style={{
        xs: {
          color: template_color,
          fontSize: xsSize,
          backgroundColor: 'transparent',
          fontFamily: 'Ionicons',
          padding: '50px 15px',
          top: '50%',
          transform: 'translateY(-50%)',
          position: 'absolute',
          cursor: 'pointer',
          zIndex: 4,
          ...(left !== null ? { left: left, } : {} ),
          ...(right !== null ? { right: right } : {} ),
        },
        md: { fontSize: mdSize }
      }}
    >
      {direction !== 'left' ? children : null}
      {direction === 'left' ? '' : ''}
      {direction === 'left' ? children : null}
    </Csku>
  );
}
