import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { Input } from "@commonsku/styles";
import { TNumber } from "../../interfaces/common";
import { Select } from "../helpers";
import {
  useUpdateProductQuestion,
  useUpdateProductQuestionsError,
} from "../../redux/hooks/product_questions";

interface CartProductQuestion {
  metadata_id: string;
  item_id: string;
  m: {
    label: string;
    value: string;
    type: string;
    mandatory: TNumber;
    options: {
      key: string;
      value: string;
    } | null;
  };
  shop_id: string;
}

const CartProductQuestion = ({
  metadata_id,
  item_id,
  m,
  shop_id,
  setError,
}) => {
  const [updatedProductQ, setUpdatedProductQ] = useState<string>(m.value);
  const onUpdateProductQuestion = useUpdateProductQuestion();
  const onUpdateProductQuestionError = useUpdateProductQuestionsError();
  const mandatory = "" + m.mandatory === "1";
  const initialTextValue = useRef(null);
  useEffect(() => {
    initialTextValue.current = m.value;
  }, []);

  useEffect(() => {
    if (mandatory && !updatedProductQ.trim()) {
      setError(metadata_id, "This field is required");
      onUpdateProductQuestionError(metadata_id, "This field is required");
      onUpdateProductQuestion(
        metadata_id,
        m.label,
        initialTextValue.current,
        item_id,
        m.type,
        m.mandatory,
        null,
        shop_id
      );
      return;
    } else {
      setError(null);
      onUpdateProductQuestionError(null);

      if (m.type === "TEXT") {
        return onUpdateProductQuestion(
          metadata_id,
          m.label,
          updatedProductQ,
          item_id,
          m.type,
          m.mandatory,
          null,
          shop_id
        );
      }
    }
  }, [updatedProductQ]);

  const cartProductQuestions = useMemo(() => {
    return m.type === "TEXT" ? (
      <Input
        type="text"
        style={{ marginBottom: 0 }}
        value={updatedProductQ}
        onChange={(e) => setUpdatedProductQ(e.target.value)}
        data-test-id={`cart-product-question-${metadata_id}`}
      />
    ) : (
      <Select
        value={m.value}
        placeholder={m.value}
        onChange={(e) =>
          onUpdateProductQuestion(
            metadata_id,
            m.label,
            e.value,
            item_id,
            m.type,
            m.mandatory,
            m.options,
            shop_id
          )
        }
        options={m.options || []}
        data-test-id={`cart-product-question-${metadata_id}`}
      />
    );
  }, [metadata_id, item_id, m, updatedProductQ]);
  return (
    <div data-test-id="cart-product-questions">{cartProductQuestions}</div>
  );
};

export default CartProductQuestion;
