import React, { useEffect, useRef, useCallback } from 'react';
import { DoubleArrowIcon } from '@commonsku/styles';
import { getImageSrc, isVisibleHorizontal } from '../../utils';
import Img from '../Img';

export default function ItemImagesSlider({
  images,
  template_color,
  index,
  imageStyle,
  onSelectImage,
}) {
  const parentRef = useRef(null);

  const containerStyles = {
    height: '100%',
    width: '100%',
    transition: 'transform 1s',
    overflow: 'auto hidden',
    whiteSpace: 'nowrap',
  };
  const linkStyles = (idx) => ({
    display: 'inline-block',
    width: 75,
    height: 75,
    textDecoration: 'none',
    ...(index === idx ? {
      border: `5px solid ${template_color}`,
    } : {}),
    ...(idx === 0 ? {
      marginLeft: 14,
    } : {}),
    ...(idx === images.length-1 ? {
      marginLeft: 14,
    } : {}),
  });

  const scrollToImageIndex = useCallback((idx) => {
    const it = images[idx];
    const id = `item_image-link-${it.item_id}-${it.file.file_id}`;
    const elem = document.getElementById(id);
    if (!elem) { return; }
    elem.scrollIntoView({
      behavior: 'smooth', block: 'nearest', inline: 'center',
    });
  }, [images]);

  function getVisibleStartEndElemIdx() {
    let startIdx = -1,
        endIdx = -1;
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const id = `item_image-link-${image.item_id}-${image.file.file_id}`;
      const elem = document.getElementById(id);
      if (!elem) { continue; }
      const isVlisible = isVisibleHorizontal(elem, parentRef.current);
      if (startIdx >= 0 && !isVlisible) { break; }
      if (isVlisible) {
        if (startIdx === -1) {
          startIdx = i;
        } else {
          endIdx = i;
        }
      }
    }
    return [startIdx, endIdx];
  }

  function slideNext() {
    const [start, end] = getVisibleStartEndElemIdx();
    if (end === -1 || end >= images.length || !images[end]) { return; }
    scrollToImageIndex(end === images.length-1 ? end : end + 1);
  }

  function slidePrev() {
    const [start, end] = getVisibleStartEndElemIdx();
    if (start < 0 || !images[start]) { return; }
    scrollToImageIndex(start === 0 ? 0 : start - 1);
  }

  useEffect(() => {
    if (index < 0) { return; }
    scrollToImageIndex(index);
  }, [index, scrollToImageIndex]);

  return (
    <>
      <div ref={parentRef} style={{
        ...containerStyles,
        width: 'calc(100vw - 45px)',
        marginLeft: 14,
      }}>
        {images.map((i, idx) =>
          <a key={`item_image-${i.item_id}-${i.file.file_id}-${idx}`}
            id={`item_image-link-${i.item_id}-${i.file.file_id}`}
            style={linkStyles(idx)}
            onClick={() => onSelectImage(i.item_id)}
          >
            <Img style={{
              ...imageStyle,
              height: 60,
            }} src={getImageSrc(i.file, 'large')} />
          </a>)}
      </div>

      <div onClick={slidePrev} style={{
        color: 'teal',
        left: 0,
        fontSize: '3em',
        top: 42,
        transform: 'translateY(-50%)',
        position: 'absolute',
        zIndex: 4,
      }}>
        <DoubleArrowIcon
          direction="left"
          color={template_color}
          style={{
            margin: 4,
            background: 'linear-gradient(180deg, #DFEEF4 0%, rgba(166, 194, 198, 0.08) 100%)',
          }}
        />
      </div>

      <div onClick={slideNext} style={{
        color: 'teal',
        right: 0,
        fontSize: '3em',
        top: 42,
        transform: 'translateY(-50%)',
        position: 'absolute',
        zIndex: 4,
      }}>
        <DoubleArrowIcon
          direction="right"
          color={template_color}
          style={{
            margin: 4,
            background: 'linear-gradient(180deg, #DFEEF4 0%, rgba(166, 194, 198, 0.08) 100%)',
          }}
        />
      </div>
    </>
  );
}
