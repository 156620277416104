import { AddressAutocompleteInput, Col, colors, InputDropdown, LabeledInput, Row, Text } from "@commonsku/styles";
import { get, pick } from "lodash";
import React from "react";
import { countries, countries_map, states } from "../../../geo";
import { LabeledSelect } from "../../helpers";
import config from "../../../config";

export const available_countries = ['us', 'ca', 'uk', 'au', 'nz'];
const country_map = available_countries.reduce((acc, c) => {
  const found = countries_map[c];
  if (!found) return acc;
  return { ...acc, [found]: c };
}, {});

export default function EnterAddress({
  field,
  address,
  showErrors,
  onChange,
  onFocus,
  onUpdateAddress,
  required_full_address=false,
  allowed_countries = 'ALL',
}) {
  const base_states = get(states, address.address_country, []);
  const state_options = [{ key: '', value: address.address_country === 'Canada' ? 'Select a Province...' : 'Select a State...' }].concat(base_states.map(s => ({ key: s.abbr, value: s.name })));
  const hasLine1Error = showErrors && !(address.address_line_1.trim());
  const hasCityError = showErrors && required_full_address && !(address.address_city.trim());
  const hasCountryError = showErrors && required_full_address && !(address.address_country.trim());
  const hasStateError = showErrors && required_full_address && !(address.address_state.trim());
  const hasPostalError = showErrors && required_full_address && !(address.address_postal.trim());

  const selectAllCountries = !allowed_countries || allowed_countries === 'ALL';

  const country_options = (
    selectAllCountries
      ? countries
      : countries.filter(c => allowed_countries.includes(country_map[c] || c))
  ).map(c => ({ key: c, value: c }));

  return (
    <Row>
      <Col xs>
        <AddressAutocompleteInput
          apiKey={config.googleMapsApiKey}
          key={field + 'aa1'}
          id={`${field}-address_line_1`}
          type="text"
          required
          error={hasLine1Error}
          label='Address Line 1'
          placeholder="123 Street St."
          value={address.address_line_1}
          country={selectAllCountries ? undefined : allowed_countries}
          onFocus={onFocus(field + 'aa1')}
          style={{ marginBottom: '0' }}
          onChange={v => {
            const addr = v.parsed_address;
            onUpdateAddress?.(addr);
          }}
          onInputChange={v => {
            onChange('address_line_1')({ value: v });
          }}
          optionsListStyle={{ marginTop: 65 }}
        />
      </Col>
      {hasLine1Error && <Col xs style={{ paddingTop: 8 }}>
        <Text style={{ color: colors.errors.main }}>This field is required</Text>
      </Col>}

      <Col xs style={{ paddingTop: 16 }}>
        <LabeledInput
          key={field + 'aa2'}
          id={`${field}-address_line_2`}
          type="text"
          label='Address Line 2'
          placeholder="Suite Number"
          value={address.address_line_2}
          onChange={onChange('address_line_2')}
          onFocus={onFocus(field + 'aa2')}
          style={{ marginBottom: 0, }}
        />
      </Col>
      <Col xs md={6} mdStyle={{ paddingRight: 8 }} style={{ paddingTop: 16 }}>
        <LabeledInput
          key={field + 'aa3'}
          id={`${field}-address_city`}
          type="text"
          label='City'
          placeholder="City"
          value={address.address_city}
          onChange={onChange('address_city')}
          onFocus={onFocus(field + 'aa3')}
          style={{ marginBottom: 0, }}
          error={hasCityError}
          required={required_full_address}
        />
      </Col>
      {hasCityError && <Col xs md={false} style={{ paddingTop: 8 }}>
        <Text style={{ color: colors.errors.main }}>This field is required</Text>
      </Col>}
      <Col xs md={6} mdStyle={{ paddingLeft: 8 }} style={{ paddingTop: 16 }}>
        <LabeledSelect
          key={field + 'aa6'}
          name={field + 'aa6'}
          id={`${field}-address_country`}
          type="text"
          label='Country'
          placeholder="Country"
          options={country_options}
          value={address.address_country}
          onChange={onChange('address_country')}
          onFocus={onFocus(field + 'aa6')}
          parentStyle={{ marginTop: '-8px', }}
          error={hasCountryError}
          required={required_full_address}
        />
      </Col>
      {hasCityError && <Col xs={false} sm={false} md={6} mdOffsetRight={hasCountryError ? 0 : 6} style={{ paddingTop: 8 }}>
        <Text style={{ color: colors.errors.main }}>This field is required</Text>
      </Col>}
      {hasCountryError && <Col xs md={6} mdOffset={hasCityError ? 0 : 6} style={{ paddingTop: 8 }}>
        <Text style={{ color: colors.errors.main }}>This field is required</Text>
      </Col>}
      <Col xs md={6}  mdStyle={{ paddingRight: 8 }} style={{ paddingTop: 16 }}>
        {base_states.length ?
          <LabeledSelect
            key={field + 'aa4'}
            name={field + 'aa4'}
            id={`${field}-address_state`}
            type="text"
            label='State/Province'
            placeholder="State/Province"
            options={state_options}
            value={address.address_state}
            onChange={onChange('address_state')}
            onFocus={onFocus(field + 'aa4')}
            parentStyle={{ marginTop: '-8px', }}
            error={hasStateError}
            required={required_full_address}
          /> :
          <LabeledInput
            key={field + 'aa4'}
            id={`${field}-address_state`}
            type="text"
            label='State/Province'
            placeholder="State/Province"
            value={address.address_state}
            onChange={onChange('address_state')}
            onFocus={onFocus(field + 'aa4')}
            style={{ marginBottom: 0, }}
            error={hasStateError}
            required={required_full_address}
          />}
      </Col>
      {hasStateError && <Col xs md={false} style={{ paddingTop: 8 }}>
        <Text style={{ color: colors.errors.main }}>This field is required</Text>
      </Col>}
      <Col xs md={6} mdStyle={{ paddingLeft: 8 }} style={{ paddingTop: 16 }}>
        <LabeledInput
          key={field + 'aa5'}
          id={`${field}-address_postal`}
          type="text"
          label='Zip/Postal Code'
          placeholder="Zip / Postal Code"
          value={address.address_postal}
          onChange={onChange('address_postal')}
          onFocus={onFocus(field + 'aa5')}
          style={{ marginBottom: 0, }}
          error={hasPostalError}
          required={required_full_address}
        />
      </Col>
      {hasStateError && <Col xs={false} sm={false} md={6} style={{ paddingTop: 8 }}>
        <Text style={{ color: colors.errors.main }}>This field is required</Text>
      </Col>}
      {hasPostalError && <Col xs md={6} mdOffset={hasStateError ? 0 : 6} style={{ paddingTop: 8 }}>
        <Text style={{ color: colors.errors.main }}>This field is required</Text>
      </Col>}
    </Row>
  );
}
