import React from 'react';
import { useSelector } from 'react-redux';

import { getPublicViewTemplateDropdown } from '../../selectors/dropdowns';
import PublicViewTemplateComponent from './PublicViewTemplateComponent';

const Home = ({
  hideIntro = false,
  isPreviewing = false,
}) => {

  const public_view_templates = useSelector(getPublicViewTemplateDropdown);

  return (
    <div className={'shop shop-v3'}>
      <PublicViewTemplateComponent
        isEditable={false}
        public_view_templates={public_view_templates}
        hideIntro={hideIntro}
        isPreviewing={isPreviewing}
      />
    </div>
  );
};

export default Home;
