import React from 'react';
import {
  Text,
  colors,
} from '@commonsku/styles';
import { getOrderTotal, isArtworkOk, isMinimumQuantityOk, isTotalOk } from './helpers';
import { usePublicViewEntityContext } from '../../../context/PublicViewEntityProvider';

const CartErrors = React.memo(({ cart }) => {
  const {
    entityClientId: client_id,
    entityAggregate: aggregate,
    perCheckoutLimit: per_checkout_limit,
    entityBuyInventory: buy_inventory,
    entityForceMinimumQty: force_minimum_qty,
    entityIsFree: is_shop_free,
  } = usePublicViewEntityContext();

  const itemInCart = !!cart.items.length;
  const orderTotal = getOrderTotal({ cart });
  const minimumQuantityOk = isMinimumQuantityOk({ buy_inventory, force_minimum_qty, aggregate, cart });
  const artworkOk = isArtworkOk({ cart, client_id });
  const totalOk = isTotalOk({ orderTotal, per_checkout_limit });

  let message;
  if (!minimumQuantityOk) {
    message = 'Minimum quantity not met';
    if (is_shop_free == 1) {
      message = itemInCart ? 'Please select the variant above to check out.' : 'Please select a product.';
    }
  } else if (!artworkOk) {
    message = 'Artwork not uploaded';
  } else if (!totalOk) {
    message = 'Edit quantities to fit within spending limit in order to checkout';
  }

  if (!message) { return null; }
  return <Text style={{ fontSize: '16px', color: colors.errors.main }}>{message}</Text>;
});

export default CartErrors;
