import React, { useState } from 'react';

import { getImageSrc } from '../../../utils';

import Img from '../../Img';
import { useSelector } from 'react-redux';
import { getItemComments } from '../../../selectors/items';
import TileComments from '../TileComments';
import Orders from '../Orders';
import Inventory from '../Inventory';
import { DEFAULT_COLOR } from '../utils';

export const DEFAULT_TILE_WIDTH = 300;
export const DEFAULT_TILE_HEIGHT = 300;
export const DEFAULT_TILE_PADDING = 5;

const TileInline = React.forwardRef((props, ref) => {
  const itemComments = useSelector(s => getItemComments(s, { item_id: props.item.item_id }));

  const [state, setState] = useState({ hover: false });

  function onMouseEnter(e) {
    setState(s => ({ ...s, hover: true }));
  }

  function onMouseLeave(e) {
    setState(s => ({ ...s, hover: false }));
  }

  const {
    item,
    currency_symbol,
    onImageLoad,
    is_shop_free,
    showInventory,
    buyInventory,
    onAddItem,
    onRemoveItem,
    hide_pricing = '0',
    template_color = DEFAULT_COLOR,
    showItemCount = true,
    useV2 = false,
    width = DEFAULT_TILE_WIDTH,
    height = DEFAULT_TILE_HEIGHT,
    padding = DEFAULT_TILE_PADDING,
    imgSize = 'large'
  } = props;
  if ('OPTION' !== item.parent_type) {
    return null;
  }

  let item_prices = [];
  if (showInventory && !buyInventory && (item.inventory_items ?? []).length > 0) {
    item_prices = item.inventory_items.map(ii => parseFloat(ii.price));
  } else if (item.options && item.options.length) {
    item_prices = item.options.filter(o => 0 == o.hidden && 0 != o.unit_price).map(o => parseFloat(o.unit_price));
  } else if (item.min_price && item.max_price) {
    item_prices = [item.min_price, item.max_price];
  }
  const minimum_price = Math.min.apply(null, item_prices);
  const maximum_price = Math.max.apply(null, item_prices);
  const displayPrice = +hide_pricing !== 1 && item_prices.length ? (
    minimum_price === maximum_price
      ? `${currency_symbol}${minimum_price.toFixed(2)}`
      : `${currency_symbol}${minimum_price.toFixed(2)} - ${currency_symbol}${maximum_price.toFixed(2)}`
  ) : null;

  const baseImageStyle = {
    cursor: 'pointer',
    WebkitTransition: 'all 1s',
    transition: 'all 1s',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%) ' + (state.hover ? 'scale(1.05, 1.05)' : 'scale(1, 1)'),
    opacity: state.hover ? 0.2 : 1,
  };

  const ratio = width / (height + 2 * padding);

  const imageStyle = ratio < 1 ? { ...baseImageStyle, height: '100%' } : { ...baseImageStyle, width: '100%' };

  const detailsStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    paddingTop: '100px',
    textAlign: 'center',
    cursor: 'pointer',
    width: '100%',
    WebkitTransition: 'all 1s',
    transition: 'all 1s',
    opacity: state.hover ? 1 : 0,
    fontWeight: 'bold'
  };

  const onClick = props.onClick ?
    e => props.onClick() :
    null;

  return (
    <div
      ref={ref}
      id={'item-'+item.item_id}
      style={{
        position: 'relative',
        float: 'left',
        padding: `${2 * padding}px ${padding}px 0 ${padding}px`,
        width,
        height: height + 2 * padding,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <div style={{ overflow: 'hidden', background: 'white', textAlign: 'center', height: '100%' }}>
        <Img src={getImageSrc(item.images[0], imgSize)} onLoad={onImageLoad} style={imageStyle} />
      </div>
      <div style={detailsStyle}>
        <p style={{ fontSize: '1.2em' }}>{item.item_name}</p>
        {is_shop_free !=1 && <p style={{ fontSize: '1em' }}>{item.price_label}</p>
        && <p>{displayPrice}</p>}
      </div>
      <TileComments
        item={item}
        comments={itemComments}
        showComments={props.showComments ?? true}
      />
      <Orders
        hover={state.hover}
        item={item}
        onAddItem={onAddItem}
        onRemoveItem={onRemoveItem}
        padding={padding}
        template_color={template_color}
        showItemCount={showItemCount}
        useV2={useV2}
        right={padding || '1rem'}
      />
      <Inventory
        item={item}
        template_color={template_color}
        showInventory={showInventory}
      />
    </div>
  );
});

export default TileInline;
