import { each, get, map } from 'lodash';
import React from 'react';
import { formatNumber } from '../helpers';

const AvalaraShopTaxSummary = ({ avalara_summary }) => {
  const summary = {};
  each(avalara_summary, ({ region, taxCalculated }) => {
    summary[region] = get(summary, region, 0) + taxCalculated;
  });
  return <table>
    <tbody style={{ background: 'none' }}>
      {map(summary, (amount, label) => {
        return <tr key={label} data-test-name="avalara-tax-row" data-test-id={"avalara-row-" + label}>
          <td style={{ background: 'none', textAlign: 'left' }} data-test-name="avalara-tax-label" data-test-id={"avalara-label-" + label}>{label}: </td>
          <td style={{ background: 'none' }} data-test-name="avalara-tax-value" data-test-id={"avalara-value-" + label}>${formatNumber(amount, true)}</td>
        </tr>;
      })}
    </tbody>
  </table>;
};

export default AvalaraShopTaxSummary;
