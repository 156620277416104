export function getOrderTotal({ cart }) {
  return cart.items.reduce((t, i) => parseFloat(t) + parseFloat(i.subtotal), 0.00000);
}

export function isTotalOk({ per_checkout_limit, orderTotal }) {
  return per_checkout_limit ?
    orderTotal <= per_checkout_limit :
    true;
}

export function isArtworkOk({ client_id, cart }) {
  return !!client_id || cart.items.reduce((ok, i) => ok && i.artworks.reduce((ok2, a) => ok2 && !!a.image, true), true);
}

export function isMinimumQuantityOk({ buy_inventory, force_minimum_qty, aggregate, cart }) {
  const itemInCart = !!cart.items.length;
  return itemInCart && cart.items.reduce((ok, i) => {
    if (!ok) {
      return ok;
    }
    if (!buy_inventory && i.inventory_items.length > 0) {
      // If this is an item with inventory and we are not buying inventory do nothing
      return i.quantity > 0;
    } else if ((aggregate == 1 && force_minimum_qty == 1) || aggregate == 0) {
      return i.quantity >= i.minimum_quantity;
    } else {
      return i.quantity > 0;
    }
  }, true);
}
