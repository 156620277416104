import { get } from "lodash";

export function getAddressCityAndState(address) {
  const city = (get(address, ['address_city']) || get(address, ['city']) || '').trim();
  const state = (get(address, ['address_state']) || get(address, ['state']) || '').trim();
  return [
    city + (city ? ', ' : ''),
    state + (state ? ',' : ''),
  ].join(' ').trim();
}

export function getAddressPostalCodeAndCountry(address) {
  const postal = (get(address, ['address_postal']) || get(address, ['postal']) || '').trim();
  const country = get(address, ['address_country']) || get(address, ['country']) || '';
  return [
    postal + (postal ? ', ' : ''),
    country,
  ].join(' ').trim();
}
