import { get } from "lodash";
import React, { useMemo } from "react";
import { Csku } from "@commonsku/styles";

import FilmStrip from "../../gallery/FilmStrip";
import { Medium, Small, SmallOnly } from "../../ScreenSize";
import ItemImagesSlider from "../ItemImagesSlider";
import GalleryImage from "./GalleryImage";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";
import { usePublicViewEntityContext } from "../../../context/PublicViewEntityProvider";
import useMobileScreen from "../../../hooks/useMobileScreen";

function getDisplayItems(order, item_id) {
  const items = order.items;

  if (!item_id) {
    return items.filter(i => 'OPTION' === i.parent_type);
  }
  const startIndex = items.map(i => i.item_id).indexOf(item_id);
  if (-1 === startIndex) {
    return [];
  }
  const endIndex = items.slice(startIndex + 1).map(i => i.parent_type).indexOf('SEPARATOR');
  if (-1 === endIndex) {
    return items.slice(startIndex).filter(i => 'OPTION' === i.parent_type);
  }
  return items.slice(startIndex, startIndex + endIndex + 1).filter(i => 'OPTION' === i.parent_type);
}

export default function SlideShow(props) {
  const {
    item,
    order,
    page,
    selected,
    fullscreen,
    extraImageId,
    productInPopup,
    productInDropdown,
    productInCarousel,
    playing,
    pause,
    play,
    onSelectImage,
    exitFullscreen,
    requestFullscreen,
    popupHeight=300,
  } = props;
  const mobileScreen = useMobileScreen();
  const { templateColor: template_color } = usePublicViewTemplateDataContext();
  const { entityType } = usePublicViewEntityContext();
  const extraImages = useMemo(
    () => (item?.images || []).map(f => ({
      item_id: f.file_id,
      file: f
    })),
    [item?.images]
  );

  const displayItems = getDisplayItems(order);
  const anyExtraImages = displayItems.reduce((t, i) => t || i.images.length > 1, false);
  const itemIndex = displayItems.map(i => i.item_id).indexOf(selected);
  const extraImageIndex = extraImages.map(i => i.item_id).indexOf(extraImageId[selected]);

  const mainImages = displayItems.map(i => ({
    item_id: i.item_id,
    file: i.images.filter(f => f.file_id === extraImageId[i.item_id])[0] || i.images[0]
  }));

  const imageStyle = {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    maxWidth: '100%',
    maxHeight: '100%',
    ...(mobileScreen ? {
      transform: 'unset',
      top: '0',
    } : {}),
  };
  const SlideshowComponent = 'slide-show' === page ? Small : Medium;
  if (!item) { return null; }

  return (
    <SlideshowComponent>
      <Csku
        className={`small-12 ${fullscreen || productInDropdown ? '' : 'medium-7'} columns`}
        sx={{
          xs: { height: '100%', },
          md: {
            paddingLeft: 80,
            height: productInPopup
              ? '100%'
              : `calc((${productInDropdown ? (672-56+'px') : '100vh'} - 7px) - 72px - 42px)`,
          },
        }}
      >
        <SmallOnly>
          <Csku
            className="row small-12 columns collapse"
            style={{
              position: 'relative',
              overflow: 'hidden',
              textAlign: 'center',
              paddingBottom: 22,
              height: '100%',
              ...(extraImages.length < 2 ? { display: 'none' } : {}),
            }}
            sx={{
              xs: {
                height: '105px',
                ...(extraImages.length <= 1 ? {
                  visibility: 'hidden',
                  height: '2rem',
                } : {}),
              },
              md: {
                height: '105px',
                ...(extraImages.length <= 1 ? {
                  visibility: 'hidden',
                  height: productInPopup ? '100%' : 'calc(2rem + 75px)',
                } : {}),
              },
            }}
          >
            <ItemImagesSlider
              images={extraImages}
              index={extraImageIndex < 0 ? 0 : extraImageIndex}
              onSelectImage={onSelectImage}
              template_color={template_color}
              imageStyle={{...imageStyle, top: '4%'}}
            />
          </Csku>
        </SmallOnly>
        {entityType !== 'ORDER' && <Medium>
          {extraImages.length > 1 ?
            <FilmStrip items={extraImages} index={extraImageIndex < 0 ? 0 : extraImageIndex} size={75} onSelect={onSelectImage} template_color={template_color} /> :
            (anyExtraImages ?
              <Csku forceStyles as="div"
                sx={{
                  xs: { height: '2rem', },
                  md: { height: productInPopup ? '100%' : 'calc(2rem + 75px)', },
                }}>&nbsp;</Csku> :
              null)}
        </Medium>}
        <Csku as="div" forceStyles
            className="row small-12 columns collapse"
            style={{
              xs: {
                position: 'relative',
                overflow: 'hidden',
                textAlign: 'center',
                padding: '22px 11px',
                height: '100%',
              },
              md: {
                padding: 22,
                height: productInDropdown
                  ? `calc(672px - 2rem - 100px${anyExtraImages ? ' - 2rem - 75px' : ''})`
                  : productInPopup
                  ? '100%'
                  : `calc(100vh - 2rem - 75px - 56px${anyExtraImages ? ' - 2rem - 75px' : ''})`
              },
            }}
          >
            <div style={{
              height: mobileScreen ? '40vh' : (productInPopup ? popupHeight : '100%'),
              ...(!productInPopup && !productInDropdown ? {
                display: 'flex',
                width: (displayItems.length * 100) + '%',
                transition: 'transform 1s',
                transform: 'translateX(-' + (itemIndex / displayItems.length * 100) + '%)',
              } : {}),
            }}>
              {productInPopup || productInDropdown
                ? <GalleryImage
                    key={'GalleryImage-' + item.item_id}
                    imageStyle={imageStyle}
                    image={get(mainImages.find(it => it.item_id === item.item_id), ['file'])}
                    productInPopup={productInPopup}
                    popupHeight={popupHeight}
                  />
                : mainImages.map((i, idx) =>
                    <GalleryImage
                      key={`GalleryImage-${(i.file?.file_id || idx)}-${i.item_id}`}
                      style={{ width: '100%', height: '100%', flex: 1 }}
                      image={i.file}
                      imageStyle={imageStyle}
                      productInPopup={productInPopup}
                      popupHeight={popupHeight}
                    />)}
            </div>
            {entityType !== 'ORDER' && productInCarousel && <Medium>
              <a onClick={playing ? pause : play}
                style={{
                  display: 'inline-block',
                  backgroundColor: 'transparent',
                  fontFamily: 'Ionicons',
                  color: template_color,
                  position: 'absolute',
                  zIndex: 4,
                  fontSize: '2.7em',
                  padding: '15px 20px',
                  textShadow: '0 1px 1px #1a1a1a',
                  cursor: 'pointer',
                  left: 0,
                  bottom: 0
                }}
              >{playing ? '' : ''}</a>
            </Medium>}
            {entityType !== 'ORDER' && productInCarousel && <Medium>
              <a onClick={fullscreen ? exitFullscreen : requestFullscreen}
                style={{
                  display: 'inline-block',
                  backgroundColor: 'transparent',
                  fontFamily: 'Ionicons',
                  color: template_color,
                  position: 'absolute',
                  zIndex: 4,
                  fontSize: '2.7em',
                  padding: '15px 20px',
                  textShadow: '0 1px 1px #1a1a1a',
                  cursor: 'pointer',
                  right: 0,
                  bottom: 0
                }}
              >{fullscreen ? '' : '' }</a>
            </Medium>}
          </Csku>
          {entityType === 'ORDER' && <Medium>
            {extraImages.length > 1 ?
              <FilmStrip items={extraImages} index={extraImageIndex < 0 ? 0 : extraImageIndex} size={75} onSelect={onSelectImage} template_color={template_color} /> :
              (anyExtraImages ?
                <Csku forceStyles as="div"
                  sx={{
                    xs: { height: '2rem', },
                    md: { height: productInPopup ? '100%' : 'calc(2rem + 75px)', },
                  }}>&nbsp;</Csku> :
                null)}
          </Medium>}
      </Csku>
    </SlideshowComponent>
  );
}
