import { Col, colors, fontFamilies, Row, Text } from "@commonsku/styles";
import React, { useState } from "react";
import { TemplateButton } from "../helpers";
import CartItems from "./CartItems";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";
import { usePublicViewEntityContext } from "../../../context/PublicViewEntityProvider";
import { usePublicView } from "../../../context/PublicViewProvider";

export default function CheckoutCartItems(props) {
  const {
    cart,
  } = props;
  const { templateColor } = usePublicViewTemplateDataContext();
  const { entityIsFree } = usePublicViewEntityContext();
  const { onClickCart } = usePublicView();
  const [hoveringItem, setHoveringItem] = useState(null);

  return (
    <Col xs style={{
      background: colors.neutrals[20],
      borderRadius: 4,
      padding: 0,
      marginBottom: entityIsFree == 1 ? '12px' : '20px',
    }}>
      <Row>
        <Col xs style={{
          padding: 24,
          borderBottom: `1px solid ${colors.neutrals[60]}`,
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px'
        }}>
          <Text style={{
            fontSize: '25px',
            fontFamily: fontFamilies.bold,
            color: 'var(--color-neutrals-100)',
            display: 'inline-block',
            marginBottom: 0,
          }}>
            Order Summary
          </Text>
          {!(+entityIsFree === 1) && <div style={{ display: 'inline-block', float: 'right',  }}>
            <TemplateButton
              isSecondary
              template_color={templateColor}
              size='medium'
              onClick={onClickCart}
            >View/Edit Cart</TemplateButton>
          </div>}
        </Col>
        <Col xs>
          <CartItems
            cartItems={cart.items}
            hoveringItem={hoveringItem}
            onUpdateHoveringItem={setHoveringItem}
            onClickCartItem={(item_id) => {
              onClickCart(null, item_id);
            }}
          />
        </Col>
      </Row>
    </Col>
  );
}
