import React from 'react';
import {
  Col,
  Row,
  Text,
  fontFamilies,
} from '@commonsku/styles';

import { TemplateButton } from '../helpers';

const CartHeader = ({
  cart,
  itemInCart,
  isEditingAnItem,
  template_color,
  showDetailsItemIds,
  onToggleShowAllItemsDetails,
}) => {
  return (
    <Col xs style={{
      position: 'sticky',
      top: 0,
      bottom: 'auto',
      background: 'rgb(237, 244, 247)',
      zIndex: 1,
      borderBottom: '2px solid var(--color-neutrals-60)',
      paddingTop: 16,
    }}>
      <Row>
        <Col xs={7} style={{ paddingLeft: 16, }}>
          <Text style={{
            fontSize: '25px',
            fontFamily: fontFamilies.bold,
            color: 'var(--color-neutrals-100)',
          }}>Shopping Cart</Text>
        </Col>
        <Col xs={5} style={{ textAlign: 'right', paddingRight: 16, paddingBottom: 10, }}>
          {itemInCart && !isEditingAnItem && <TemplateButton
            template_color={template_color}
            size='medium'
            onClick={() => onToggleShowAllItemsDetails()}
          >{cart.items.length !== showDetailsItemIds.length  ? 'Expand' : 'Collapse'} All</TemplateButton>}
        </Col>
      </Row>
    </Col>
  );
};

export default CartHeader;
