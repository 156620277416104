import React from "react";
import { Medium } from "../../ScreenSize";
import CommentButton from './CommentButton';

export default function InfoButtons(props) {
  const { item, showComments = true, style = {}, onSelectPage } = props;
  return (
    showComments ? <div className="row collapse" style={style}>
      <Medium>
        <div className="medium-12 columns">
          <CommentButton
            item={item}
            showComments={showComments}
            onSelectPage={onSelectPage}
          />
        </div>
      </Medium>
    </div> : null
  );
}
