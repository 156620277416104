import { find, get } from "lodash";
import React from "react";
import { Col, EditIcon, Row, ShowPopup, Text } from "@commonsku/styles";
import { TemplateButton, TemplateTextIconButton } from "../helpers";
import SelectContactPopup from "./SelectContactPopup";
import { getContactName } from "../../contact/utils";
import { usePublicViewEntityContext } from "../../../context/PublicViewEntityProvider";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";

export default function SelectContact({
  field,
  contactId,
  contact,
  contacts,
  hide_select,
  hasErrors,
  disabled = false,

  onSelectContact,
  onAddContact,
}) {
  const { entity } = usePublicViewEntityContext();
  const { templateColor: template_color } = usePublicViewTemplateDataContext();

  const selectedId = contactId && contactId !== 'ADD' ? contactId : '';
  let selectedContact = (selectedId && find(contacts, v => v.contact_id === selectedId));
  if (!selectedContact && contact && contact['contact_email']) {
    selectedContact = contact;
  }
  const popupProps = {
    hideSelect: (''+hide_select) === '1',
    popup: SelectContactPopup,
    color: template_color,
    contacts: contacts,
    selectedId: contactId,
    customContact: contact,
    canAddCustomContact: 'TYPED' === entity[`${field}_type`],
    onSelectContact: onSelectContact,
    onAddContact: onAddContact,
  };

  return (
    <Row key={`select-${field}-contact`} style={{ paddingBottom: 16, }}>
      <Col xs md={selectedContact ? 8 : 12}>
        {selectedContact ? <>
          <Text as="p" style={{marginBottom: 0}}>{getContactName(selectedContact)}</Text>
          <Text Text as="p" style={{marginBottom: 0}}>{get(selectedContact, ['contact_email'])}</Text>
        </> : <ShowPopup
          {...popupProps}
          render={({ onClick }) => (
            <TemplateButton
              id={`add_${field}}`}
              size='large'
              disabled={disabled}
              template_color={template_color}
              isSecondary
              style={{ width: '100%' }}
              onClick={(e) => !disabled ? onClick(e) : null}
            >+ Add Contact</TemplateButton>
          )}
        />}
      </Col>
      {selectedContact && <Col xs md={4} mdStyle={{ textAlign: 'right' }}>
        <ShowPopup
          {...popupProps}
          render={({ onClick }) => (
            <TemplateTextIconButton
              id={`edit_${field}}`}
              size='medium'
              Icon={EditIcon}
              template_color={template_color}
              style={{ width: '100%' }}
              onClick={(e) => !disabled ? onClick(e) : null}
            >Edit Contact</TemplateTextIconButton>
          )}
        />
      </Col>}
      {hasErrors && <Col xs padded style={{ textAlign: 'left', color: 'var(--color-errors-main)', fontWeight: 'bold' }}><span key={field + 'c1e'}>Please choose a contact</span></Col>}
    </Row>
  );
}
