import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { formatMoney, oauth } from "../../../utils";
import { TemplateButton } from "../helpers";

import AvalaraShopTaxButton from "./AvalaraShopTaxButton";
import { createCloseCart, createEmptyCart } from "../../../actions/shop";
import { usePublicViewEntityContext } from "../../../context/PublicViewEntityProvider";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";

const STRIPE_MINIMUM = 0.5;

export default function CheckoutButtons({
  order,
  cart,
  error,
  checkoutTotal,
  discountValue,
  shippingAddress,
  shipping_cost,
  avalara_summary,

  handleSubmitOrder,
  onChangeAvalaraSummary,
  onChangeAddressTax,
  onError,

  isAvalara = false,
  isDisabled = false,
  show_for_size = "",
  estimateTaxByAddress = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    entity,
    entityId,
    entityAggregate,
    entityIsFree,
    entityBuyInventory,
    entityLocation,
  } = usePublicViewEntityContext();
  const { templateColor: template_color } = usePublicViewTemplateDataContext();

  const new_class =
    show_for_size === "small" && +entityAggregate === 1
      ? "small-12 row show-for-small-only"
      : show_for_size === "large" && +entityAggregate === 1
      ? "small-12 row show-for-medium"
      : "small-12 row";
  const isCreditCard =
    entity.allow_credit_card_checkout == 1 &&
    checkoutTotal > 0 &&
    !entityBuyInventory &&
    entityIsFree != 1;
  const minimumAmount = STRIPE_MINIMUM;
  const minimumReached =
    isCreditCard &&
    entity.company_data?.credit_card_software === "STRIPE" &&
    checkoutTotal < minimumAmount
      ? false
      : true;

  let payButton = (
    <TemplateButton
      template_color={template_color}
      style={{ width: "100%" }}
      disabled={isDisabled}
      onClick={(e) => {
        if (isDisabled) {
          return;
        }
        if (estimateTaxByAddress && !shippingAddress.tax_id) {
          oauth("GET", "address/get-shop-tax-from-address", {
            shop_id: entityId,
            ...shippingAddress,
          })
            .then((res) => {
              onChangeAddressTax(res?.json?.tax);
            })
            .catch((err) => {
              const errorMsg = err?.json?.error || "No tax found";
              onError?.(errorMsg);
              console.log(errorMsg);
            });
          return;
        }
        handleSubmitOrder(e);
      }}
    >
      {estimateTaxByAddress && !shippingAddress.tax_id
        ? "Estimate Tax"
        : isCreditCard
        ? "Pay by Credit Card"
        : "Submit Order"}
    </TemplateButton>
  );

  const onCloseCart = () => {
    Promise.resolve(
      dispatch(
        +entityIsFree === 1
          ? createEmptyCart(entityId)
          : createCloseCart(entityId)
      )
    ).then(() => {
      navigate(entityLocation);
    });
  };

  if (!minimumReached) {
    payButton = (
      <span style={{ color: "red" }}>
        Minimum Payment of {order.currency_symbol}
        {formatMoney(minimumAmount)} is required to finalize this order. Please
        add more in the cart.
      </span>
    );
  }

  if (cart.items.length === 0) {
    payButton = (
      <span style={{ color: "red" }}>
        Your cart is empty. Please choose{" "}
        {+entityIsFree === 1 ? "an item" : "some items"} for your cart.
      </span>
    );
  }

  return (
    <div
      key="checkout-buttons"
      className={new_class}
      style={{ marginBottom: "1rem" }}
    >
      <div className="large-5 columns keep-shopping-btn-container">
        <TemplateButton
          isSecondary
          template_color={template_color}
          onClick={onCloseCart}
          style={{
            width: "100%",
            marginBottom: "1rem",
            padding: 12,
          }}
        >
          {+entityIsFree === 1 ? "Change Product" : "Keep Shopping"}
        </TemplateButton>
      </div>
      <div className="large-7 columns" style={{ padding: 0 }}>
        {isAvalara && avalara_summary === false ? (
          <AvalaraShopTaxButton
            disabled={isDisabled}
            style={{ width: "100%" }}
            items={cart.items}
            shipping_cost={shipping_cost}
            address={shippingAddress}
            discount={discountValue}
            onChange={(result) => {
              onChangeAvalaraSummary(result);
            }}
          />
        ) : (
          payButton
        )}
      </div>
    </div>
  );
}
