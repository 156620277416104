import React, { FC, useCallback, useMemo } from "react";
import { ItemMetaData } from "../../../../interfaces/Metadata";
import Question from "./Question";
import { TNumber } from "../../../../interfaces/common";
import {
  useProductQuestionsData,
  useUpdateProductQuestion,
} from "../../../../redux/hooks/product_questions";

interface ProductQuestionProps {
  metadata: ItemMetaData;
  shop_id: string;
}

const ProductQuestion: FC<ProductQuestionProps> = ({ metadata, shop_id }) => {
  const productQuestions = useProductQuestionsData();
  const onUpdateProductQuestion = useUpdateProductQuestion();

  const onUpdateProductMetadata = useCallback(
    (
      metadata_id: string,
      label: string,
      value: string,
      parent_id: string,
      type: string,
      mandatory: TNumber,
      options: {
        key: string;
        value: string;
      } | null,
      shop_id: string
    ) => {
      onUpdateProductQuestion(
        metadata_id,
        label,
        value,
        parent_id,
        type,
        mandatory,
        options,
        shop_id
      );
    },
    []
  );

  const questionBody = useMemo(
    () =>
      Object.values(metadata).map((m) => {
        const value = (
          productQuestions?.[m.parent_id]?.[m.metadata_id] || {
            value: "",
          }
        ).value;
        return (
          <Question
            key={m.metadata_id}
            m={m}
            value={value}
            shop_id={shop_id}
            onUpdateProductMetadata={onUpdateProductMetadata}
          />
        );
      }),
    [metadata, productQuestions, onUpdateProductMetadata, shop_id]
  );

  return (
    <div
      className="small-12 columns"
      style={{ padding: 0, paddingTop: 16 }}
      data-test-id="product-questions"
    >
      {questionBody}
    </div>
  );
};

export default ProductQuestion;
