import React from 'react';
import { upperFirst } from 'lodash';
import FreeItemOption, { OptionLabel } from './helpers/FreeItemOption';
import { freeItemColStyle, freeItemRowStyle } from './helpers';

export default function FreeShopItemSkuSelector({
  item,
  value,
  template_color,
  sizeaxes,
  winnowingAxes,
  winnowingAxesOption,
  winnowingAxesOptions,
  availableOptions,
  availableOptionsColor,
  onChangeSizeAxisFreeShop,
  onChangeColorAxisFreeShop,
  selectedFreeShopValue,
}) {
  return (
    <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
      <div>
        {sizeaxes.map(a => {
          return (
            <div key={"sku_sizes-" + a}>
              <OptionLabel className="sku_free_shop_size_label">{upperFirst(a)}</OptionLabel>
              <div name="sku_sizes" id="sku_free_shop_sizes" style={{ maxHeight: 170, ...freeItemRowStyle }}>
                {winnowingAxesOptions[a].map((s, idx) =>
                  <div key={s.key}
                    style={freeItemColStyle}
                    className="sku_free_shop_size_col"
                  >
                    <FreeItemOption
                      axis={s}
                      value={s.value}
                      disabled={availableOptions.length > 0 ? !(availableOptions.includes(s.value)) : ''}
                      className={`${availableOptions.length > 0 && !(availableOptions.includes(s.value)) ? 'unavailable' : ''} sku_free_shop_size_option`}
                      label={s.value}
                      isSelected={availableOptions.length > 0 && !(availableOptions.includes(s.value))}
                      isCurrentValue={value[a] == s.value}
                      template_color={template_color}
                      onChangeFreeShopAxis={e => onChangeSizeAxisFreeShop(a, e)}
                      id={`sku_free_shop_size_option-${s.key}`}
                    />
                  </div>)}
              </div>
            </div>
          );
        })} </div>
      {0 < winnowingAxesOption.length &&
        winnowingAxes.map(a => {
          return (
            <div key={"sku_free_shop_colors-" + a} style={{ marginTop: 16 }}>
              <OptionLabel className="sku_free_shop_color_label">{upperFirst(a)}</OptionLabel>
              <div name="sku_colors" id="sku_free_shop_colors" style={{ maxHeight: 250, ...freeItemRowStyle }}>
                {winnowingAxesOptions[a].map((c, idx) =>
                  <div key={c.key}
                    style={freeItemColStyle}
                    className="sku_free_shop_color_col"
                  >
                    <FreeItemOption
                      axis={c}
                      value={c.value}
                      disabled={availableOptionsColor.length > 0 ? !(availableOptionsColor.includes(c.value)) : ''}
                      className={`${availableOptionsColor.length > 0 && !(availableOptionsColor.includes(c.value)) ? 'unavailable' : ''} sku_free_shop_color_option`}
                      label={c.value}
                      isSelected={availableOptionsColor.length > 0 && !(availableOptionsColor.includes(c.value))}
                      isCurrentValue={value[a] == c.value}
                      template_color={template_color}
                      onChangeFreeShopAxis={e => onChangeColorAxisFreeShop(a, e)}
                      id={`sku_free_shop_color_option-${c.key}`}
                    />
                  </div>)}
              </div>
            </div>
          );
        })}
    </div>
  );
}
