import { isEmpty, } from "lodash";
import React from "react";
import { Col, colors, Row, XIcon } from "@commonsku/styles";
import { formatNumber, TemplateIconButton } from "../helpers";
import AvalaraShopTaxSummary from './AvalaraShopTaxSummary';
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";
import { usePublicViewEntityContext } from "../../../context/PublicViewEntityProvider";

export default function OrderTotal({
  order,
  orderTotal,
  shipping_cost,
  applied_discount,
  avalara_summary,
  tax,
  taxTotal,
  checkoutTotal,
  discountValue,
  isAvalara,
  onDeleteCoupon,
  CartItems,
  EnterCouponSection,
  CheckoutButtons,
  hasCheckoutTaxError = false,
}) {
  const { templateColor: template_color } = usePublicViewTemplateDataContext();
  const { entityIsFree, entityAggregate } = usePublicViewEntityContext();

  const { cellLeft, cellRight } = {
    cellLeft: {
      paddingTop: '5px'
    },
    cellRight: {
      textAlign: 'right',
      paddingTop: '5px'
    }
  };
  const tdStyles = {background: 'none'};

  return (
    <Row className={+entityAggregate === 1 ? '' : 'full-height'}>
      {CartItems}
      <Col xs style={{
        background: colors.neutrals[20],
        borderRadius: 4,
        marginBottom: 20,
        padding: 24
      }}>
        {entityIsFree != 1 &&
          <table>
            <tbody style={{ background: 'none' }}>
              <tr>
                <td data-test-id="subtotal-label" style={{...tdStyles, ...cellLeft}}>Subtotal</td>
                <td data-test-id="subtotal-value" style={{...tdStyles, ...cellRight}}>{order.currency_symbol}{formatNumber(orderTotal, true)}</td>
              </tr>
              {applied_discount ?
                <tr>
                  <td data-test-id="discount-label" style={{...tdStyles, ...cellLeft}}>
                    <span>
                      {applied_discount.code} (-{applied_discount.type === 'VALUE' ? order.currency_symbol : null}{applied_discount.value}{applied_discount.type === 'PERCENT' ? '%' : null})
                    </span>
                    <span style={{ paddingLeft: 10, }}>
                      <TemplateIconButton
                        id="delete_coupon_btn"
                        Icon={<XIcon color={template_color} />}
                        size='medium'
                        template_color={template_color}
                        isSecondary
                        style={{ verticalAlign: 'baseline', }}
                        onClick={e => { e.preventDefault(); onDeleteCoupon(); }}
                        variantBorderColor="transparent"
                        variantHoverBorderColor="transparent"
                        variantBg="transparent"
                        variantHoverBg="transparent"
                      >
                        Delete Coupon
                      </TemplateIconButton>
                    </span>
                  </td>
                  <td style={{ ...cellRight, ...tdStyles, }} data-test-id="discount-value">-{order.currency_symbol}{formatNumber(discountValue, true)}</td>
                </tr>
                : EnterCouponSection}
              {shipping_cost !== 0 ?
                <tr>
                  <td style={{...tdStyles, ...cellLeft}} data-test-id="shipping-label">SHIPPING</td>
                  <td style={{...tdStyles, ...cellRight}} data-test-id="shipping-cost-value">{order.currency_symbol}{formatNumber(shipping_cost, true)}</td>
                </tr>
                : null}
              {tax ?
                <tr>
                  <td style={{...tdStyles, ...cellLeft}} data-test-id="tax-label">
                    {`${tax.label} TAX (${parseFloat(tax.percent)}%): `}
                  </td>
                  <td style={{...tdStyles, ...cellRight}} data-test-id="tax-value">
                    {order.currency_symbol}{formatNumber(taxTotal, true)}
                  </td>
                </tr>
                : hasCheckoutTaxError ? <tr>
                  <td style={{...tdStyles, ...cellLeft}} data-test-id="tax-not-applicable">
                    Tax not applicable
                  </td>
                  <td style={{...tdStyles, ...cellRight}}>
                    -
                  </td>
                </tr> : null}
              <tr>
                <td colSpan="2" style={tdStyles}>
                  <hr style={{ top: '0px', margin: '5px' }} />
                </td>
              </tr>
              {isAvalara && !isEmpty(avalara_summary) && <tr>
                {/* <td style={{...tdStyles, ...cellLeft}}></td> */}
                <td colSpan={2} style={{...tdStyles, ...cellRight}}><AvalaraShopTaxSummary avalara_summary={avalara_summary} /></td>
              </tr>}
              <tr>
                <td style={{ ...tdStyles, paddingBottom: '5px', fontWeight: 'bold' }} data-test-id="total-label">Total</td>
                <td style={{ ...tdStyles, paddingBottom: '5px', textAlign: 'right', fontWeight: 'bold' }} data-test-id="total-value">{order.currency_symbol}{formatNumber(checkoutTotal, true)}</td>
              </tr>
            </tbody>
          </table>
        }
        {CheckoutButtons}
      </Col>
    </Row>
  );
}
