import React, { useRef } from 'react';
import {
  Col,
  colors,
  themeOptions
} from '@commonsku/styles';

import useContainerScroll, { ScrollContainer } from '../../../hooks/useContainerScroll';

export default function CartItemsContainer({
  fullHeight = false,
  itemInCart = true,
  template_color,
  children,
  showDetailsItemIds,
}) {
  /**
   * @type {React.MutableRefObject<HTMLDivElement>} parentRef
   */
  const ref = useRef(null);
  const {
    canScrollDown,
    canScrollUp,
    innerWidth,
    scrollDown,
    scrollUp,
  } = useContainerScroll(ref, [fullHeight, itemInCart]);
  const mediumWidth = parseInt((''+themeOptions.mediaQueries.sizeMediaWidth.md).replace('px'));

  return (
    <Col ref={ref} xs style={{
      overflow: 'auto',
      padding: 0,
      ...(innerWidth >= mediumWidth && itemInCart ? {
        minHeight: 100,
        maxHeight: `calc(100vh - ${fullHeight ? 250 : 320}px)`,
      } : {}),
    }}>
      <div
        className="container"
        style={{
          position: 'relative',
          background: colors.neutrals[20],
        }}
      >
        <div className="row">
          <div className="small-12 columns" style={{ padding: 0 }}>
            {children}
          </div>
        </div>
      </div>
      {itemInCart > 0 && ref.current ? <>
        <ScrollContainer
          isUp
          onClick={scrollUp}
          canScroll={canScrollUp()}
          color={template_color}
          width={ref.current.clientWidth - 10}
          zIndex={4}
          top={ref.current.offsetTop - 17}
          left={22}
        />

        <ScrollContainer
          isUp={false}
          onClick={scrollDown}
          canScroll={canScrollDown()}
          color={template_color}
          width={ref.current.clientWidth - 10}
          zIndex={4}
          top={ref.current.clientHeight + ref.current.offsetTop - 44}
          left={22}
        />
      </> : null}
    </Col>
  );
}
