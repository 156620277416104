import _,{ get } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Csku, colors, H3, } from '@commonsku/styles';
import Img from '../../Img';
import { getImageSrc, isInViewport } from '../../../utils';
import { formatNumber, TemplateButton } from '../helpers';
import useWindowSize from '../../../hooks/useWindowSize';
import { ArrowsIcon } from '../../../hooks/useContainerScroll';
import { usePublicViewTemplateDataContext } from '../../../context/PublicViewTemplateDataProvider';
import { usePublicViewEntityContext } from '../../../context/PublicViewEntityProvider';

export default function CartItems({
  cartItems=[],
  hoveringItem = -1,
  onUpdateHoveringItem,
  onClickCartItem,
  ...props
}) {
  const { templateColor: template_color } = usePublicViewTemplateDataContext();
  const { entityIsFree, entityOrder: order } = usePublicViewEntityContext();
  const isFree = +entityIsFree === 1;

  useWindowSize(); // reload on window change

  /**
   * @type {React.MutableRefObject<HTMLDivElement>} ref
   */
  const ref = useRef(null);
  const [scrollPos, setScrollPos] = useState(-1);

  const scrollTop = scrollPos > -1 ? scrollPos : (ref.current?.scrollTop || 0);

  const scrollToItemIndex = useCallback((idx) => {
    if (idx < 0 || !get(cartItems, `[${idx}]`)) { return; }
    const it = cartItems[idx];
    const id = `cart_item-checkout-${it.item_id}`;
    const elem = document.getElementById(id);
    if (!elem) { return; }
    elem.scrollIntoView({
      behavior: 'smooth', block: 'nearest', inline: 'center',
    });
  }, [cartItems]);

  function getVisibleStartEndElemIdx() {
    let startIdx = -1,
        endIdx = -1;
    for (let i = 0; i < cartItems.length; i++) {
      const it = cartItems[i];
      const id = `cart_item-checkout-${it.item_id}`;
      const elem = document.getElementById(id);
      if (!elem) { continue; }
      const isVlisible = isInViewport(elem);
      if (startIdx >= 0 && !isVlisible) { break; }
      if (isVlisible) {
        if (startIdx === -1) {
          startIdx = i;
        } else {
          endIdx = i;
        }
      }
    }
    return [startIdx, endIdx];
  }

  function slideNext() {
    const [start, end] = getVisibleStartEndElemIdx();
    if (end === -1 || end >= cartItems.length || !cartItems[end]) { return; }
    scrollToItemIndex(end === cartItems.length-1 ? end : end + 1);
  }

  function slidePrev() {
    const [start, end] = getVisibleStartEndElemIdx();
    if (start < 0 || !cartItems[start]) { return; }
    scrollToItemIndex(start === 0 ? 0 : start - 1);
  }

  const showScrollArrowNavigation = ref.current && ref.current.scrollHeight > ref.current.clientHeight;
  const showScrollTopArrow = scrollTop > 15;
  const showScrollBottomArrow = ref.current && scrollTop < ref.current.scrollHeight - ref.current.clientHeight - 10;

  useEffect(() => {
    const elem = ref.current;
    if (!elem) { return; }

    function updateScrollPos() {
      setScrollPos(elem.scrollTop);
    }
    elem.addEventListener('scroll', updateScrollPos);
    updateScrollPos();
    return () => elem.removeEventListener('scroll', updateScrollPos);
  }, []);

  return (
    <div ref={ref} style={{
      maxHeight: 500,
      overflowY: 'auto',
      ...(showScrollArrowNavigation ? {
        marginTop: 15,
        marginBottom: 15,
      } : {}),
    }}>
      <table className='sidebar-cart'>
        <tbody style={{ border: 'none', background: 'none' }}>
          {cartItems.map((i, idx) => [
            <Csku
              as="tr"
              key={`tr1-${i.item_id}`}
              id={`cart_item-checkout-${i.item_id}`}
              onMouseOver={() => onUpdateHoveringItem(i.item_id)}
              onMouseLeave={() => onUpdateHoveringItem(null)}
            >
              <td colSpan={2}>
                <table style={{ marginBottom: 0 }}>
                  <tbody style={{ background: 'none' }}>
                    <tr style={{
                      ...(hoveringItem !== i.item_id || isFree ? {} : {
                        background: '#fff',
                        filter: 'opacity(0.4)',
                      }),
                    }}>
                      <td colSpan={2} style={{ padding: '16px 24px 0 24px', cursor: isFree ? 'auto' : 'pointer', }}>
                        <table style={{ marginBottom: 0 }}>
                          <tbody style={{ background: 'none', borderBottom: idx === cartItems.length-1 ? 'none' : `1px solid ${colors.neutrals[60]}`, }}>
                            <tr>
                              <td style={{
                                paddingBottom: 16,
                                background: 'none',
                                width: 95,
                                cursor: isFree ? 'auto' : 'pointer',
                              }}>
                                <Img
                                  style={{
                                    maxWidth: 80,
                                    maxHeight: 80,
                                  }}
                                  src={getImageSrc(i.image, 'medium')}
                                  alt={i.item_name}
                                />
                              </td>
                              <td style={{ paddingBottom: 16, paddingLeft: 16, background: 'none', cursor: isFree ? 'auto' : 'pointer', }}>
                                <H3
                                  style={{
                                    borderBottom: `1px solid ${colors.neutrals[60]}`,
                                    paddingBottom: 5,
                                    fontSize: 20,
                                    color: 'inherit',
                                  }}
                                >{i.item_name}</H3>
                                <div>
                                {isFree ? (
                                    i.breakdowns[0].size_name || i.breakdowns[0].color_name ? (
                                      <>
                                      {i.breakdowns[0].size_name && <span> <strong>Size:</strong> {i.breakdowns[0].size_name === 'TBD' ? 'N/A' : _.startCase(_.lowerCase(i.breakdowns[0].size_name))}</span>}
                                      {i.breakdowns[0].color_name && <span> <strong>Color:</strong> {i.breakdowns[0].color_name === 'TBD' ? 'N/A' : _.startCase(_.lowerCase(i.breakdowns[0].color_name))}</span>}
                                      </>
                                  ) : (
                                    <span>
                                      Quantity: {formatNumber(i.quantity)}
                                    </span>
                                  )
                                ) : (
                                  <span>
                                    Quantity: {formatNumber(i.quantity)}
                                  </span>
                                )}
                                  <span style={{ float: 'right' }}>
                                    {!isFree &&
                                      `Total: ${order.currency_symbol || ''}${formatNumber(i.subtotal, true)}`
                                    }
                                  </span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </Csku>,
            <tr
              key={`tr2-${i.item_id}`}
              onMouseOver={() => onUpdateHoveringItem(i.item_id)}
              onMouseLeave={() => onUpdateHoveringItem(null)}
              style={{
                ...(hoveringItem !== i.item_id || isFree ? {display: 'none'} : {})
              }}
            >
              <td colSpan={2} style={{ paddingLeft: '40%' }}>
                <TemplateButton
                  template_color={template_color}
                  style={{
                    position: 'absolute',
                    marginTop: (-90-scrollTop),
                  }}
                  onClick={() => {
                    onClickCartItem(i.item_id);
                  }}
                >View Details</TemplateButton>
              </td>
            </tr>
          ])}
        </tbody>
      </table>

      {showScrollArrowNavigation &&
        <>
          <div onClick={slidePrev} style={{
            color: 'teal',
            fontSize: '3em',
            position: 'absolute',
            zIndex: 4,
            top: ref.current.offsetTop - 30,
            width: ref.current.clientWidth - 5,
            transition: 'visibility 0s linear 300ms, opacity 300ms',
            visibility: showScrollTopArrow ? 'visible' : 'hidden',
            opacity: showScrollTopArrow ? 1 : 0,
          }}>
            <ArrowsIcon direction="up" color={template_color}
            />
          </div>

          <div onClick={slideNext} style={{
            color: 'teal',
            fontSize: '3em',
            position: 'absolute',
            zIndex: 4,
            top: ref.current.clientHeight + ref.current.offsetTop - 35,
            width: ref.current.clientWidth - 5,
            transition: 'visibility 0s linear 300ms, opacity 300ms',
            visibility: showScrollBottomArrow ? 'visible' : 'hidden',
            opacity: showScrollBottomArrow ? 1 : 0,
          }}>
            <ArrowsIcon direction="down" color={template_color}
            />
          </div>
        </>}
    </div>
  );
}
