import React from "react";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";

export default function CommentButton(props) {
  const { item, showComments = true, onSelectPage } = props;
  const { templateColor: template_color } = usePublicViewTemplateDataContext();

  if (!showComments) {
    return null;
  }

  const commentCount = Object.values(item.comments).length;

  const countStyle = {
    background: 'white',
    color: template_color,
    padding: '0.5rem 0.75rem',
    borderRadius: '1rem',
    marginLeft: '0.2rem',
    fontWeight: 'bold'
  };

  return (
    <button
      className="button expanded"
      style={{ height: '3rem', marginLeft: '0.5rem' }}
      onClick={e => onSelectPage('comment')}
    >
      Comments <span style={countStyle}>{commentCount}</span>
    </button>
  );
}
