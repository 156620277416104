export function getContactName(contact) {
  if (!contact) { return ''; }
  if (contact.contact_full_name) {
    return contact.contact_full_name.trim();
  }
  if (contact.contact_name) {
    return contact.contact_name.trim();
  }
  if (contact.contact_first_name || contact.contact_last_name) {
    return `${contact.contact_first_name || ''} ${contact.contact_last_name || ''}`.trim();
  }
  return '';
}
