import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FileUploadIcon, Dropzone, } from '@commonsku/styles';

import { getImageSrc } from '../../utils';

import { createUploadGuestFile } from '../../actions/file';
import { createFinalizeUpdateArtwork } from '../../actions/artwork';
import { SelectShopArtworkPopupV2 } from '../../actions/popup';

import Img from '../Img';

class CartItemArtwork extends Component {

  render() {
    const {
      artwork,
      hasArtworkFiles,
      isEditing,
      onSelectFile,
      onUploadFile,
      small=6,
      medium=4,
      large=2,
      imgMaxHeight = 100,
      imgContainerStyle = {
        border: '2px dashed lightgray',
        height: 120,
        backgroundColor: 'white',
        padding: '0.5rem',
      },
      artworkContainerStyle = {
        backgroundColor: '#f2f2f2',
        padding: '0.5rem',
      },
      labelStyles = {},
      style={},
      usefileUploadIcon = false,
    } = this.props;
    const labelStyle = {
      textTransform: 'uppercase',
      display: 'inline-block',
      float: 'left',
      clear: 'left',
      width: '50%',
      fontSize: 'x-small'
    };

    const spanStyle = {
      display: 'inline-block',
      float: 'left',
      clear: 'right',
      width: '50%',
      fontSize: 'x-small'
    };

    const artworkContainerStyles = {
      borderRadius: '0.5rem',
      width: '100%',
      backgroundColor: '#f2f2f2',
      padding: '0.5rem',
      ...artworkContainerStyle,
    };

    const imageContainerStyle = {
      position: 'relative',
      padding: '0.5rem',
      textAlign: 'center',
      cursor: isEditing ? 'pointer' : 'default',
      border: '2px dashed lightgray',
      height: 120,
      backgroundColor: 'white',
      ...imgContainerStyle,
    };

    return (
      <div className={`small-${small} medium-${medium} large-${large} columns end cart_item_artwork_container`} style={style}>
        <div style={artworkContainerStyles}>
          <span style={{ fontWeight: 'bold', ...labelStyles }}>{artwork.artwork_title}</span>
          <div style={imageContainerStyle}>
          {isEditing ? (hasArtworkFiles ?
            <div className='cart_item_artwork_upload' style={{ width: '100%', height: '100%' }} onClick={onSelectFile(artwork.artwork_id, artwork.image)}>
              {artwork.image ?
                <Img style={{ maxHeight: imgMaxHeight }} src={getImageSrc(artwork.image, 'small')} />
                : usefileUploadIcon ? <FileUploadIcon />
                : <span style={{ color: 'lightgray' }}>Click here to upload your logo</span>}
            </div> :
            <Dropzone className="cart_item_artwork_drop_upload" style={{ width: '100%', height: '100%', border: 'none' }} onDrop={onUploadFile(artwork.artwork_id)}>
              <>{artwork.image ?
                <Img style={{ maxHeight: imgMaxHeight }} src={getImageSrc(artwork.image, 'small')} />
                : usefileUploadIcon ? <FileUploadIcon />
                : <span style={{ color: 'lightgray' }}>Click here to upload your logo</span>}</>
            </Dropzone>) :
            <div style={{ width: '100%', height: '100%' }}>
              {artwork.image ?
                <Img style={{ maxHeight: imgMaxHeight }} src={getImageSrc(artwork.image, 'small')} /> :
                <span style={{ color: 'lightgray' }}>No Image Selected</span>}
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  hasArtworkFiles: !!Object.values(state.entities.files).filter(f => 'GUEST' === f.parent_id).length
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSelectFile: (artwork_id, artwork_image=null) => () => dispatch(SelectShopArtworkPopupV2(artwork_id, ownProps.tenant_id, artwork_image)),
  onUploadFile: artwork_id => files => dispatch(createUploadGuestFile('GUEST', 'GUEST', files[0], ownProps.tenant_id)).then(
    action => dispatch(createFinalizeUpdateArtwork({ artwork_id, artwork_file_id: action.payload.id }))
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(CartItemArtwork);
