import React from "react";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate } from 'react-router-dom';
import { Col, Row } from "@commonsku/styles";

import { createContactPopup } from "../../actions/popup";
import { Medium } from "../ScreenSize";
import { setFilter } from "../../redux/gallery";
import { usePublicViewEntityContext } from "../../context/PublicViewEntityProvider";
import { usePublicViewTemplateDataContext } from "../../context/PublicViewTemplateDataProvider";
import Img from "../Img";
import { H1, H5, Text } from "../helpers/Typography";

const POSTER_FOOTER_FONT_STYLE = {
  color: "var(--neutrals-70899-ca-9, #899CA9)",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "24px",
};

const Footer = ({
  isEditable = false,
  centerAligned,
  withTopBorder = false,
  style = {},
  overrideFontStyle = false,
}) => {
  const {
    entityType,
    baseUrl,
    baseEntityUrl,
    entityBuyInventory,
    contactName,
    contactEmail,
  } = usePublicViewEntityContext();
  const {
    templateColor,
  } = usePublicViewTemplateDataContext();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onClickHome = () => {
    if (!isEditable) {
      navigate(
        baseUrl + (!!entityBuyInventory ? "?buy_inventory=true" : "")
      );
      dispatch(setFilter(""));
    }
  };

  const onClickShop = () => {
    if (!isEditable) {
      navigate(
        baseEntityUrl + (!!entityBuyInventory ? "?buy_inventory=true" : "")
      );
      dispatch(setFilter("shop"));
    }
  };

  const onClickContact = () =>
    !isEditable &&
    dispatch(
      createContactPopup(
        { contact_name: contactName, contact_email: contactEmail },
        templateColor
      )
    );
  const linkFontStyle = {
    color: templateColor,
    ...(overrideFontStyle ? POSTER_FOOTER_FONT_STYLE : {}),
  };

  return (
    <Medium>
      <div
        className={`row home-footer home-${entityType}-footer`}
        style={{
          maxWidth: "100%",
          ...(centerAligned ? { textAlign: "center" } : {}),
          ...(entityType === 'ORDER'
              ? {
                maxWidth: '100%',
                position: 'fixed',
                marginTop: 'auto',
                marginBottom: 0,
                bottom: 0,
                background: 'var(--color-neutrals-20)',
                width: '100%',
              }
              : { borderTop: withTopBorder ? "2px solid rgba(0, 0, 0, 0.1)" : "none" }),
          ...style,
        }}
      >
        {entityType === 'SHOP' && <ShopFooter
          onClickHome={onClickHome}
          onClickShop={onClickShop}
          onClickContact={onClickContact}
          centerAligned={centerAligned}
          linkFontStyle={linkFontStyle}
        />}
        {entityType === 'ORDER' && <OrderFooter />}
      </div>
    </Medium>
  );
};

const ShopFooter = ({
  onClickHome,
  onClickShop,
  onClickContact,
  centerAligned = false,
  linkFontStyle={},
}) => {
  return (
    <div className={`small-12 ${centerAligned ? "" : "large-6"} columns`}>
      <a style={linkFontStyle} onClick={onClickHome}>
        Home
      </a>
      <a style={linkFontStyle} onClick={onClickShop}>
        Shop
      </a>
      <a style={linkFontStyle} onClick={onClickContact}>
        Contact
      </a>
    </div>
  );
};

const OrderFooter = () => {
  const {
    entityNumber,
    entity,
  } = usePublicViewEntityContext();
  const company_name = entity.company?.company_name || '';
  const client_name = entity.client_name || '';
  const company_avatar = entity.company?.company_avatar || entity.company_data?.company_avatar?.original || '';
  const order_template = entity.order_template ?? null;
  const rep = entity.resku_form && order_template?.footer_user_type === 'CLIENT-REP' ? entity.client_rep : entity.sales_rep;
  const address = entity.resku_form && entity.order_template?.address
    ? entity.order_template?.address
    : entity.company_address;

  return (
    <>
      <div className="small-12 medium-9 columns" style={{ textAlign: 'left' }}>
        {company_avatar && <Img className="company-avatar" style={{ paddingRight: '24px' }} src={company_avatar} />}
        <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
          {company_name && <H1 style={{ marginBottom: '8px' }}>{company_name}</H1>}
          <Text noBottom>Presentation #{entityNumber}{client_name ? ` for ${client_name}` : ''}</Text>
          <Text noBottom>{entity.job_name || ''}</Text>
        </div>
      </div>

      <Col className="small-12 medium-3 columns" style={{ textAlign: 'right' }}>
        <div style={{ textAlign: 'left', paddingBottom: 16 }}>
          <H5 noBottom>{(rep?.contact_first_name || '') + " " +  (rep?.contact_last_name || '')}</H5>
          <Text noBottom>{rep?.contact_email || ''}</Text>
        </div>
        <div style={{ textAlign: 'left' }}>
          <H5 noBottom>Billing Address</H5>
          <Text noBottom>{address?.address_line_1 || ''}</Text>
          <Text noBottom>{address?.address_city || ''}, {address?.address_state || ''} {address?.address_postal || ''}</Text>
          <Text noBottom>{address?.address_country || ''}</Text>
        </div>
      </Col>
    </>
  );
};

export default Footer;
