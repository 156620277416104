import { Col, colors, CouponIcon, Input, Row } from "@commonsku/styles";
import React from "react";
import { usePublicViewEntityContext } from "../../../context/PublicViewEntityProvider";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";

export default function EnterCouponSection({
  code,
  errors,
  onChangeCode,
  checkCoupon,
}) {
  const { templateColor } = usePublicViewTemplateDataContext();
  const { entityBuyInventory, entityAggregate, allowCouponCheckout } = usePublicViewEntityContext();
  if(+entityAggregate === 0 || !allowCouponCheckout || !!entityBuyInventory) {
    return null;
  }

  return (
    <tr>
      <td style={{ paddingTop: '5px' }} colSpan={'100%'}>
        <Row>
          <Col xs={9} sm={10} style={{ paddingRight: 16, }}>
            <div style={{ position: 'absolute', marginTop: '2px', marginLeft: '5px' }}>
              <CouponIcon color={errors.discount ? colors.errors.main : templateColor} mr={5} mt={5} />
            </div>
            <Input
              type="text"
              value={code}
              style={{
                paddingLeft: '35px',
                width: '100%',
                ...(errors.discount ? {
                  borderColor: colors.errors.main,
                  marginBottom: 5,
                } : {}),
              }}
              placeholder="Enter coupon code"
              onChange={e => onChangeCode(e.target.value)}
            />
            {errors.discount && <p style={{ color: colors.errors.main, marginBottom: 5, }}>{errors.discount}</p>}
          </Col>
          <Col xs={3} sm={2}>
            <a className="button"
              style={{ width: '100%', backgroundColor: templateColor, borderColor: templateColor }}
              onClick={e => {e.preventDefault(); checkCoupon();}}>Apply</a>
          </Col>
        </Row>
      </td>
    </tr>
  );
}
