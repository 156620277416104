import { get } from "lodash";
import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Col, Row, HelpIcon, Text, Select, Csku } from "@commonsku/styles";

import { window } from "../../global";
import { setFilter } from "../../redux/gallery";
import { createDeleteTemp } from "../../actions/temp";
import { getFullCart } from "../../selectors";

import { Medium } from "../ScreenSize";
import {
  CartIconLink,
  TEMPLATE_SIDEBAR_WIDTH,
  getShopHeaderTitle,
} from "./helpers";
import useWindowSize from "../../hooks/useWindowSize";
import IntroPopup from "./IntroPopup";
import SmallNav from "./Header/SmallNav";
import { usePublicViewEntityContext } from "../../context/PublicViewEntityProvider";
import { usePublicView } from "../../context/PublicViewProvider";
import { usePublicViewTemplateDataContext } from "../../context/PublicViewTemplateDataProvider";

function getStateFromProps(props) {
  const template_data = props.template_data;
  const { headerTitle, headerTitleType } = getShopHeaderTitle(
    template_data,
    props.title
  );
  return {
    title: headerTitle,
    title_type: headerTitleType,
    darken: (template_data.darken || { value: 1 }).value,
  };
}

const POSTER_THEME_FONT_STYLE = {
  fontFamily: "Bebas Neue",
  fontWeight: "400",
  titleFontSize: "24px",
  itemFontSize: "1.5rem",
};

const POSTER_THEME_MOBILE_NAV_STYLE = {
  position: "fixed",
  top: 0,
  zIndex: 99,
};

const POSTER_THEME_LINK_FONT_STYLE = {
  textAlign: "center",
  color: "var(--neutrals-90-body-text-2-a-4-d-63, #2A4D63)",
  fontSize: POSTER_THEME_FONT_STYLE.itemFontSize,
  fontFamily: POSTER_THEME_FONT_STYLE.fontFamily,
  lineHeight: "2rem",
  textTransform: "uppercase",
};

const POSTER_THEME_LINK_STYLE = {
  width: "100%",
  padding: "1rem",
  textAlign: "center",
  overflow: "hidden",
};

function MainMenu({
  filter,
  onClickHome,
  onClickAllProducts,
  onClickIntro,
  onChangeFilter,
  isPosterTheme,
  linkColor,
  isShopRouteActive,
  isHomeRouteActive,
  categories,
}) {
  const {
    templateData,
    templateColor,
  } = usePublicViewTemplateDataContext();

  const [vw, vh] = useWindowSize();
  // (64 + 24 * 2): shop title height + padding; (48 + 16 * 2): help icon height + padding
  // 60: space under help icon; 64 * 2: HOME & ALL PRODUCTS height
  const categoriesLen = Math.floor(
    (vh - (64 + 24 * 2) - (48 + 16 * 2) - 60 - 64 * 2) / 64
  );
  const navCategories = categories.slice(0, categoriesLen);
  const moreCategoriesOption = { value: "", label: "MORE CATEGORIES" };
  const extraCategoriesOptions =
    categories.length > categoriesLen
      ? categories.slice(categoriesLen).map((it) => ({
          value: it.item_id,
          label: it.item_name.toUpperCase(),
        }))
      : [];

  const extraCategoriesSelectValue = () => {
    if (extraCategoriesOptions.length === 0) return null;

    if (filter === "shop") return moreCategoriesOption;

    return (
      extraCategoriesOptions.find((it) => it.value === filter) ||
      moreCategoriesOption
    );
  };
  const isHiddenCategorySelected =
    extraCategoriesOptions.length > 0 &&
    extraCategoriesSelectValue() !== moreCategoriesOption;

  const linkUnderlined = +templateData.sidebar_nav_underlined === 1;
  return (
    <Row style={{ width: "100%" }}>
      <>
        <LinkItem
          color={linkColor}
          onClick={onClickHome}
          active={isHomeRouteActive}
          underline={linkUnderlined}
          isPosterTheme={isPosterTheme}
        >
          Home
        </LinkItem>
        <LinkItem
          color={linkColor}
          onClick={onClickAllProducts}
          active={isShopRouteActive}
          underline={linkUnderlined}
          isPosterTheme={isPosterTheme}
        >
          All Products
        </LinkItem>
        {navCategories.map((it) => (
          <LinkItem
            key={"category-nav-item-" + it.item_id}
            isPosterTheme={isPosterTheme}
            color={linkColor}
            onClick={() => onChangeFilter(it.item_id)}
            active={filter === it.item_id}
            underline={linkUnderlined}
          >
            {it.item_name}
          </LinkItem>
        ))}
        {extraCategoriesOptions.length > 0 ? (
          <div
            style={{
              display: "flex",
              textAlign: "center",
              width: 190,
              height: 64,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div
              className="extra-categories-options"
              style={{ width: "100%", textAlign: "center", paddingLeft: 10 }}
            >
              <Select
                inPopup
                className={`extra-categories-select ${
                  linkUnderlined && !isHiddenCategorySelected
                    ? "neutrals-90"
                    : ""
                }${isHiddenCategorySelected ? "active" : ""}`}
                options={extraCategoriesOptions}
                value={extraCategoriesSelectValue()}
                onChange={(e) => !!e.value && onChangeFilter(e.value)}
                singleValueStyles={{
                  ...(isPosterTheme
                    ? POSTER_THEME_LINK_FONT_STYLE
                    : {
                        fontFamily: "var(--font-family-bold)",
                        fontSize: 17,
                      }),
                  color: templateColor,
                }}
              />
            </div>
          </div>
        ) : null}
        <a onClick={onClickIntro}  style={{ margin: "0 auto", width: "100%", textAlign: "center" }}>
          <HelpIcon
            style={{ marginRight: 0, marginTop: 3 }}
            color={templateColor}
            height={"100%"}
            width={"42px"}
          />
        </a>
      </>
    </Row>
  );
}

function ShopTitle(props) {
  const { title, title_type, style = {}, isPosterTheme } = props;
  const {
    entityBuyInventory,
  } = usePublicViewEntityContext();
  const { templateColor } = usePublicViewTemplateDataContext();
  const titleH1Style = isPosterTheme
    ? {
        fontSize: POSTER_THEME_FONT_STYLE.titleFontSize,
        fontWeight: POSTER_THEME_FONT_STYLE.fontWeight,
        fontFamily: POSTER_THEME_FONT_STYLE.fontFamily,
      }
    : {};

  return (
    <div
      id="shop-title"
      style={{
        padding: "24px 0",
        textAlign: "center",
        width: TEMPLATE_SIDEBAR_WIDTH,
        ...style,
      }}
    >
      <Csku as="div" forceStyles>
        {"text" === title_type ? (
          <>
            <Text
              as="p"
              style={{
                color: !!entityBuyInventory ? "white" : templateColor,
                width: "100%",
                overflowWrap: "break-word",
                lineHeight: "48px",
                ...titleH1Style,
              }}
            >
              {title}
            </Text>
            {!!entityBuyInventory && (
              <p style={{ margin: 0, fontSize: "small" }}>(Buy Inventory)</p>
            )}
          </>
        ) : (
          <img src={title} alt="" />
        )}
      </Csku>
    </div>
  );
}

function LinkItem({
  children,
  color,
  active = false,
  underline = false,
  style = {},
  onClick,
  isPosterTheme,
}) {
  return (
    <Text
      as="p"
      style={{
        cursor: "pointer",
        color: color === "white" ? "black" : !active ? color : "#fff",
        background: active ? color : "#fff",
        padding: 14,
        margin: 0,
        width: TEMPLATE_SIDEBAR_WIDTH,
        height: 64,
        textAlign: "center",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontWeight: isPosterTheme
          ? POSTER_THEME_FONT_STYLE.fontWeight
          : "inherit",
        fontSize: isPosterTheme ? POSTER_THEME_FONT_STYLE.itemFontSize : 18,
        fontFamily: isPosterTheme
          ? POSTER_THEME_FONT_STYLE.fontFamily
          : "var(--font-family-bold)",
        ...(underline
          ? {
              background: "#fff",
              color: "var(--color-neutrals-90)",
              ...(active
                ? {
                    color: color,
                    textDecorationLine: "underline",
                    textUnderlinePosition: "under",
                    textDecorationThickness: 2,
                  }
                : {}),
            }
          : {}),
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </Text>
  );
}

const CartIcon = ({
  isCartOpen,
  isItemSelected,
  changeCartStyleWhenItemSelected,
  totalQuantity,
}) => {
  const commonStyle = {
    zIndex: 99,
    position: "fixed",
    height: isCartOpen ? 84 : 60,
    width: isCartOpen ? 84 : 60,
    textAlign: "center",
    borderRadius: isCartOpen ? "50% 0 0 50%" : "50%",
    background: !isCartOpen ? "#fff" : "#EDF4F7",
    transition: "all 0.3s ease-in-out",
  };

  const normalStyle = {
    top: isCartOpen ? 0 : 24,
    right: isCartOpen ? 0 : 24,
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.25)",
  };

  const itemSelectedStyle = {
    top: isCartOpen ? 0 : 12,
    right: isCartOpen ? 0 : 12,
  };

  return (
    <div
      style={{
        ...commonStyle,
        ...(changeCartStyleWhenItemSelected && isItemSelected
          ? itemSelectedStyle
          : normalStyle),
      }}
    >
      <CartIconLink
        useV2
        totalQuantity={totalQuantity}
        overrideStyles={isCartOpen ? { paddingLeft: "24px" } : {}}
      />
    </div>
  );
};

function Sidebar(props) {
  const {
    first_view,
    onFirstView,
    isItemSelected = false,
    transparentMobileNav = false,
    changeCartStyleWhenItemSelected = false,
  } = props;
  const { filter, showCart, onClickCart, } = usePublicView();
  const {
    baseUrl,
    title,
    isShop,
    introduction,
    basePathname,
    baseEntityUrl,
    entityBuyInventory,
    entityOrder: gallery,
  } = usePublicViewEntityContext();
  const {
    isPoster,
    template_data,
    templateColor,
    showHelpScreen,
    getShopCategoryWithImages,
  } = usePublicViewTemplateDataContext();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const shopCart = useSelector(
    s => getFullCart(s, { buy_inventory: entityBuyInventory })
  );

  const items = get(gallery, ["items"]) || [];
  const categories = getShopCategoryWithImages(items);
  const linkColor = !!entityBuyInventory ? "white" : templateColor;
  const isShopRouteActive = filter === "shop";
  const isHomeRouteActive = !filter && [baseUrl, `${baseUrl}/`].includes(
    window.location.pathname
  );

  const [state, setState] = useState({
    menuOpen: false,
    shopIntroPopup: false,
    showBackButton: false,
    ...getStateFromProps({
      template_data,
      title,
    }),
  });
  const totalQuantity = ((shopCart || {}).items || []).reduce(
    (acc, v) => acc + parseFloat(v.quantity),
    0
  );

  const sidebarStyle = {
    width: TEMPLATE_SIDEBAR_WIDTH,
    zIndex: 10,
    height: "100%",
    top: 0,
    bottom: "auto",
    paddingRight: 0,
    background: "#fff",
  };
  if (!!entityBuyInventory) {
    sidebarStyle.backgroundColor = "#00D374";
    sidebarStyle.color = "white";
  }

  function onClickIntro(e = null) {
    e && e.preventDefault();
    setState((s) => ({ ...s, shopIntroPopup: true }));
  }

  function handleClickMenu(e) {
    e.preventDefault();
    setState((s) => ({ ...s, menuOpen: !s.menuOpen }));
  }

  const onClickHome = () => {
    dispatch(setFilter(""));
    navigate(
      baseUrl + (!!entityBuyInventory ? "?buy_inventory=true" : "")
    );
  };

  const onClickAllProducts = () => {
    dispatch(setFilter("shop"));
    navigate(
      baseEntityUrl +
        (!!entityBuyInventory ? "?buy_inventory=true" : "")
    );
  };

  const onChangeFilter = (filter) => {
    navigate(
      baseEntityUrl +
        (!!entityBuyInventory ? "?buy_inventory=true" : "")
    );
    dispatch(setFilter(filter));
  };

  useEffect(() => {
    if (
      first_view &&
      introduction &&
      introduction.replace(/<(?:.|\n)*?>/gm, "") !== "" &&
      !window.location.href.match(/receipt/g)
    ) {
      showHelpScreen && onClickIntro();
      onFirstView();
    }
    if (
      !filter &&
      window.location.href.match(basePathname) &&
      window.location.href.match(basePathname).length > 1
    ) {
      onClickAllProducts();
    }
  }, []);

  useEffect(() => {
    const newState = getStateFromProps({
      template_data,
      title,
    });
    setState((s) => ({ ...s, ...newState }));
  }, [template_data, title]);

  const MainMenuComponent = (
    <MainMenu
      filter={filter}
      gallery={gallery}
      onClickHome={onClickHome}
      onClickAllProducts={onClickAllProducts}
      onClickIntro={onClickIntro}
      onChangeFilter={onChangeFilter}
      isPosterTheme={isPoster}
      items={items}
      categories={categories}
      linkColor={linkColor}
      isHomeRouteActive={isHomeRouteActive}
      isShopRouteActive={isShopRouteActive}
    />
  );

  const ShopTitleComponent = (
    <ShopTitle
      title={state.title}
      title_type={state.title_type}
      isPosterTheme={isPoster}
    />
  );

  return (
    <>
      {state.shopIntroPopup &&
        ReactDOM.createPortal(
          <IntroPopup
            onClose={() => setState((s) => ({ ...s, shopIntroPopup: false }))}
          />,
          document.getElementById("root")
        )}
      <Medium style={sidebarStyle}>
        {isShop && ReactDOM.createPortal(
          <CartIcon
            totalQuantity={totalQuantity}
            onClickCart={onClickCart}
            isCartOpen={showCart}
            isItemSelected={isItemSelected}
            changeCartStyleWhenItemSelected={changeCartStyleWhenItemSelected}
          />,
          document.getElementById("root")
        )}
        <Row>
          <Col xs>{ShopTitleComponent}</Col>
          <Col xs mdStyle={{ display: "flex" }}>
            {MainMenuComponent}
          </Col>
        </Row>
      </Medium>
      <SmallNav
        filter={filter}
        menuOpen={state.menuOpen}
        title={state.title}
        title_type={state.title_type}
        categories={categories}
        isCartOpen={showCart}
        totalQuantity={totalQuantity}
        onClickHome={onClickHome}
        onClickAllProducts={onClickAllProducts}
        onClickCart={onClickCart}
        onClickIntro={onClickIntro}
        onChangeFilter={onChangeFilter}
        handleClickMenu={handleClickMenu}
        showBackButton={state.showBackButton}
        transparentOnTop={isPoster && transparentMobileNav}
        overrideStyles={isPoster && POSTER_THEME_MOBILE_NAV_STYLE}
        linkFontOverrideStyles={isPoster && POSTER_THEME_LINK_FONT_STYLE}
        linkOverrideStyles={isPoster && POSTER_THEME_LINK_STYLE}
        secondNavTitle={isPoster && "ALL PRODUCTS"}
        linkColor={linkColor}
        isHomeRouteActive={isHomeRouteActive}
        isShopRouteActive={isShopRouteActive}
        linkUnderline={isPoster}
        withOverlay={!isPoster}
      />
    </>
  );
}

export const mapStateToProps = (state, ownProps) => ({
  first_view: state.temp.first_view,
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  onFirstView: () => dispatch(createDeleteTemp("first_view")),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
