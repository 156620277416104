import React, { FC, memo, useState } from "react";
import { ItemMetaData } from "../../../../interfaces/Metadata";
import { colors, LabeledInput, Text } from "@commonsku/styles";
import { LabeledSelect } from "../../../helpers/Select";
import { TNumber } from "../../../../interfaces/common";

interface QuestionProps {
  m: ItemMetaData;
  value: string;
  onUpdateProductMetadata: (
    metadata_id: string,
    label: string,
    value: string,
    parent_id: string,
    type: string,
    mandatory: TNumber,
    options: {
      key: string;
      value: string;
    } | null,
    shop_id: string
  ) => void;
  shop_id: string;
}

const Question: FC<QuestionProps> = memo(
  ({ m, value, onUpdateProductMetadata, shop_id }) => {
    const [isTouched, setIsTouched] = useState<boolean>(false);
    const isRequired = "" + m.mandatory === "1";
    const options =
      m.type === "SELECT"
        ? JSON.parse(m.value || "[]").map((v: string) => ({
            key: v,
            value: v,
          }))
        : [];

    const hasError = isRequired && !value.trim() && isTouched;

    return [
      m.type === "SELECT" ? (
        <LabeledSelect
          key={m.metadata_id}
          name={"metadata-item-" + m.metadata_id}
          id={"metadata-item-" + m.metadata_id}
          required={isRequired}
          label={`${m.label}${isRequired ? "" : " (optional)"}`}
          placeholder={m.label}
          value={value}
          onChange={(e: any /** Need better type inference */) =>
            onUpdateProductMetadata(
              m.metadata_id,
              m.label,
              e.value,
              m.parent_id,
              m.type,
              m.mandatory,
              options,
              shop_id
            )
          }
          options={options}
          error={hasError}
          parentStyle={{ marginBottom: 14 }}
          data-test-id={`product-question-${m.metadata_id}`}
          onBlur={() => setIsTouched(true)}
        />
      ) : (
        <LabeledInput
          key={m.metadata_id}
          id={"metadata-item-" + m.metadata_id}
          required={isRequired}
          label={`${m.label}${isRequired ? "" : " (optional)"}`}
          value={value}
          onChange={(e) =>
            onUpdateProductMetadata(
              m.metadata_id,
              m.label,
              e.target.value,
              m.parent_id,
              m.type,
              m.mandatory,
              null,
              shop_id
            )
          }
          placeholder={m.label}
          error={hasError}
          style={{
            ...(hasError ? { marginBottom: 0 } : {}),
          }}
          data-test-id={`product-question-${m.metadata_id}`}
          onBlur={() => setIsTouched(true)}
        />
      ),
      hasError && (
        <Text
          key={"error-" + m.metadata_id}
          as="p"
          style={{ marginBottom: 16, color: colors.errors.main }}
        >
          This field is required
        </Text>
      ),
    ];
  }
);

export default Question;
