import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { getImageSrc } from '../../../utils';
import { DEFAULT_COLOR } from '../../shop/helpers';
import { useSelector } from 'react-redux';
import { getItemComments } from '../../../selectors/items';
import TileComments from '../TileComments';
import Orders from '../Orders';
import Inventory from '../Inventory';

function GridInline(props) {
  const itemComments = useSelector(s => getItemComments(s, { item_id: props.item.item_id }));

  const [state, setState] = useState({ hover: false });

  function onMouseEnter(e) {
    setState({ ...state, hover: true });
  }

  function onMouseLeave(e) {
    setState({ ...state, hover: false });
  }

  function getItemImage(item) {
    if (!item || !item.images || !item.images.length) {
      return null;
    }
    return item.images[0];
  }

  const {
    item,
    currency_symbol,
    index,
    total,
    is_shop_free,
    padding,
    onAddItem,
    onRemoveItem,
    hide_pricing='0',
    template_color = DEFAULT_COLOR,
    showInventory = false,
    showItemCount = true,
    useV2 = false,
    imgSize = 'large',
  } = props;
  if ('OPTION' !== item.parent_type) {
    return null;
  }
  let item_prices = [];
  if (item.options && item.options.length) {
    item_prices = item.options.filter(o => 0 == o.hidden && 0 != o.unit_price).map(o => parseFloat(o.unit_price));
  } else if (item.min_price && item.max_price) {
    item_prices = [item.min_price, item.max_price];
  }
  const minimum_price = Math.min.apply(null, item_prices);
  const maximum_price = Math.max.apply(null, item_prices);
  let displayPrice = null;
  if (item_prices.length && +hide_pricing !== 1) {
    displayPrice = minimum_price === maximum_price
      ? `${currency_symbol}${minimum_price.toFixed(2)}`
      : `${currency_symbol}${minimum_price.toFixed(2)} - ${currency_symbol}${maximum_price.toFixed(2)}`;
  }
  const onClick = props.onClick ?
    e => props.onClick() : null;

  return (
    <div
      id={'item-'+item.item_id}
      className={
        index === total
        ? `product-column small-12 medium-3 columns end`
        : `product-column small-12 medium-4 large-3 columns`
      }
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div id="feat-prod" onClick={() => onClick()}>
        <div className="prod-img" style={{
          backgroundImage: `url('${getImageSrc(getItemImage(item), imgSize)}')`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          textAlign: 'center',
          transition: '300ms',
        }}>
        </div>
        <h4>{item.item_name}</h4>
        {!+is_shop_free && <p style={{ color: template_color }}>{displayPrice}</p>}
        <TileComments
          item={item}
          comments={itemComments}
          showComments={props.showComments ?? true}
          left={0}
          top={0}
          numberLeft={Object.values(itemComments || item.comments).length > 99 ? 0 : 6}
          numberTop={15}
        />
        <Orders
          hover={state.hover}
          item={item}
          onAddItem={onAddItem}
          onRemoveItem={onRemoveItem}
          padding={padding}
          template_color={template_color}
          showItemCount={showItemCount}
          useV2={useV2}
        />
        <Inventory
          item={item}
          template_color={template_color}
          showInventory={showInventory}
        />
      </div>
    </div>
  );
}

GridInline.propTypes = {
  item: PropTypes.any,
  onAddItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  template_color: PropTypes.string,
  showItemCount: PropTypes.bool,
  aggregate: PropTypes.any,
};

export default GridInline;
