import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AddressAutocompleteInput, Col, colors, DoubleArrowIcon, fontFamilies, Grid, GridItem, Input, LabeledIconInput, LabeledInput, Popup, Row, SearchIcon, Text } from '@commonsku/styles';
import { getAddressCityAndState, getAddressPostalCodeAndCountry } from '../../address/utils';
import { get } from 'lodash';
import { LabeledSelect } from '../../helpers';
import { countries, states } from '../../../geo';
import { TemplateButton, TemplateTextButton } from '../helpers';
import { hexToHSL } from '../../../utils';
import { useContainerScroll } from '../../../hooks';
import { ScrollContainer } from '../../../hooks/useContainerScroll';
import config from '../../../config';

const VIEW_TYPES = {
  ADDRESSES_LIST: 'ADDRESSES_LIST',
  ADD_NEW: 'ADD_NEW',
  ADD_UPDATE: 'ADD_UPDATE',
};

const SelectAddressPopup = (props) => {
  const {
    selectedId,
    addresses=[],
    customAddress = {},
    color = colors.primary1.main,
    onClose,
    onSelectAddress,
    onAddAddress,
    hideSelect,
    canAddCustomAddress=false,
  } = props;

  /**
   * @type {React.MutableRefObject<HTMLDivElement>} popupRef
   */
  const popupRef = useRef(null);
  /**
   * @type {React.MutableRefObject<HTMLDivElement>} ref
   */
  const ref = useRef(null);

  const popupRect = popupRef.current ? popupRef.current.getBoundingClientRect() : {
    top: 0,
    bottom: 0,
    height: 0,
  };

  const [searchQuery, setSearchQuery] = useState('');
  const [viewType, setViewType] = useState(VIEW_TYPES.ADDRESSES_LIST);
  const [showErrors, setShowErrors] = useState(false);
  const [newAddress, setNewAddress] = useState({
    address_company: get(customAddress, 'address_company') || '',
    address_line_1: get(customAddress, 'address_line_1') || '',
    address_line_2: get(customAddress, 'address_line_2') || '',
    address_city: get(customAddress, 'address_city') || '',
    address_state: get(customAddress, 'address_state') || '',
    address_postal: get(customAddress, 'address_postal') || '',
    address_country: get(customAddress, 'address_country') || '',
  });

  const isAddView = [VIEW_TYPES.ADD_NEW, VIEW_TYPES.ADD_UPDATE].includes(viewType);
  const popupMaxHeight = isAddView && showErrors && !newAddress.address_line_1 ? 655 : 637;

  const {
    innerWidth,
    canScrollDown,
    canScrollUp,
    scrollDown,
    scrollUp,
  } = useContainerScroll(ref, [viewType, showErrors, searchQuery]);

  const addressesFilter = useMemo(() => {
    if (!searchQuery) { return addresses; }

    const query = searchQuery.toLowerCase().trim();
    return addresses.filter(
      ad => (ad.address_company || '').toLowerCase().includes(query)
          || (ad.address_line_1 || '').toLowerCase().includes(query)
          || (ad.address_line_2 || '').toLowerCase().includes(query)
          || (ad.address_line_3 || '').toLowerCase().includes(query)
          || (ad.address_line_4 || '').toLowerCase().includes(query)
          || (ad.address_city || '').toLowerCase().includes(query)
          || (ad.address_state || '').toLowerCase().includes(query)
          || (ad.address_country || '').toLowerCase().includes(query)
    );
  }, [addresses, searchQuery]);

  const onUpdateAddressField = useCallback((field, value) => {
    setNewAddress(s => ({ ...s, [field]: value }));
  }, []);

  const onUpdateAddress = useCallback((newAddress = {}) => {
    setNewAddress(s => ({ ...s, ...newAddress }));
  }, []);

  const onCreateNewAddress = () => {
    if (!newAddress.address_line_1 || !newAddress.address_company) {
      setShowErrors(true);
      return;
    }

    onAddAddress(newAddress);
    onClose();
  };

  useEffect(() => {
    if (addresses.length === 0
        && canAddCustomAddress
        && !get(customAddress, 'address_line_1')
    ) {
      setViewType(VIEW_TYPES.ADD_NEW);
    }
  }, [addresses, canAddCustomAddress, customAddress]);

  return (
    <Popup
      ref={popupRef}
      contentClassName={`select-address-popup-content ${isAddView ? 'add-new' : 'select-existing'}`}
      style={{
        maxWidth: 750,
        maxHeight: popupMaxHeight,
        overflow: 'hidden',
      }}
      onClose={onClose}
      noCloseButton={isAddView}
      title={isAddView ? 'Custom Address' : 'Select Address'}
      header={<Row style={{ paddingBottom: 8 }}>
        <Col xs sm={8}>
          <Text style={{
            fontSize: '30px',
            fontFamily: fontFamilies.bold,
            color: 'var(--color-neutrals-100)',
          }} className="title">{isAddView ? 'Custom Address' : 'Select Address'}</Text>
        </Col>
        {!isAddView && <Col xs sm={4} style={{ textAlign: 'right' }}>
          <TemplateButton className="close_popup_btn" template_color={color} onClick={onClose}>Close</TemplateButton>
        </Col>}
      </Row>}
    >
      {viewType === VIEW_TYPES.ADDRESSES_LIST && <Row>
        <Col xs padded style={{
          paddingTop: 24,
          position: 'sticky',
          top: 0,
          bottom: 'auto',
          background: '#fff',
        }}>
          <LabeledIconInput
            Icon={<SearchIcon />}
            label=''
            placeholder='Search'
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            iconLabelStyles={{ background: 'white', }}
            iconColor={colors.neutrals[50]}
          />
        </Col>
        <Col ref={ref} xs style={{
          paddingBottom: 10,
          minHeight: 100,
          maxHeight: `calc(${popupRect.height}px - ${innerWidth > 640 ? 220 : 280}px)`,
          overflow: 'auto',
        }}>
          <AddressesGrid
            ref={ref}
            addresses={addressesFilter}
            color={color}
            onSelectAddress={(addressId) => {
              if (addressId === 'ADD') {
                setViewType(VIEW_TYPES.ADD_UPDATE);
                return;
              }
              onSelectAddress && onSelectAddress(addressId);
              onClose && onClose();
            }}
            selectedId={selectedId}
            customAddress={(newAddress.address_company || newAddress.address_line_1) ? newAddress : null}
          />

          {addressesFilter.length > 0 && ref.current ? <>
            <ScrollContainer
              isUp
              onClick={scrollUp}
              canScroll={canScrollUp()}
              color={color}
              width={ref.current.clientWidth - 10}
              zIndex={4}
              top={150-18 + (innerWidth > 640 ? 0 : 40)}
              bottom={'auto'}
              left={22}
            />

            <ScrollContainer
              isUp={false}
              onClick={scrollDown}
              canScroll={canScrollDown()}
              color={color}
              width={ref.current.clientWidth - 10}
              zIndex={4}
              top={'auto'}
              bottom={innerWidth > 640 ? 44 : 64}
              left={22}
            />
          </> : null}
        </Col>
        {canAddCustomAddress && <Col xs style={{
          textAlign: 'center',
          position: 'sticky',
          top: 'auto',
          bottom: 0,
          zIndex: 9,
          background: '#fff',
        }}>
          <TemplateTextButton
            className="add_custom_address_btn"
            template_color={color}
            onClick={() => setViewType(VIEW_TYPES.ADD_UPDATE)}
          >+ Add Custom Address</TemplateTextButton>
        </Col>}
      </Row>}
      {isAddView && canAddCustomAddress && <Row>
        <Col xs padded>
          <NewAddressForm
            color={color}
            form={newAddress}
            updateField={onUpdateAddressField}
            onUpdateForm={onUpdateAddress}
            onSubmit={onCreateNewAddress}
            onCancel={() => {
              if (viewType === VIEW_TYPES.ADD_NEW) {
                onClose();
              } else {
                setViewType(VIEW_TYPES.ADDRESSES_LIST);
              }
            }}
            showErrors={showErrors}
          />
        </Col>
      </Row>}
    </Popup>
  );
};

const NewAddressForm = ({
  color,
  form,
  updateField,
  onUpdateForm,
  onSubmit,
  onCancel,
  showErrors=false,
}) => {
  const country_options = countries.map(c => ({ key: c, value: c }));
  const base_states = get(states, form.address_country, []);
  const state_options = [{ key: '', value: form.address_country === 'Canada' ? 'Select a Province...' : 'Select a State...' }].concat(base_states.map(s => ({ key: s.abbr, value: s.name })));

  return (
    <Grid
      gap={15}
      columns={2}
      className="new_address_form"
    >
      <GridItem colSpan={2}>
        <LabeledInput
          label='Title of Address'
          name='address_company'
          id='address_company'
          value={form.address_company}
          onChange={e => updateField('address_company', e.target.value)}
          style={{ marginBottom: 0 }}
          required
        />
        {showErrors && !form.address_company && <Text as={"p"} style={{ color: colors.errors.main, marginBottom: 0 }}>
          This field is required
        </Text>}
      </GridItem>
      <GridItem colSpan={2}>
        <AddressAutocompleteInput
          apiKey={config.googleMapsApiKey}
          name='address_line_1'
          id='address_line_1'
          type="text"
          required
          label='Address Line 1'
          placeholder="123 Street St."
          value={form.address_line_1}
          country={config.addressAutocompleteCountries}
          style={{ marginBottom: 0 }}
          onChange={v => {
            const addr = v.parsed_address;
            onUpdateForm(addr);
          }}
          onInputChange={v => {
            updateField('address_line_1', v);
          }}
          optionsListStyle={{ marginTop: 65 }}
        />
        {showErrors && !form.address_line_1 && <Text as={"p"} style={{ color: colors.errors.main, marginBottom: 0 }}>
          This field is required
        </Text>}
      </GridItem>
      <GridItem colSpan={2}>
        <LabeledInput
          label='Address Line 2'
          name='address_line_2'
          id='address_line_2'
          value={form.address_line_2}
          onChange={e => updateField('address_line_2', e.target.value)}
          style={{ marginBottom: 0 }}
        />
      </GridItem>
      <GridItem colSpan={1}>
        <LabeledInput
          label='City'
          name='address_city'
          id='address_city'
          value={form.address_city}
          onChange={e => updateField('address_city', e.target.value)}
          style={{ marginBottom: 0 }}
        />
      </GridItem>
      <GridItem colSpan={1}>
        <LabeledSelect
            type="text"
            label='Country'
            name='address_country'
            id='address_country'
            placeholder="Country"
            options={country_options}
            value={form.address_country}
            onChange={e => updateField('address_country', e ? e.value : '')}
            parentStyle={{ marginTop: '-7px', }}
            inPopup
          />
      </GridItem>
      <GridItem colSpan={1}>
        {base_states.length ?
          <LabeledSelect
            type="text"
            label='State/Province'
            name='address_state'
            id='address_state'
            placeholder="State/Province"
            options={state_options}
            value={form.address_state}
            onChange={e => updateField('address_state', e ? e.value : '')}
            parentStyle={{ marginTop: '-7px', }}
            inPopup
          /> :
          <LabeledInput
            type="text"
            label='State/Province'
            name='address_state'
            id='address_state'
            placeholder="State/Province"
            value={form.address_state}
            onChange={e => updateField('address_state', e.target.value)}
            style={{ marginBottom: 0, }}
          />}
      </GridItem>
      <GridItem colSpan={1}>
        <LabeledInput
          label='Zip / Postal Code'
          name='address_postal'
          id='address_postal'
          value={form.address_postal}
          onChange={e => updateField('address_postal', e.target.value)}
          style={{ marginBottom: 0 }}
        />
      </GridItem>
      <GridItem colSpan={2}>
        <TemplateButton
          template_color={color}
          style={{ width: '100%' }}
          onClick={onSubmit}
          id='add_address_btn'
        >Add Address</TemplateButton>
      </GridItem>
      <GridItem colSpan={2}>
        <TemplateButton
          template_color={color}
          isSecondary
          style={{ width: '100%' }}
          onClick={onCancel}
          id='cancel_new_address_btn'
        >Cancel</TemplateButton>
      </GridItem>
    </Grid>
  );
};

const AddressesGrid = React.forwardRef(
  ({
    color,
    selectedId,
    customAddress,
    addresses,
    onSelectAddress,
  }, ref) => {
    const hslColor = hexToHSL(color);
    return (
      <Grid
        ref={ref}
        gap={15}
        columns={3}
        style={{ padding: 10 }}
        className="addresses-grid"
      >
        {addresses.map(ad => (
          <GridItem
            key={'address-' + ad.address_id}
            id={'address-' + ad.address_id}
            colSpan={{ xs: 3, sm: 1 }}
            style={{
              overflowWrap: 'break-word',
              padding: 10,
              background: color,
              borderRadius: 5,
              cursor: 'pointer',
              ...(selectedId === ad.address_id ? {
                background: `hsl(${hslColor[0]}, 100%, 35%)`,
              } : {}),
            }}
            onClick={() => {
              onSelectAddress && onSelectAddress(ad.address_id);
            }}
          >
            <Text
              as="p"
              style={{
                color: '#fff',
                fontWeight: 'bold',
                marginBottom: 5,
              }}>{ad.address_company || ''}</Text>
            <Text as="p" className='address_line_1' style={{ marginBottom: 5, color: '#fff' }}>{ad.address_line_1}</Text>
            {ad.address_line_2 && <Text as="p" className='address_line_2' style={{ marginBottom: 5, color: '#fff' }}>{ad.address_line_2}</Text>}
            {ad.address_line_3 && <Text as="p" className='address_line_3' style={{ marginBottom: 5, color: '#fff' }}>{ad.address_line_3}</Text>}
            {ad.address_line_4 && <Text as="p" className='address_line_4' style={{ marginBottom: 5, color: '#fff' }}>{ad.address_line_4}</Text>}
            <Text as="p" className='cus_address_line_2' style={{ marginBottom: 5, color: '#fff' }}>{getAddressCityAndState(ad)}</Text>
            <Text as="p" className='cus_address_line_3' style={{ marginBottom: 5, color: '#fff' }}>{getAddressPostalCodeAndCountry(ad)}</Text>
          </GridItem>
        ))}
        {customAddress && <GridItem
            key='address-custom'
            className='address-custom'
            colSpan={{ xs: 3, sm: 1 }}
            style={{
              overflowWrap: 'break-word',
              padding: 10,
              background: color,
              borderRadius: 5,
              cursor: 'pointer',
              ...(selectedId === 'ADD' ? {
                background: `hsl(${hslColor[0]}, 100%, 35%)`,
              } : {}),
            }}
            onClick={() => {
              onSelectAddress && onSelectAddress('ADD');
            }}
          >
            <Text
              as="p"
              style={{
                color: '#fff',
                fontWeight: 'bold',
                marginBottom: 5,
              }}>{customAddress.address_company || ''}</Text>
            <Text as="p" className='custom_address address_line_1' style={{ marginBottom: 5, color: '#fff' }}>{customAddress.address_line_1}</Text>
            {customAddress.address_line_2 && <Text as="p" className='custom_address address_line_2' style={{ marginBottom: 5, color: '#fff' }}>{customAddress.address_line_2}</Text>}
            {customAddress.address_line_3 && <Text as="p" className='custom_address address_line_3' style={{ marginBottom: 5, color: '#fff' }}>{customAddress.address_line_3}</Text>}
            {customAddress.address_line_4 && <Text as="p" className='custom_address address_line_4' style={{ marginBottom: 5, color: '#fff' }}>{customAddress.address_line_4}</Text>}
            <Text as="p" className='custom_address address_line_2' style={{ marginBottom: 5, color: '#fff' }}>{getAddressCityAndState(customAddress)}</Text>
            <Text as="p" className='custom_address address_line_3' style={{ marginBottom: 5, color: '#fff' }}>{getAddressPostalCodeAndCountry(customAddress)}</Text>
          </GridItem>}
      </Grid>
    );
  }
);

export default SelectAddressPopup;
