import React from 'react';
import { get, first, upperFirst } from 'lodash';
import { Col, Csku, Input, Row } from '@commonsku/styles';

import { sizeSort } from '../../utils';
import {
  getOptionsFromSkus,
  getSkuFromOptions,
  onChangeItemSkuQuantity,
  getSkuInventory,
  getItemSkus,
  hasSkuInventory,
  AvailableInventory,
  MaximumQuantity,
} from './helpers';
import { SmallOnly, Medium } from '../ScreenSize';
import { LabeledSelect } from '../helpers/Select';
import FreeShopItemSkuSelector from './FreeShopItemSkuSelector';

function getParsedData({
  value,
  skus,
  options,
  axes,
  is_shop_free,
}) {
  const winnowingAxes = axes.filter(a => a !== 'size' && options[a].length > 0);
  const winnowingAxesOptions = axes.reduce(
    (wao, a) => ({
      ...wao,
      [a]: options[a].map(o => ({ key: o, value: o }))
    }),
    {}
  );

  let winnowed = (get(winnowingAxesOptions, [first(winnowingAxes)]) || []).length <= 1 || Object.getOwnPropertyNames(value).length && value[Object.getOwnPropertyNames(value)[0]] !== '' ? true : false;

  const firstAxis = axes.shift();
  const firstAxisOptions = (options[firstAxis] || []).map(o => ({ key: o, value: o }));
  const quantityAxis = axes.includes('size') ? 'size' : axes.includes('dimension') ? 'dimension' : 'sku';
  const sizeaxes = axes.filter(a => a == quantityAxis && options[a].length > 0);
  const filteredOptions = winnowed && is_shop_free != 1 ?
    getOptionsFromSkus(
      skus.filter(
        sku => !!sku.options.filter(o => winnowingAxes.reduce((t, a) => t || (o.option_axis === a && o.option_name === value[a]), false)).length
      )
    ) :
    [];

  if (!filteredOptions.size || !filteredOptions.size.length) {
    const selectedSkus = getSkuFromOptions(skus, value);
    if (1 === selectedSkus.length) {
      filteredOptions.sku = ['Quantity'];
    } else if (selectedSkus.length > 1) {
      filteredOptions.sku = selectedSkus.map(
        product_sku_id => skus.filter(
          sku => product_sku_id === sku.product_sku_id
        ).map(
          sku => !!sku.description ? sku.description : sku.sku
        )[0]
      );
    } else {
      winnowed = false;
    }
  }

  return {
    winnowingAxes,
    winnowingAxesOptions,
    winnowed,
    quantityAxis,
    sizeaxes,
    filteredOptions,
    firstAxis,
    firstAxisOptions,
  };
}

const CartItemSkuSelector = ({
  focus,
  item,
  quantities,
  template_color,
  value,
  onChangeFirstAxis,
  onFocus,
  onChangeQuantity,
  onUpdateQuantity,
  small = false,
  aggregate,
  force_minimum_qty = false,
  buyInventory = false,
  showInventory = true,
  is_shop_free,
  onChangeColorAxisFreeShop,
  onChangeSizeAxisFreeShop,
  availableOptions,
  availableOptionsColor
}) => {
  const hasInventory = hasSkuInventory(item, buyInventory);
  const {
    skus,
    options,
    axes
  } = getItemSkus(item, buyInventory);
  const {
    winnowingAxes,
    winnowingAxesOptions,
    winnowed,
    quantityAxis,
    sizeaxes,
    filteredOptions,
  } = getParsedData({
    value,
    skus,
    options,
    axes,
    is_shop_free,
    item,
    buyInventory,
  });

  const selectStyle = {
    display: 'inline-block',
    minWidth: '300px',
    verticalAlign: 'middle',
    marginLeft: '20px'
  };

  const showMinimumQuantity = (!!buyInventory || item.inventory_items.length === 0) && (!aggregate || force_minimum_qty);
  const handleKeyDown = (axis, option) => e => {
    if ('Enter' === e.key) {
      onUpdateQuantity(axis, option);
    }
  };
  const getInventory = (product_sku_id) => getSkuInventory(item, buyInventory, product_sku_id);
  const showMaximumQuantity = (quantity, maxQuantity) => hasInventory && !buyInventory && quantity === maxQuantity;
  const onChange = (quantityAxis, option) => onChangeItemSkuQuantity(value, item, buyInventory, option, onChangeQuantity, quantityAxis);
  const selectedFreeShopValue = (a) => Array.isArray(value[a]) ? value[a] : [value[a]];

  const winnowingAxesOption = get(winnowingAxesOptions, [first(winnowingAxes)]) || [];

  return (
    <div>
      {!small && <Medium>
        {is_shop_free == 1 && <FreeShopItemSkuSelector
          item={item}
          value={value}
          template_color={template_color}
          sizeaxes={sizeaxes}
          winnowingAxes={winnowingAxes}
          winnowingAxesOptions={winnowingAxesOptions}
          winnowingAxesOption={winnowingAxesOption}
          availableOptions={availableOptions}
          availableOptionsColor={availableOptionsColor}
          onChangeSizeAxisFreeShop={onChangeSizeAxisFreeShop}
          onChangeColorAxisFreeShop={onChangeColorAxisFreeShop}
          selectedFreeShopValue={selectedFreeShopValue}
        />}
        {is_shop_free != 1 && <div className="medium-cart_item_sku_selector">
          {winnowingAxesOption.length > 1 && winnowingAxes.map(a =>
            <div key={a} className="row small-12 columns collapse medium-cart_item_sku_axis_option_container" style={{ padding: 0 }}>
              <Csku forceStyles
                className="columns"
                style={{ paddingTop: '0.25rem', paddingBottom: '14px', }}
                sx={{
                  xs: { width: '100%' },
                  md: { width: '224px' },
                }}
              >
                <LabeledSelect
                  name={a + "-id-select"}
                  label={upperFirst(a)}
                  className="csku-select medium-cart_item_sku_axis_option_select"
                  options={[{ key: '', value: `Select ${a}` }].concat(winnowingAxesOptions[a])}
                  value={value[a]}
                  onChange={option => onChangeFirstAxis(a, option ? option.value : '')}
                  onFocus={() => onFocus(a)}
                  isDisabled={1 === winnowingAxesOptions[a].length}
                />
              </Csku>
            </div>
          )}
          {winnowed &&
            <div className="row small-12 columns collapse" style={{ marginTop: '0.5rem', marginBottom: '0.5rem', borderRadius: '3px', padding: 0 }}>
              <div className="medium-12 columns collapse">
                {winnowingAxes.map(
                  a => <label key={a} style={{ paddingTop: '0.5rem', textTransform: 'capitalize' }}>{value[a]}</label>
                )}
                {showMinimumQuantity && <span className="excludes">{`Minimum Quantity of ${item.minimum_quantity}`}</span>}
                {hasInventory && showInventory && <label style={{ marginTop: showMinimumQuantity ? '0.5rem' : '1.85rem' }}>Available in Inventory</label>}
              </div>
              <div className="medium-12 columns collapse medium-cart_item_sku_axis_qty_container">
                <ItemAxisQuantitites
                  skus={skus}
                  focus={focus}
                  value={value}
                  quantityAxis={quantityAxis}
                  quantities={quantities}
                  filteredOptions={filteredOptions[quantityAxis] ? filteredOptions[quantityAxis].sort('size' === quantityAxis ? sizeSort : undefined) : []}
                  hasInventory={hasInventory}
                  buyInventory={buyInventory}
                  showInventory={showInventory}
                  template_color={template_color}
                  getInventory={getInventory}
                  getSkuFromOptions={getSkuFromOptions}
                  onChange={onChange}
                  handleKeyDown={handleKeyDown}
                  onUpdateQuantity={onUpdateQuantity}
                  onFocus={onFocus}
                  showMaximumQuantity={showMaximumQuantity}
                  winnowingAxesOption={winnowingAxesOption}
                />
              </div>
            </div>}
        </div>}
      </Medium>}

      {small && <SmallOnly>
        {is_shop_free == 1 && <FreeShopItemSkuSelector
          item={item}
          value={value}
          template_color={template_color}
          sizeaxes={sizeaxes}
          winnowingAxes={winnowingAxes}
          winnowingAxesOptions={winnowingAxesOptions}
          winnowingAxesOption={winnowingAxesOption}
          availableOptions={availableOptions}
          availableOptionsColor={availableOptionsColor}
          onChangeSizeAxisFreeShop={onChangeSizeAxisFreeShop}
          onChangeColorAxisFreeShop={onChangeColorAxisFreeShop}
          selectedFreeShopValue={selectedFreeShopValue}
        />}
        {is_shop_free != 1 && <div className="edit-product small-cart_item_sku_selector">
          {winnowingAxes.map(a =>
            <div key={a} className="editcolor">
              <label id="bld">
                <LabeledSelect
                  name={a + "-id-select"}
                  label={upperFirst(a)}
                  className="csku-select small-cart_item_sku_axis_option_select"
                  style={{ ...selectStyle, border: focus === a ? `1px solid ${template_color}` : 'inherit' }}
                  options={[{ key: '', value: `Select ${a}` }].concat(winnowingAxesOptions[a])}
                  value={value[a]}
                  onChange={option => onChangeFirstAxis(a, option ? option.value : '')}
                  onFocus={() => onFocus(a)}
                  isDisabled={1 === winnowingAxesOptions[a].length}
                />
              </label>
              {showMinimumQuantity && <span className="excludes">{`Minimum Quantity of ${item.minimum_quantity}`}</span>}
            </div>)}
          {winnowed && is_shop_free != 1 &&
            <div className="quantity small-cart_item_sku_axis_qty_container">
              <ItemAxisQuantitites
                skus={skus}
                focus={focus}
                value={value}
                quantityAxis={quantityAxis}
                quantities={quantities}
                filteredOptions={filteredOptions[quantityAxis] ? filteredOptions[quantityAxis] : []}
                hasInventory={hasInventory}
                buyInventory={buyInventory}
                showInventory={showInventory}
                template_color={template_color}
                getInventory={getInventory}
                getSkuFromOptions={getSkuFromOptions}
                onChange={onChange}
                handleKeyDown={handleKeyDown}
                onUpdateQuantity={onUpdateQuantity}
                onFocus={onFocus}
                showMaximumQuantity={showMaximumQuantity}
                winnowingAxesOption={winnowingAxesOption}
              />
            </div>}
        </div>}
      </SmallOnly>}
    </div>
  );
};

export const CartItemColorSkuSelector = ({
  selected_product_sku_id,
  focus,
  item,
  quantities,
  template_color,
  value,
  onFocus,
  onChangeFirstAxis,
  onChangeQuantity,
  onUpdateQuantity,
  buyInventory = false,
  is_shop_free,
  onChangeColorAxisFreeShop,
  onChangeSizeAxisFreeShop,
  availableOptions,
  availableOptionsColor,
  textAlign = '',
}) => {
  const {
    skus,
    options,
    axes
  } = getItemSkus(item, buyInventory);
  const {
    winnowingAxes,
    winnowingAxesOptions,
    winnowed,
    quantityAxis,
    sizeaxes,
    filteredOptions,
  } = getParsedData({
    value,
    skus,
    options,
    axes,
    is_shop_free,
    item,
    buyInventory,
    selected_product_sku_id,
  });

  const handleKeyDown = (axis, option) => e => {
    if ('Enter' === e.key) {
      onUpdateQuantity(axis, option);
    }
  };
  const getInventory = (product_sku_id) => getSkuInventory(item, buyInventory, product_sku_id);
  const onChange = (quantityAxis, option) => {
    return onChangeItemSkuQuantity(value, item, buyInventory, option, onChangeQuantity, quantityAxis, value);
  };
  const selectedFreeShopValue = (a) => Array.isArray(value[a]) ? value[a] : [value[a]];

  const option = (filteredOptions[quantityAxis] ? filteredOptions[quantityAxis].sort('size' === quantityAxis ? sizeSort : undefined) : []).find(op => op === value[quantityAxis]);
  const winnowingAxesOption = get(winnowingAxesOptions, [first(winnowingAxes)]) || [];

  return (
    is_shop_free == 1 ? <FreeShopItemSkuSelector
      item={item}
      value={value}
      template_color={template_color}
      sizeaxes={sizeaxes}
      winnowingAxes={winnowingAxes}
      winnowingAxesOptions={winnowingAxesOptions}
      winnowingAxesOption={winnowingAxesOption}
      availableOptions={availableOptions}
      availableOptionsColor={availableOptionsColor}
      onChangeSizeAxisFreeShop={onChangeSizeAxisFreeShop}
      onChangeColorAxisFreeShop={onChangeColorAxisFreeShop}
      selectedFreeShopValue={selectedFreeShopValue}
    /> : winnowed && <ItemAxisQuantititesInput
      option={option}
      skus={skus}
      focus={focus}
      value={value}
      quantityAxis={quantityAxis}
      quantities={quantities}
      buyInventory={buyInventory}
      template_color={template_color}
      getInventory={getInventory}
      getSkuFromOptions={getSkuFromOptions}
      onChange={onChange}
      handleKeyDown={handleKeyDown}
      onUpdateQuantity={onUpdateQuantity}
      onFocus={onFocus}
      winnowingAxesOption={winnowingAxesOption}
      textAlign={textAlign}
      placeholder={'Quantity'}
    />
  );
};

function ItemAxisQuantitites({
  skus,
  focus,
  value,
  quantityAxis,
  quantities,
  filteredOptions,
  hasInventory,
  buyInventory,
  showInventory,
  template_color,
  getInventory,
  getSkuFromOptions,
  onChange,
  handleKeyDown,
  onUpdateQuantity,
  onFocus,
  showMaximumQuantity,
}) {
  return (
    <Row style={{ paddingLeft: 0 }} className="item_axis_quantities_container">
      {filteredOptions.filter(option => !hasInventory || getInventory(getSkuFromOptions(skus, { ...value, [quantityAxis]: option })[0]) !== null).map((option, i) =>
        <Csku as={Col} key={`axis-${quantityAxis}-option-${option}`} className="sizes item_axis_quantity_container" xs={6} md={4}
          style={{
            xs: {
              paddingLeft: '0px !important',
              paddingRight: `${(i+1)%2 === 0 ? 0 : 24}px !important`,
              paddingBottom: '24px !important',
            },
            md: {
              paddingLeft: '0px !important',
              paddingRight: `${(i+1)%3 === 0 ? 0 : 24}px !important`,
              paddingBottom: '24px !important',
            },
          }}>
          <label style={{ position: 'relative' }}>
            <span className='item_axis_quantity_label'>{option}</span>
            <ItemAxisQuantititesInput
              option={option}
              skus={skus}
              focus={focus}
              value={value}
              quantityAxis={quantityAxis}
              quantities={quantities}
              buyInventory={buyInventory}
              template_color={template_color}
              getInventory={getInventory}
              getSkuFromOptions={getSkuFromOptions}
              onChange={onChange}
              handleKeyDown={handleKeyDown}
              onUpdateQuantity={onUpdateQuantity}
              onFocus={onFocus}
              placeholder={'Quantity'}
            />
            {showMaximumQuantity(
              quantities[getSkuFromOptions(skus, { ...value, [quantityAxis]: option })[0]] ?? '',
              getInventory(getSkuFromOptions(skus, { ...value, [quantityAxis]: option })[0])
            ) && <MaximumQuantity />}
            {hasInventory && showInventory && <AvailableInventory label="Inventory: " quantity={getInventory(getSkuFromOptions(skus, { ...value, [quantityAxis]: option })[0])} />}
          </label>
        </Csku>
      )}
    </Row>
  );
}

function ItemAxisQuantititesInput({
  option,
  skus,
  focus,
  value,
  quantityAxis,
  quantities,
  buyInventory,
  template_color,
  getInventory,
  getSkuFromOptions,
  onChange,
  handleKeyDown,
  onUpdateQuantity,
  onFocus,
  textAlign='',
  placeholder='',
}) {
  return (
    <Input
      type="text"
      value={quantities[getSkuFromOptions(skus, { ...value, [quantityAxis]: option })[0]] || ''}
      onChange={onChange(quantityAxis, option)}
      onKeyDown={handleKeyDown(quantityAxis, option)}
      onBlur={() => onUpdateQuantity(quantityAxis, option)}
      onFocus={() => onFocus(`${quantityAxis}-${option}`)}
      style={{
        marginBottom: 0,
        borderRadius: 5,
        ...(focus === `${quantityAxis}-${option}` ? { border: `1px solid ${template_color}` } : {}),
        textAlign,
      }}
      disabled={!buyInventory && getInventory(getSkuFromOptions(skus, { ...value, [quantityAxis]: option })[0]) === 0}
      placeholder={placeholder}
      className="item_axis_quantity_input"
      id={`item_axis_quantity_input-${quantityAxis}-${option}`}
    />
  );
}

export default CartItemSkuSelector;
