import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Csku, Input, LabeledInput, Row } from "@commonsku/styles";

import { TemplateButton } from "../../shop/helpers";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";
import { IItem } from "../../../interfaces/Item";
import { createAddComment } from "../../../actions";
import { formatAMPM } from "../../../utils";
import { H5, Span, Text } from "../../helpers/Typography";
import { getItemComments } from "../../../selectors/items";

type AddCommentsButtonProps = {
  item: IItem,
  disabled?: boolean,
  onClick?: (item: IItem) => void,
};

export default function AddCommentsButton(props: AddCommentsButtonProps) {
  const {
    item,
    onClick,
    disabled = false,
  } = props;
  const {
    templateColor: template_color,
  } = usePublicViewTemplateDataContext();

  const dispatch = useDispatch();
  const itemComments = useSelector(s => getItemComments(s, { item_id: item.item_id }));

  const [showErrors, setShowErrors] = useState(false);
  const [state, setState] = useState({
    commenterName: '',
    commenterComment: '',
    showNewComment: false,
  });

  const onAddComment = () => {
    if (!state.commenterName || !state.commenterComment) {
      setShowErrors(true);
      return;
    }
    setShowErrors(false);
    return dispatch(
      createAddComment(item.item_id, null, state.commenterName, null, state.commenterComment)
    );
  };

  const handleClick = () => {
    if (disabled) { return; }
    if (state.showNewComment) {
      onAddComment();
      setState(s => ({ ...s, commenterComment: '', showNewComment: false }));
    } else {
      setShowErrors(false);
      setState(s => ({ ...s, showNewComment: true }));
    }
  };

  const hoverStyle = disabled ? {
    background: 'var(--color-neutrals-50) !important',
    outline: 'var(--color-neutrals-50) !important',
    borderColor: 'var(--color-neutrals-50) !important',
    color: 'var(--color-neutrals-70) !important',
  } : {};

  return <div style={{ paddingRight: 8, paddingBottom: 16, }}>
    {itemComments?.length > 0 && <div style={{ paddingBottom: '24px' }}>
      <H5 style={{ color: 'var(--color-neutrals-90)' }}>Comments</H5>
      <div style={{ paddingTop: 8 }}>
        {itemComments.map(ic => (
          <React.Fragment key={ic.presentation_comment_id}>
            <Text style={{ marginBottom: '1px' }}>
              <Span bold>{ic.commenter_name}: </Span>
              <Span>{ic.comment}</Span>
            </Text>
            <Text color="var(--color-neutrals-70)">{formatAMPM(ic.date_created)}</Text>
          </React.Fragment>
        ))}
      </div>
    </div>}
    {state.showNewComment && <Row>
      <Col xs>
        <Csku
          as={LabeledInput}
          forceStyles
          required
          type="text"
          label="Your Name"
          placeholder="e.g. John Smith"
          sx={{
            borderRadius: 5,
            ':hover': { border: `1px solid ${template_color}` },
            ':focus': { border: `1px solid ${template_color}` },
          }}
          value={state.commenterName}
          onChange={e => {
            const value = e.target.value;
            setState(s => ({ ...s, commenterName: value }));
          }}
          error={showErrors && Boolean(state.commenterName)}
        />
      </Col>
      <Col xs>
        <Csku
          as={Input}
          forceStyles
          required
          type="text"
          placeholder="Comments in this item..."
          sx={{
            borderRadius: 5,
            ':hover': { border: `1px solid ${template_color}` },
            ':focus': { border: `1px solid ${template_color}` },
          }}
          value={state.commenterComment}
          onChange={e => {
            const value = e.target.value;
            setState(s => ({ ...s, commenterComment: value }));
          }}
          error={showErrors && Boolean(state.commenterComment)}
        />
      </Col>
    </Row>}
    <Csku
      as={TemplateButton}
      template_color={template_color}
      style={{
        width: '100%',
        maxWidth: '229px',
        ':hover': hoverStyle,
        ':focus': hoverStyle,
      }}
      disabled={disabled}
      onClick={handleClick}
      size='huge'
    >
      <span>{!state.showNewComment ? 'Leave a comment' : 'Post Comment'}</span>
    </Csku>
  </div>;
}
