import React from "react";
import { Col, colors, LabeledInput, Row, Text } from "@commonsku/styles";
import { validateEmail } from "../../../utils";

export default function EnterContact({
  field,
  contact,
  showErrors,
  onChange,
  onFocus,
}) {
  const isNameError = showErrors && !!!(contact.contact_name.trim());
  const isEmailError = showErrors && !validateEmail(contact.contact_email.trim());

  return (
    <Row>
      <Col xs style={{ paddingTop: 16 }}>
        <LabeledInput
          required
          key={field + 'cc2'}
          id={`${field}-contact_name`}
          error={isNameError}
          label={'Name'}
          type="text"
          placeholder="Enter Full Name"
          value={contact.contact_name}
          onChange={onChange('contact_name')}
          onFocus={onFocus(field + 'cc2')}
          style={{ marginBottom: 0, }}
        />
      </Col>
      {isNameError && <Col xs style={{ paddingTop: 8 }}>
        <Text style={{ color: colors.errors.main }}>This field is required</Text>
      </Col>}
      <Col xs style={{ paddingTop: 16 }}>
        <LabeledInput
          required
          key={field + 'cc4'}
          id={`${field}-contact_email`}
          error={isEmailError}
          label={'Email Address'}
          type="text"
          placeholder="Enter Email Address"
          value={contact.contact_email}
          onChange={onChange('contact_email')}
          onFocus={onFocus(field + 'cc4')}
          style={{ marginBottom: isEmailError ? 0 : 16, }}
        />
      </Col>
      {isEmailError && <Col xs style={{ paddingTop: 8, marginBottom: 16, }}>
        <Text style={{ color: colors.errors.main }}>This field is required</Text>
      </Col>}
    </Row>
  );
}
