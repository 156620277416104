import React from 'react';
import { connect } from 'react-redux';
import { colors, fontFamilies, Text, Typography } from '@commonsku/styles';

import { ItemWarning } from '../../ItemWarnings';
import ProductWarnings from '../../ProductWarning';
import Loading from '../../Loading';

import { formatNumber, getItemSelectedSizeOptionsByColorId, getShopItemMinQuantity, hasItemInventory } from '../../shop/helpers';
import CartItem from '../../shop/CartItem';
import { isCostVisible, ItemInfo as BaseItemInfo } from '../ItemInfo';
import AddItemButton from '../../shop/ShopGallery/AddItemButton';
import { getCustomCartItem } from '../../../selectors';
import { withRouter } from '../../helpers';
import AddCommentsButton from '../../public-view/ShopGallery/AddCommentsButton';
import { H1, H5 } from '../../helpers/Typography';

class ItemInfo extends BaseItemInfo {

  constructor(props) {
    super(props);

    this.state = {
      selectedColorId: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.item.item_id !== this.props.item.item_id) {
      this.setState({ selectedColorId: '' });
    }
  }

  getItemMinQuantity(item) {
    const { aggregate, buyInventory, force_minimum_qty } = this.props;
    return getShopItemMinQuantity({ force_minimum_qty, buy_inventory: buyInventory, aggregate }, item);
  }

  renderCartItem(item) {
    const {
      cart,
      aggregate,
      is_shop_free,
      buyInventory,
      show_inventory,
      currency_symbol,
      template_color,
      force_minimum_qty,
      client_id,
      tenant_id,
      isEditing = true,
      isInCart=true,
      onEditCartItem,
      onCancelEditCartItem,
      onDeleteCartItem,
      onSaveCartItem,
      onUpdateBreakdown,
    } = this.props;

    const visible_options = item.options.filter(o => 0 == o.hidden && 0 != o.total_units);
    if(!visible_options.length && aggregate == 0) {
      return null;
    }

    let cartItem = cart?.items?.find(it => it.item_id === item.item_id);
    if (!cartItem) {
      cartItem = this.props.cartItem;
    }

    const metadata = item.metadata;
    const shopId = this.props.shopId;

    return (
      <div style={{ paddingLeft: 1, paddingRight: 8 }}>
        <CartItem
          key={item.item_id}
          item={cartItem}
          is_shop_free={is_shop_free}
          force_minimum_qty={force_minimum_qty}
          aggregate={aggregate}
          client_id={client_id}
          tenant_id={tenant_id}
          isEditing={isEditing}
          onEditCartItem={onEditCartItem}
          onDeleteCartItem={onDeleteCartItem}
          onCancelEditCartItem={onCancelEditCartItem}
          onSaveCartItem={onSaveCartItem}
          onUpdateBreakdown={onUpdateBreakdown}
          template_color={template_color}
          currency_symbol={currency_symbol}
          buyInventory={buyInventory}
          showInventory={!!show_inventory}
          showOnlyOptionSelectors
          showArtworks
          isInCart={isInCart}
          onChangeColor={color_id => this.setState({ selectedColorId: color_id })}
          metadata={metadata}
          shop_id={shopId}
        />
      </div>
    );
  }

  renderFixedCosts() {
    const { item, buyInventory, currency_symbol, showNet } = this.props;
    const fixed_costs = (
      item.fixed_costs ? item.fixed_costs : item.item_costs
    ).filter(isCostVisible).filter(ic => ic.quantity);

    if (!fixed_costs || !fixed_costs.length || item.share_fixed_charges == 0) {
      return null;
    }
    if (!buyInventory && item.inventory_items.length > 0) {
      return null;
    }
    return (
      <section>
        <H5>Fixed Charges {showNet ? '(Net)' : ''}</H5>
        {fixed_costs.map((c, i) =>
          <Text as="p" key={c.item_cost_id + c.item_cost_category_id + c.item_cost_type_id}>
            <Text as="span" style={{ fontFamily: fontFamilies.regular, paddingRight: 5, }}>{c.item_cost_title}{c.item_location_title && <span> &mdash; {c.item_location_title}</span>}: </Text>
            <Text as="span" style={{ fontFamily: fontFamilies.regular }}>{currency_symbol}{formatNumber(c.unit_price, true)}</Text>
          </Text>
        )}
      </section>
    );
  }

  render() {
    const {
      item,
      loading,
      supplier_id,
      aggregate,
      buyInventory,
      hidePricing = false,
      showProductWarnings = false,
      showVendorInfo = true,
      className = '',
      style = {},
      is_shop_free = 0,
      itemNameFont = "",
      showAddToCartButton = true,
      showCartItem = true,
      showAddCommentsButton = false,
    } = this.props;

    if(loading) {
      return (
        <div className="small-12 columns text-center">
          <Loading style={{ margin: '50% 0', width: '85px' }}/>
        </div>
      );
    }

    const hasInventory = hasItemInventory(item, buyInventory);
    const minQuantity = this.getItemMinQuantity(this.props.cartItem);
    const shopItemQty = this.props.shopCartItem && this.props.shopCartItem.length ? this.props.shopCartItem.reduce((t, b) => t + parseFloat(b.quantity), 0) : 0;
    const hasMinQuantity = minQuantity !== null && minQuantity !== undefined && shopItemQty >= minQuantity;
    const itemMinQty = item.minimum_quantity || this.props.cartItem ? this.props.cartItem.minimum_quantity : null;
    const selectedSizeOptions = getItemSelectedSizeOptionsByColorId(
      { buy_inventory: buyInventory, is_shop_free },
      this.props.cartItem,
      this.state.selectedColorId
    );

    return (
      <div className={`small-12 columns gallery-item-info ${className}`} style={style}>
        <Typography.H2 style={!!itemNameFont ? {fontFamily: itemNameFont } : {}}>{item.item_name}</Typography.H2>
        {+is_shop_free === 0 && itemMinQty && itemMinQty > 0 && !hasInventory ? <Text style={{ color: 'var(--color-neutrals-80)', fontFamily: fontFamilies.regular }}>
          Minimum Quantity - {formatNumber(itemMinQty)}
        </Text> : null}
        {!hasMinQuantity && shopItemQty > 0 && <Text style={{ display: 'block', color: colors.errors.main }}>You have {shopItemQty} items - minimum quantity of {minQuantity} not met!</Text>}

        {!!item.price_label && <H5>{item.price_label}</H5>}
        {!hidePricing && aggregate == 1 && is_shop_free != 1 && this.renderRetailPrice()}
        {!hidePricing && (!aggregate || aggregate == '0') && is_shop_free != 1 && this.renderPriceGrid()}
        {showCartItem && this.renderCartItem(item)}
        {showAddToCartButton && <AddItemButton
          cart={this.props.cart}
          item={this.props.item}
          selectedSizeOptions={selectedSizeOptions}
          showItemCount={this.props.showItemCount}
          onClickCart={this.props.onClickCart}
          onAddItem={this.props.onAddItem}
          disabled={this.props.isPreviewing}
        />}
        {showAddCommentsButton && <AddCommentsButton
          item={this.props.item}
          disabled={this.props.isPreviewing}
        />}

        {!hidePricing && is_shop_free != 1 && this.renderUpCharges()}
        {!hidePricing && (!aggregate || aggregate == '0') && is_shop_free != 1 && this.renderFixedCosts()}

        {showVendorInfo && item.item_sku && <p style={{ marginBottom: '0.25rem' }}>SKU: {item.item_sku}</p>}
        {showVendorInfo && item.supplier_id !== supplier_id && item.division_name && <p>
          <a
            target="_blank"
            rel="noreferrer"
            href={`/vendor.php?id=${item.supplier_id}&division_id=${item.division_id}`}
          >{item.division_name}</a>
        </p>}
        {!showAddCommentsButton && <p dangerouslySetInnerHTML={{ __html: item.item_description}} />}
        {showProductWarnings && !!item.product_warnings.length &&
          <div style={{ display: 'block' }}>
            <ProductWarnings warnings={item.product_warnings} />
            <br />
          </div>}
        {item.item_warnings && !!item.item_warnings.length &&
          <div style={{ display: 'block' }}>
            {item.item_warnings.map(iw =>
              <ItemWarning key={iw.item_warning_id} locked={true} item_warning={iw} />
            )}
            <br />
          </div>}
        {this.renderInventory()}
        {this.renderColors()}
        {this.renderSizes()}
        {this.renderSkus()}
        {showAddCommentsButton && <p dangerouslySetInnerHTML={{ __html: item.item_description}} />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    cartItem: getCustomCartItem(state, ownProps),
    shopId: ownProps.params.shop_id
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ItemInfo));
