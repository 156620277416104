import { fontFamilies } from '@commonsku/styles';
import React from 'react';
import { hexToHSL, toneColor } from '../../../utils';

export const getColMdSize = (value) => value.length > 13 ? 5 : value.length > 9 ? 4 : 3;

// get last row columns sizes
export const calcLastColsSize = (len, by=4) => {
  const rowsSplit = parseFloat(len / by).toFixed(2).split('.');
  const colSize = parseInt(parseFloat('.' + rowsSplit[1]) * by, 10);
  return [parseInt(rowsSplit[0]), colSize];
};

export const OptionLabel = ({ children, style={}, ...props }) => (
  <p
    {...props}
    style={{ marginBottom: 0, fontWeight: 'bold', fontFamily: fontFamilies.demibold, ...style }}
  >{children}</p>
);

export default function FreeItemOption({ value, label, disabled, isCurrentValue, isSelected, template_color, onChangeFreeShopAxis, style={}, ...props }) {
  const hslColor = hexToHSL(template_color);
  return (
    <div
      {...props}
      title={label}
      onClick={e => {
        if (disabled) { return; }
        onChangeFreeShopAxis({ target: { value: value }});
      }}
      style={{
        fontSize: 16,
        padding: 16,
        textAlign: 'center',
        display: 'inline-block',
        cursor: 'pointer',
        borderRadius: 2000,
        width: '100%',
        border: `3px solid ${!(isSelected || isCurrentValue) ? '#fff' : template_color}`,
        color: template_color,
        background: !(isCurrentValue || isSelected) ? `hsl(${hslColor[0]}, 100%, 95%)` : '',
        fontFamily: fontFamilies.regular,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        ...(disabled ? {
          border: '3px solid var(--color-neutrals-40)',
          background: 'var(--color-neutrals-40)',
          color: 'var(--color-neutrals-80)',
          cursor: 'not-allowed',
        } : {}),
        ...style,
      }}
    >{label}</div>
  );
}
