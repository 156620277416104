import { Csku } from "@commonsku/styles";
import React from "react";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";

const bannerImageStyle = (
  categoryImages,
  bannerDimPercentage,
  bannerOnHover
) => ({
  marginBottom: 24,
  width: "100%",
  backgroundImage: `url('${categoryImages[0]}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  filter: `brightness(${1 - bannerDimPercentage})`,
  transition: "filter 0.5s ease-in-out",
  ":hover": {
    ...(bannerOnHover && categoryImages.length > 1
      ? {
          background: `url('${categoryImages[1]}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }
      : {}),
  },
});

const bannerResponsiveHeight = {
  xs: {
    height: 160,
  },
  sm: {
    height: 200,
  },
  md: {
    height: 296,
  },
};

const bannerTextStyle = {
  lineHeight: "48px",
  color: "#fff",
  fontFamily: "Bebas Neue",
  position: "absolute",
  left: 0,
  top: 0,
  paddingLeft: 24,
};

const getPaddingTop = (height, lineHeight) => `${(height - lineHeight) / 2}px`;

const bannerTextResponseiveStyle = {
  xs: {
    ...bannerResponsiveHeight.xs,
    fontSize: 40,
    lineHeight: "64px",
    paddingTop: getPaddingTop(160, 64),
  },
  sm: {
    ...bannerResponsiveHeight.sm,
    fontSize: 60,
    paddingTop: getPaddingTop(200, 48),
  },
  md: {
    ...bannerResponsiveHeight.md,
    paddingTop: getPaddingTop(296, 48),
  },
};

export const PosterThemeBanner = ({ category }) => {
  const bannerDimPercentage = category?.bannerDimPercentage;
  const { templateBannerOnHover } = usePublicViewTemplateDataContext();

  return (
    <div style={{ position: "relative" }}>
      <Csku
        forceStyles
        style={bannerImageStyle(
          category.categoryImages,
          bannerDimPercentage,
          templateBannerOnHover
        )}
        sx={bannerResponsiveHeight}
      />
      <Csku
        as="div"
        forceStyles
        style={bannerTextStyle}
        sx={bannerTextResponseiveStyle}
      >
        {category.item_name}
      </Csku>
    </div>
  );
};
