import { find, get } from "lodash";
import React from "react";
import { Col, EditIcon, Row, ShowPopup, Text } from "@commonsku/styles";
import { DEFAULT_COLOR, TemplateButton, TemplateTextIconButton } from "../helpers";
import SelectAddressPopup from "./SelectAddressPopup";
import { getAddressCityAndState, getAddressPostalCodeAndCountry } from "../../address/utils";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";
import { usePublicViewEntityContext } from "../../../context/PublicViewEntityProvider";

export default function SelectAddress({
  field,
  addressId,
  address,
  addresses,
  hide_select,
  hasErrors,
  disabled = false,

  onSelectAddress,
  onAddAddress,
}) {
  const { templateColor: template_color } = usePublicViewTemplateDataContext();
  const { entity } = usePublicViewEntityContext();

  const selectedId = addressId && addressId !== 'ADD' ? addressId : '';
  let selectedAddress = (selectedId && find(addresses, v => v.address_id === selectedId)) || null;
  if (!selectedAddress && address && address['address_line_1']) {
    selectedAddress = address;
  }
  const popupProps = {
    hideSelect: +hide_select === 1,
    popup: SelectAddressPopup,
    color: template_color,
    addresses: addresses,
    selectedId: addressId,
    customAddress: address,
    canAddCustomAddress: 'TYPED' === entity[`${field}_type`],
    onSelectAddress: onSelectAddress,
    onAddAddress: onAddAddress,
  };

  return (
    <Row key={`select-${field}-address`}>
      <Col xs md={selectedAddress ? 8 : 12}>
        {selectedAddress ? <>
          <Text as="p" style={{marginBottom: 0}}>{get(selectedAddress, ['address_company']) || ''}</Text>
          <Text Text as="p" style={{marginBottom: 0}}>{get(selectedAddress, ['address_line_1']) || ''}</Text>
          {selectedAddress?.address_line_2 && <Text Text as="p" style={{marginBottom: 0}}>{selectedAddress.address_line_2}</Text>}
          {selectedAddress?.address_line_3 && <Text Text as="p" style={{marginBottom: 0}}>{selectedAddress.address_line_3}</Text>}
          {selectedAddress?.address_line_4 && <Text Text as="p" style={{marginBottom: 0}}>{selectedAddress.address_line_4}</Text>}
          <Text Text as="p" style={{marginBottom: 0}}>{getAddressCityAndState(selectedAddress) || ''}</Text>
          <Text Text as="p" style={{marginBottom: 0}}>{getAddressPostalCodeAndCountry(selectedAddress) || ''}</Text>
        </> : <ShowPopup
          {...popupProps}
          render={({ onClick }) => (
            <TemplateButton
              id={`add_${field}}`}
              size='large'
              disabled={disabled}
              template_color={template_color}
              isSecondary
              style={{ width: '100%' }}
              onClick={(e) => !disabled ? onClick(e) : null}
            >+ Add Address</TemplateButton>
          )}
        />}
      </Col>
      {selectedAddress && <Col xs md={4} mdStyle={{ textAlign: 'right' }}>
        <ShowPopup
          {...popupProps}
          render={({ onClick }) => (
            <TemplateTextIconButton
              id={`edit_${field}}`}
              size='medium'
              Icon={EditIcon}
              isSecondary
              template_color={template_color}
              style={{ width: '100%' }}
              onClick={(e) => !disabled ? onClick(e) : null}
            >Edit Address</TemplateTextIconButton>
          )}
        />
      </Col>}
      {hasErrors && <Col xs padded style={{ textAlign: 'left', color: 'var(--color-errors-main)', fontWeight: 'bold' }}><span key={field + 'a1e'}>Please choose an address</span></Col>}
    </Row>
  );
}
