import React, { Component } from 'react';
import { find, first, get, groupBy, isEqual, reduce } from 'lodash';
import { ChevronIcon, Col, colors, Csku, fontFamilies, Grid, GridItem, Row, Text } from '@commonsku/styles';

import { getImageSrc, isNumeric, sizeSort, } from '../../utils';
import {
  AvailableInventory,
  formatNumber,
  getItemInventory,
  getItemSkus,
  getOptionsFromSkus,
  getQuantityAxis,
  getShopItemMinQuantity,
  getSkuFromOptions,
  getSkuInventory,
  hasItemInventory,
  TemplateButton,
  TemplateTextIconButton,
} from './helpers';

import Img from '../Img';
import CartItemArtwork from './CartItemArtwork';
import CartItemOptionSelector, { CartItemColorOptionSelector, } from './CartItemOptionSelector';
import CartItemSkuSelector, { CartItemColorSkuSelector, } from './CartItemSkuSelector';
import ProductQuestion from './ShopGallery/ProductQuestions/ProductQuestion';
import CartProductQuestion from './CartProductQuestion';
import { removeItemFromProductQuestionsLocalStorage } from '../../redux/hooks/product_questions';

const fixdchargeStyle = { borderTop: '1px solid var(--color-neutrals-60)' };

class CartItem extends Component {

  constructor(props) {
    super(props);

    this.root = React.createRef();

    let firstAxisOptions = [];
    let firstAxis = '';
    let secondAxesOptions = [];
    let secondAxes = '';
    const skus = props.item.skus;
    if (skus && skus.length > 0) {
      const options = getOptionsFromSkus(skus);
      const axes = Object.keys(options).sort();
      secondAxes = Object.keys(options).length == 2 ? axes[1] : '';
      secondAxesOptions = secondAxes != '' ? options[axes[1]].map(o => ({ key: o, value: o })) : [];
      firstAxis = axes.shift();
      firstAxisOptions = (options[firstAxis] || []).map(o => ({ key: o, value: o }));
    }

    const inventory_colors = props.item.inventory_items.map(ii => ii.color_id);
    const colors = props.item.colors.filter(
      c => props.buyInventory || props.item.inventory_items.length === 0 || inventory_colors.includes(c.color_id)
    );
    const inventory_sizes = props.item.inventory_items.map(ii => ii.size_id);
    const sizes = (!props.item.sizes.length ? [{ size_id: 'TBD', size_name: props.is_shop_free == 1 ? 'One Size' : 'Quantity' }] : props.item.sizes.sort((a, b) => sizeSort(a.size_name, b.size_name))).filter(
      s => props.buyInventory || props.item.inventory_items.length === 0 || inventory_sizes.includes(s.size_id)
    );
    this.state = {
      color_id: !colors.length ? 'TBD' : (1 === colors.length ? colors[0].color_id : ''),
      size_ids: !sizes.length ? 'TBD' : (1 === sizes.length ? sizes[0].size_id : ''),
      quantities: this.getQuantitiesFromProps(props),
      focus: null,
      value: firstAxisOptions.length == 1 && secondAxesOptions.length == 1 ? { [firstAxis]: firstAxisOptions[0].key, [secondAxes]: secondAxesOptions[0].key } :
        firstAxisOptions.length == 1 ? { [firstAxis]: firstAxisOptions[0].key } : secondAxesOptions.length == 1 ? { [secondAxes]: secondAxesOptions[0].key } : {},
      availableOptions: [],
      availableOptionsColor: [],
      cartProductQuestionError: null
    };

    if (colors.length <= 1 && sizes.length <= 1 && props.is_shop_free == 1 && 'ps-products' != props.item.copied_from) {
      if (!this.state.quantities[this.state.color_id]?.[this.state.size_ids]) {
        this.props.onUpdateBreakdown(props.item.item_id, this.state.color_id, this.state.size_ids, null, 1);
      }
    }
    if ('ps-products' == props.item.copied_from && firstAxisOptions.length <= 1 && secondAxesOptions.length <= 1) {
      const sku_id = getSkuFromOptions(props.item.skus, this.state.value)[0];
      if (!this.state.quantities[sku_id]) {
        this.props.onUpdateBreakdown(props.item.item_id, null, null, sku_id, 1);
      }
    }

    [
      'handleEditItem',
      'handleDeleteItem',
      'handleCancelEditItem',
      'onFocus',
      'handleCartProductQuestionError',

      'onChangeFreeShopColor',
      'onChangeFreeShopSize',
      'onChangeSize',
      'onUpdateSize',
      'onChangeFirstAxis',
      'onChangeSizeAxisFreeShop',
      'onChangeColorAxisFreeShop',
      'onChangeQuantity',
      'onUpdateQuantity',
      'onChangeColor',
    ].forEach(k => {
      this[k] = this[k].bind(this);
    });
  }

  componentDidUpdate() {
    if (this.props.isInCart) {
      const keys = Object.keys(this.state.quantities);
      if (keys.length === 0) {
        this.handleDeleteItem();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newQuantities = this.getQuantitiesFromProps(nextProps);
    const oldQuantities = this.getQuantitiesFromProps(this.props);
    if (!isEqual(oldQuantities, newQuantities)) {
      this.setState({ quantities: newQuantities });
    }
  }

  onFocus(focus) {
    return e => {
      this.setState({ focus });
    };
  }

  getQuantitiesFromProps(props) {
    if ('ps-products' === props.item.copied_from) {
      return props.item.breakdowns.reduce((o, b) => ({
        ...o,
        [b.product_sku_id]: b.quantity
      }), {});
    } else {
      return props.item.breakdowns.reduce((o, b) => {
        o[b.color_id] = { ...o[b.color_id], [b.size_id]: b.quantity };
        return o;
      }, {});
    }
  }

  handleEditItem(e) {
    e.preventDefault();
    this.setState({ focus: null }, () => this.props.onEditCartItem(this.props.item.item_id));
  }

  handleDeleteItem(e) {
    e && e.preventDefault();
    this.setState({ focus: null }, () => {
      this.props.onDeleteCartItem(this.props.item.item_id);
    });
    removeItemFromProductQuestionsLocalStorage(this.props.shop_id, this.props.item.item_id);
  }

  handleCancelEditItem(e) {
    e.preventDefault();
    this.setState({ quantities: this.getQuantitiesFromProps(this.props), focus: null }, this.props.onCancelEditCartItem);
  }

  handleCartProductQuestionError(metadata_id, error) {
    this.setState({cartProductQuestionError: {metadata_id, error}});
  }

  onChangeFreeShopColor(e) {
    const { item } = this.props;
    const { size_ids, } = this.state;

    const color_id = e.target.value;
    this.props.onChangeColor && this.props.onChangeColor(color_id);
    this.setState({
      color_id: color_id
    }, () => {
      const sizeId = size_ids ? size_ids : item.breakdowns.length > 0 ? item.breakdowns[0].size_id : '';
      if (sizeId != '') {
        this.props.onUpdateBreakdown(item.item_id, color_id, sizeId, null, 1);
      }
    });
  }

  onChangeFreeShopSize(e) {
    const { item } = this.props;
    const { color_id, buyInventory, } = this.state;

    const size_id = e.target.value;
    const colorId = color_id ? color_id : item.breakdowns.length > 0 ? item.breakdowns[0].color_id : '';
    this.setState({
      size_ids: size_id,
      quantities: {
        [colorId]: {
          [size_id]: 1
        }
      }
    }, () => {
      const hasInventory = hasItemInventory(item, buyInventory);
      const inventory = getItemInventory(item, size_id, color_id, buyInventory);
      if ((!hasInventory || inventory === null || buyInventory || inventory >= 1) && colorId != '') {
        this.props.onUpdateBreakdown(item.item_id, colorId, size_id, null, 1);
      }
    });
  }

  onChangeSize(size_id) {
    const { item, buyInventory } = this.props;
    const { color_id, quantities, } = this.state;

    return (value) => {
      this.setState({
        quantities: {
          ...quantities,
          [color_id]: {
            ...quantities[color_id],
            [size_id]: value
          }
        }
      }, () => {
        const hasInventory = hasItemInventory(item, buyInventory);
        const inventory = getItemInventory(item, size_id, color_id, buyInventory);
        if (isNumeric(value) && parseFloat(value) === parseInt(value, 10) && (!hasInventory || inventory === null || buyInventory || value <= inventory)) {
          this.props.onUpdateBreakdown(item.item_id, color_id, size_id, null, parseFloat(value));
        }
      });
    };
  }

  onUpdateSize(size_id) {
    const { item, buyInventory } = this.props;
    const { color_id, quantities, } = this.state;

    const hasInventory = hasItemInventory(item, buyInventory);
    const quantity = ((quantities || {})[color_id] || {})[size_id] || 0;
    const inventory = getItemInventory(item, size_id, color_id, buyInventory);
    if (!isNumeric(quantity) || parseFloat(quantity) !== parseInt(quantity, 10) || (hasInventory && inventory !== null && !buyInventory && quantity > inventory)) {
      this.setState({ quantities: this.getQuantitiesFromProps(this.props) });
    } else {
      this.props.onUpdateBreakdown(item.item_id, color_id, size_id, null, quantity);
    }
  }

  onChangeFirstAxis(axis, option) {
    const { item } = this.props;

    this.props.onChangeColor && this.props.onChangeColor(option);

    const skus = item.skus;
    const options = getOptionsFromSkus(skus);
    this.setState(state => ({
      value: {
        ...state.value,
        [axis]: option
      }
    }));
  }

  onChangeSizeAxisFreeShop(axis, e) {
    const { item } = this.props;
    const { value, } = this.state;

    const val = e.target.value;

    const skus = item.skus;
    const options = getOptionsFromSkus(
      skus.filter(
        sku => !!sku.options.filter(o => [axis].reduce((t, a) => t || (o.option_axis === a && o.option_name === val), false)).length
      ));
    const excludeproperty = Object.keys(options).includes('size') ? 'size' : Object.keys(options).includes('dimension') ? 'dimension' : 'sku';
    const colorOption = Object.keys(options).filter(a => a != excludeproperty);
    const availableColors = options[colorOption];
    const isDisabledColorSelected = availableColors.length > 0 && !availableColors.includes(this.state.value[colorOption]);
    if (isDisabledColorSelected && item.breakdowns.length > 0) {
      this.props.onUpdateBreakdown(item.item_id, null, null, item.breakdowns[0].product_sku_id, 0);
    }
    if ((value.hasOwnProperty(colorOption) && !isDisabledColorSelected) || Object.keys(options).length == 1) {
      const sku_id = getSkuFromOptions(skus, { ...value, [axis]: val })[0];
      this.props.onUpdateBreakdown(item.item_id, null, null, sku_id, 1);
    }
    this.setState(state => ({
      value: isDisabledColorSelected ? { [axis]: val } : {
        ...state.value,
        [axis]: val
      },
      availableOptionsColor: availableColors,
      availableOptions: []
    }));
  }

  onChangeColorAxisFreeShop(axis, e) {
    const { item } = this.props;
    const { value, } = this.state;

    const val = e.target.value;

    this.props.onChangeColor && this.props.onChangeColor(val);

    const skus = item.skus;
    const options = getOptionsFromSkus(
      skus.filter(
        sku => !!sku.options.filter(o => [axis].reduce((t, a) => t || (o.option_axis === a && o.option_name === val), false)).length
      ));
    const size_option = Object.keys(options).includes('size') ? 'size' : Object.keys(options).includes('dimension') ? 'dimension' : 'sku';
    const availableSizes = options[size_option];
    const isDisabledSelected = availableSizes && availableSizes.length > 0 && !availableSizes.includes(value[size_option]);

    if (isDisabledSelected && item.breakdowns.length > 0) {
      this.props.onUpdateBreakdown(item.item_id, null, null, item.breakdowns[0].product_sku_id, 0);
    }
    if ((value.hasOwnProperty(size_option) && !isDisabledSelected) || Object.keys(options).length == 1) {
      const sku_id = getSkuFromOptions(skus, { ...value, [axis]: val })[0];
      this.props.onUpdateBreakdown(item.item_id, null, null, sku_id, 1);
    }
    this.setState(state => ({
      value: isDisabledSelected ? { [axis]: val } :
        {
          ...state.value,
          [axis]: val
        },
      availableOptions: availableSizes ? availableSizes : [],
      availableOptionsColor: [],
    }));
  }

  onChangeQuantity(axis, option, quantity, optionValue = {}) {
    const { item, buyInventory, } = this.props;
    const { value, } = this.state;

    const inventory_skus = item.inventory_items.map(ii => ii.product_sku_id);
    const skus = item.skus.filter(
      s => buyInventory || item.inventory_items.length === 0 || inventory_skus.includes(s.product_sku_id)
    );
    const sku = getSkuFromOptions(skus, { ...value, ...optionValue, [axis]: option });
    if (1 === sku.length) {
      this.setState(state => ({
        quantities: {
          ...state.quantities,
          [sku[0]]: !isNaN(quantity) ? parseInt(quantity, 10) : ''
        }
      }), () => {
        const hasInventory = hasItemInventory(item, buyInventory);
        const inventory = getSkuInventory(item, buyInventory, sku[0]);
        if (isNumeric(quantity) && parseFloat(quantity) === parseInt(quantity, 10) && (!hasInventory || inventory === null || buyInventory || quantity <= inventory)) {
          this.props.onUpdateBreakdown(item.item_id, null, null, sku[0], quantity);
        }
      });
    }
  }

  onUpdateQuantity(axis, option) {
    const { item, buyInventory, } = this.props;
    const { value, quantities } = this.state;

    const sku_id = getSkuFromOptions(item.skus, { ...value, [axis]: option })[0];
    const quantity = quantities[sku_id] || 0;
    const inventory = getSkuInventory(item, buyInventory, sku_id);
    const hasInventory = hasItemInventory(item, buyInventory);
    if (!isNumeric(quantity) || parseFloat(quantity) !== parseInt(quantity, 10) || (hasInventory && inventory !== null && !buyInventory && quantity > inventory)) {
      this.setState({ quantities: this.getQuantitiesFromProps(this.props) });
    } else {
      this.props.onUpdateBreakdown(item.item_id, null, null, sku_id, quantity);
    }
  }

  onChangeColor(color_id) {
    this.props.onChangeColor && this.props.onChangeColor(color_id);
    this.setState({ color_id });
  };

  getMinQuantity() {
    const { item, force_minimum_qty, buyInventory, aggregate } = this.props;
    return getShopItemMinQuantity({ force_minimum_qty, buy_inventory: buyInventory, aggregate }, item);
  }

  renderOptionSelector(small = false) {
    const { item, isEditing, aggregate, is_shop_free, force_minimum_qty, template_color, buyInventory, showInventory } = this.props;
    const { quantities, color_id, focus, value, size_ids, availableOptions, availableOptionsColor } = this.state;

    return isEditing &&
      ('ps-products' === item.copied_from ?
        <CartItemSkuSelector
          small={small}
          color_id={color_id}
          focus={focus}
          item={item}
          quantities={quantities}
          template_color={template_color}
          value={value}
          onFocus={this.onFocus}
          onChangeFirstAxis={this.onChangeFirstAxis}
          onChangeQuantity={this.onChangeQuantity}
          onUpdateQuantity={this.onUpdateQuantity}
          aggregate={aggregate == 1}
          force_minimum_qty={force_minimum_qty == 1}
          buyInventory={buyInventory}
          showInventory={showInventory}
          is_shop_free={is_shop_free}
          onChangeColorAxisFreeShop={this.onChangeColorAxisFreeShop}
          onChangeSizeAxisFreeShop={this.onChangeSizeAxisFreeShop}
          availableOptions={availableOptions}
          availableOptionsColor={availableOptionsColor}
        /> :
        <CartItemOptionSelector
          small={small}
          color_id={color_id}
          size_ids={size_ids}
          focus={focus}
          item={item}
          quantities={quantities}
          template_color={template_color}
          onFocus={this.onFocus}
          onChangeColor={this.onChangeColor}
          onChangeSize={this.onChangeSize}
          onUpdateSize={this.onUpdateSize}
          onChangeFreeShopColor={this.onChangeFreeShopColor}
          onChangeFreeShopSize={this.onChangeFreeShopSize}
          aggregate={aggregate == 1}
          force_minimum_qty={force_minimum_qty == 1}
          buyInventory={buyInventory}
          showInventory={showInventory}
          is_shop_free={is_shop_free}
        />);
  }

  renderItemBreakdownsTable() {
    const { item, isEditing, is_shop_free, currency_symbol, buyInventory, productQuestions, shop_id } = this.props;

    const { quantities } = this.state;
    const minimumQuantity = this.getMinQuantity();
    const qtyLessThanMin = !!item.quantity && item.quantity < minimumQuantity;
    const gridProps = { gap: 7, columns: 5, };
    const headerStyle = { color: 'var(--color-neutrals-70)', fontFamily: fontFamilies.regular, fontSize: 14 };
    const colStyle = { color: 'var(--color-neutrals-90)', fontFamily: fontFamilies.regular, fontSize: 14, fontWeight: 'normal' };

    const isPS = 'ps-products' === item.copied_from;
    const itemSkus = isPS ? getItemSkus(item, buyInventory) : [];
    const skus = itemSkus ? itemSkus.skus : [];
    const axes = itemSkus ? itemSkus.axes : [];
    const quantityAxis = isPS ? getQuantityAxis(axes) : null;
    const breakdownsByColor = groupBy(item.breakdowns, v => isPS ? get(
      find(
        get(find(skus, s => s.product_sku_id === v.product_sku_id), ['options']) || [],
        op => op.option_axis === 'color'
      ),
      ['option_name']
    ) : v.color_id);
    const hasInventory = hasItemInventory(item, buyInventory);
    const showInventory = this.props.showInventory;
    let getInventory = null;
    let inventoryQuantity = '';
    const item_id = item.item_id;

    return (
      is_shop_free != 1 && <div>
        <div style={{ paddingTop: 8, paddingBottom: 8, }}>
          {productQuestions.data && productQuestions.data[item_id] && Object.keys(productQuestions.data[item_id]).length > 0 && Object.entries(productQuestions.data[item_id]).map(([key,m]) => {
            const hasError = this.state.cartProductQuestionError && this.state.cartProductQuestionError.metadata_id === key;
            return (
            <div key={key} style={{display: 'flex', alignItems:'center', marginBottom: 8}}>
              <Text as="p" style={{color: 'var(--color-neutrals-90)',fontFamily: fontFamilies.bold,fontSize: 14,marginBottom: 0,}}>{m.label}:&nbsp;</Text>
              {!isEditing ? <Text as="p" style={{color: 'var(--color-neutrals-90)',fontFamily: fontFamilies.bold,fontSize: 14,marginBottom: 0,}}>{m.value}</Text>
                : <CartProductQuestion
                  metadata_id={key}
                  item_id={item_id}
                  m={m}
                  shop_id={shop_id}
                  setError={this.handleCartProductQuestionError}/>
                }
                {hasError && <Text as="p" key={"error-" + m.metadata_id} style={{ color: colors.errors.main, marginBottom: 0, marginLeft: 4 }}>{this.state.cartProductQuestionError.error}</Text>}
            </div>
            );
          })}
          {Object.keys(breakdownsByColor).map(k => {
            const breakdowns = breakdownsByColor[k];
            const firstBreakdown = first(breakdowns);
            let colorName = get(firstBreakdown, ['color_name']);
            if (isPS) {
              colorName = get(
                find(
                  get(find(skus, s => s.product_sku_id === firstBreakdown.product_sku_id), ['options']) || [],
                  op => op.option_axis === 'color'
                ),
                ['option_name']
              );
              getInventory = (product_sku_id) => getSkuInventory(item, buyInventory, product_sku_id);
            } else {
              getInventory = (size_id, color_id) => getItemInventory(item, size_id, color_id, buyInventory);
            }
            const breakdownTotalQty = breakdowns.reduce((acc, b) => acc + parseInt(b.quantity), 0);
            const breakdownTotalSubtotal = breakdowns.reduce((acc, b) => acc + parseFloat(b.subtotal), 0);

            return (
              <div key={'color-' + k} text-id={"cart_item_table_container-" + k} style={{ paddingBottom: 8 }}>
                {colorName && <Text as="p" style={{
                  color: 'var(--color-neutrals-90)',
                  fontFamily: fontFamilies.bold,
                  fontSize: 14,
                  marginBottom: 8,
                }}>Color: {colorName}</Text>}
                <Grid {...gridProps} text-id={"cart_item_table_header-" + k} style={{ borderBottom: '1px solid var(--color-neutrals-60)', paddingBottom: 8, marginBottom: 8 }}>
                  <GridItem style={headerStyle}>Description</GridItem>
                  <GridItem style={headerStyle}>&nbsp;</GridItem>
                  <GridItem className="right" style={headerStyle}>Quantity</GridItem>
                  <GridItem className="right" style={headerStyle}>Price</GridItem>
                  <GridItem className="right" style={headerStyle}>Total</GridItem>
                </Grid>
                {breakdowns.map(b => {
                  let value = reduce(
                    get(find(skus, sk => sk.product_sku_id === b.product_sku_id), ['options']),
                    (acc, op) => ({ ...acc, [op.option_axis]: op.option_name }),
                    {}
                  );
                  if (Object.keys(value).length === 0) {
                    value = this.state.value;
                  }

                  let description = b.sku_description;
                  if (isPS && get(value, [quantityAxis])) {
                    description = get(value, [quantityAxis]);
                  } else if (b.size_name) {
                    description = b.size_name;
                  }

                  if (hasInventory && showInventory) {
                    if (isPS) {
                      inventoryQuantity = getInventory(getSkuFromOptions(skus, { ...value })[0]);
                    } else {
                      inventoryQuantity = getInventory(b.size_id, b.color_id);
                    }
                  }

                  return (
                    <Grid
                      {...gridProps}
                      key={`${b.item_id}${b.color_id}${b.size_id}${b.product_sku_id}`}
                      data-testid={`cart_item_table_row-` + b.product_sku_id}
                      onClick={() => this.setState(isPS ? { value } : { color_id: b.color_id })}
                      style={{
                        paddingBottom: 8,
                      }}
                    >
                      <GridItem id="bld" colSpan={2} style={colStyle}>
                        <span style={{ ...colStyle, wordBreak: 'break-word' }}>
                          {description}
                        </span>
                        {hasInventory && showInventory && <AvailableInventory
                          block
                          wrapped
                          label="Inventory: "
                          quantity={inventoryQuantity}
                        />}
                      </GridItem>
                      <GridItem className="right" style={colStyle}>
                        {isEditing ? <>
                          {isPS
                            ? <CartItemColorSkuSelector
                                selected_product_sku_id={b.product_sku_id}
                                color_id={b.color_id}
                                focus={this.state.focus}
                                item={item}
                                quantities={quantities}
                                template_color={this.props.template_color}
                                value={value}
                                onFocus={() => this.setState({ value: value })}
                                onChangeFirstAxis={this.onChangeFirstAxis}
                                onChangeQuantity={this.onChangeQuantity}
                                onUpdateQuantity={this.onUpdateQuantity}
                                buyInventory={buyInventory}
                                is_shop_free={is_shop_free}
                                onChangeColorAxisFreeShop={this.onChangeColorAxisFreeShop}
                                onChangeSizeAxisFreeShop={this.onChangeSizeAxisFreeShop}
                                availableOptions={this.state.availableOptions}
                                availableOptionsColor={this.state.availableOptionsColor}
                                textAlign="right"
                              />
                            : <CartItemColorOptionSelector
                                select_size_id={b.size_id}
                                color_id={b.color_id}
                                size_ids={this.state.size_ids}
                                focus={this.state.focus}
                                item={item}
                                quantities={quantities}
                                template_color={this.props.template_color}
                                onFocus={() => this.setState({ color_id: b.color_id })}
                                onChangeColor={this.onChangeColor}
                                onChangeSize={this.onChangeSize}
                                onUpdateSize={this.onUpdateSize}
                                onChangeFreeShopColor={this.onChangeFreeShopColor}
                                onChangeFreeShopSize={this.onChangeFreeShopSize}
                                buyInventory={buyInventory}
                                is_shop_free={is_shop_free}
                                textAlign="right"
                              />}
                        </> : formatNumber(b.quantity)}
                      </GridItem>
                      <GridItem className="right" style={colStyle}>{currency_symbol}{formatNumber(b.unit_price, true)}</GridItem>
                      <GridItem className="right" style={colStyle}>{currency_symbol}{formatNumber(b.subtotal, true)}</GridItem>
                    </Grid>
                  );
                })}
                <Grid {...gridProps} data-testid={"cart_item_fixed_table_footer-" + k} style={{
                  ...fixdchargeStyle,
                  paddingTop: 10,
                  paddingBottom: 14
                }}>
                  <GridItem colSpan={2}>Total</GridItem>
                  <GridItem className={'right'} style={{ color: '#2a383f' }}>
                    {formatNumber(breakdownTotalQty)}
                  </GridItem>
                  <GridItem
                    className="right"
                    colSpan={2}
                  >{currency_symbol}{formatNumber(breakdownTotalSubtotal, true)}</GridItem>
                </Grid>
              </div>
            );
          })}
        </div>
        <div data-testid={"cart_item_fixed_cost_rows-" + item.item_id} style={item.fixed_costs.length > 0 ? { paddingTop: 10, paddingBottom: 10, } : {}}>
          {item.fixed_costs.map(ic =>
            <Grid key={ic.item_cost_id} {...gridProps} data-testid={`cart_item_fixed_cost_row-${ic.item_cost_id}`} style={{ ...fixdchargeStyle, paddingTop: 10 }}>
              <GridItem colSpan={2}>{ic.item_cost_title}</GridItem>
              <GridItem className="right">{formatNumber(ic.quantity)}</GridItem>
              <GridItem className="right">{currency_symbol}{formatNumber(ic.unit_price, true)}</GridItem>
              <GridItem className="right">{currency_symbol}{formatNumber(ic.subtotal, true)}</GridItem>
            </Grid>
          )}
        </div>
        <Grid {...gridProps} data-testid={"cart_item_fixed_table_footer-" + item.item_id} style={{ ...fixdchargeStyle, paddingTop: 10 }}>
          <GridItem colSpan={2}>Product Total</GridItem>
          <GridItem
            className={'right'}
            style={{ color: (qtyLessThanMin) ? '#e0150d' : '#2a383f' }}
          >{formatNumber(item.quantity)}</GridItem>
          <GridItem
            className="right"
            colSpan={2}
          >{currency_symbol}{formatNumber(item.subtotal, true)}</GridItem>
        </Grid>
      </div>
    );
  }

  renderItemHeader() {
    const { item, is_shop_free, currency_symbol, isEditing, isCollapsed, template_color, buyInventory, } = this.props;
    const minimumQuantity = this.getMinQuantity();
    const qtyLessThanMin = !!item.quantity && item.quantity < minimumQuantity;
    const hasInventory = hasItemInventory(item, buyInventory);

    const minQtyErrText = (
      <Text as="p" style={{
        color: 'var(--color-errors-main)',
        fontFamily: fontFamilies.regular,
        fontSize: 16,
        paddingTop: 8,
        marginBottom: 0,
      }}>
        {`You have ${formatNumber(item.quantity)} items - minimum quantity of ${formatNumber(item.minimum_quantity)} not met!`}
      </Text>
    );

    return (
      <Row>
        <Col xs={2} sm={1.5}>
          <div
            id="img"
            style={{
              ...(+is_shop_free === 1 ? {
                paddingTop: item.colors.length > 1 ? '20px' : ''
              } : {}),
              width: '100%',
            }}
          ><Img style={{ maxWidth: 50, }} src={getImageSrc(item.image, 'medium')} alt={item.item_name} /></div>
        </Col>
        <Col xs={7} sm={7.5} md={8.5} xsStyle={{ marginBottom: 20 }} mdStyle={{ marginBottom: 5 }}>
          <Text as="span" style={{ fontSize: 16, color: 'var(--color-neutrals-100)' }}>{item.item_name}</Text>
          {!isEditing && isCollapsed  && <>
            {qtyLessThanMin ? minQtyErrText : <Text as="p" style={{ paddingTop: 8, marginBottom: 0, }}>
                <Csku as={Text} style={{ color: 'var(--color-neutrals-90)', fontFamily: fontFamilies.regular }}>
                  Quantity: {formatNumber(item.quantity)}
                </Csku>
                {+is_shop_free !== 1 && <Csku as={Text} style={{
                  xs: {
                    color: 'var(--color-neutrals-90)',
                    fontFamily: fontFamilies.regular,
                    display: 'block',
                  },
                  sm: { float: 'right', display: 'initial' },
                }}>
                  Total: {currency_symbol}{formatNumber(item.subtotal, true)}
                </Csku>}
              </Text>}
            {+is_shop_free !== 1 && <Text as="p">
              <TemplateTextIconButton
                template_color={template_color}
                Icon={<ChevronIcon direction={'down'} />}
                data-testid={`cart_item_view_details_btn-${item.item_id}`}
                onClick={this.props.onToggleShowDetails}
                variantHoverBg='transparent'
                style={{
                  marginLeft: '-28px',
                }}
              >View details</TemplateTextIconButton>
            </Text>}
          </>}
          {!isEditing && !isCollapsed && !hasInventory && (qtyLessThanMin ? minQtyErrText : <Text as="p" style={{ paddingTop: 8, marginBottom: 0, }}>
            <Text data-testid={`cart_item_min_qty_text-${item.item_id}`} style={{ color: 'var(--color-neutrals-80)', fontFamily: fontFamilies.regular }}>
              Minimum Quantity - {formatNumber(item.minimum_quantity)}
            </Text>
          </Text>)}
        </Col>
        {!isEditing && <Col xs={3} sm={3} md={2} style={{ textAlign: 'right' }} xsStyle={{ marginBottom: 20 }} mdStyle={{ marginBottom: 5 }}>
          {+is_shop_free !== 1 && <Csku
            as={TemplateButton}
            data-testid={`cart_item_edit_btn-${item.item_id}`}
            template_color={template_color}
            size='tiny'
            style={{
              marginBottom: 7,
              width: 56,
            }}
            sx={{
              xs: { marginRight: '0px !important', },
              sm: { marginRight: '7px !important', },
              md: { marginRight: '0px !important', },
            }}
            onClick={this.handleEditItem}
          >Edit</Csku>}
          <TemplateButton
            data-testid={`cart_item_delete_btn-${item.item_id}`}
            isSecondary
            template_color={template_color}
            size='tiny'
            onClick={this.handleDeleteItem}
            style={{ width: 56 }}
          >Delete</TemplateButton>
        </Col>}
      </Row>
    );
  }

  renderItemArtworks() {
    const { item, isEditing, client_id, tenant_id, } = this.props;

    return (
      !client_id && item.artworks.length > 0 && <div
        className="row"
        data-testid={`cart_item_artworks-${item.item_id}`}
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      >
        {item.artworks.map((a, i) =>
          <CartItemArtwork
            key={a.artwork_id}
            artwork={a}
            isEditing={isEditing}
            tenant_id={tenant_id}
            small={12}
            medium={6}
            large={6}
            usefileUploadIcon
            imgMaxHeight='72px'
            labelStyles={{ fontWeight: 'initial' }}
            imgContainerStyle={{
              border: '2px dashed var(--color-teal-main)',
              height: 92,
              backgroundColor: a.image ? 'white' : 'var(--color-neutrals-20)',
              padding: '8px',
            }}
            artworkContainerStyle={{
              backgroundColor: 'none',
              padding: `4px ${(i+1) % 2 ? 4 : 0}px 4px ${(i+1) % 2 ? 0 : 4}px`,
            }}
            style={{ padding: 0 }}
          />
        )}
      </div>
    );
  }

  renderProductQuestions(){
    const { metadata, shop_id } = this.props;

    return (metadata && Object.keys(metadata).length > 0 && <ProductQuestion metadata={metadata} shop_id={shop_id}/>);
  }

  render() {
    const {
      item,
      isEditing,
      template_color,
      showOnlyOptionSelectors = false,
      showOnlyBreakdowns = false,
      showHeader = false,
      showArtworks = false,
      isCollapsed = false,
      isInCart = true,
      productQuestions
    } = this.props;

    const colProps = {
      md: showOnlyBreakdowns ? 9 : 12,
      mdOffset: showOnlyBreakdowns ? 1.5 : 0,
      mdOffsetRight: showOnlyBreakdowns ? 1.5 : 0,
    };

    const isItemOpen = (!isCollapsed || isEditing) && isInCart;
    const item_id = item.item_id;
    const id = `cart_item-cart-${item_id}`;
    const hasError = !!this.state.cartProductQuestionError && !!this.state.cartProductQuestionError.error;

    if (!isEditing && isCollapsed) {
      return <div ref={this.root} id={id} style={!isInCart ? {} : { paddingTop: 16, paddingLeft: 16, paddingRight: 16 }}>
        <div>
          {this.renderItemHeader()}
        </div>
      </div>;
    }

    if (showOnlyOptionSelectors || showOnlyBreakdowns) {
      return <div ref={this.root} id={id}>
        <div style={{
          paddingBottom: 16,
          ...(isItemOpen ? {background: 'var(--color-neutrals-30)'} : {}),
        }}>
          <Row>
            <Col xs style={{
              paddingTop: 16,
              paddingLeft: 16,
              paddingRight: 16,
            }}>
              {showHeader && this.renderItemHeader()}
            </Col>
            <Col xs {...colProps} style={!isInCart ? {} : {paddingLeft: 16, paddingRight: 16}}>
              {showOnlyOptionSelectors ?
                this.renderOptionSelector(false) :
                this.renderItemBreakdownsTable()}
              {showOnlyOptionSelectors && this.renderOptionSelector(true)}
              {this.renderProductQuestions()}
            </Col>
            {showArtworks && <Col xs {...colProps} style={!isInCart ? {} : {paddingLeft: 16, paddingRight: 16}}>
              {this.renderItemArtworks()}
            </Col>}
            {!isEditing && !isCollapsed && showOnlyBreakdowns && <Col xs {...colProps} style={!isInCart ? {} : {paddingLeft: 16, paddingRight: 16}}>
              <TemplateTextIconButton
                template_color={template_color}
                data-testid={`cart_item_close_details_btn-${item.item_id}`}
                variantHoverBg='transparent'
                Icon={<ChevronIcon direction={'up'} />}
                onClick={this.props.onToggleShowDetails}
                style={{
                  marginLeft: '-28px',
                }}
              >Close details</TemplateTextIconButton>
            </Col>}
            {showOnlyBreakdowns && isEditing && <Col
              xs
              {...colProps}
              style={!isInCart ? {} : {paddingTop: 14, paddingLeft: 16, paddingRight: 16}}
            >
              <TemplateButton
                data-testid={`cart_item_save_btn-${item.item_id}`}
                size='large'
                template_color={template_color}
                style={{
                  width: '100%',
                  cursor: hasError ? 'not-allowed' : 'pointer',
                }}
                onClick={() => {
                  if(productQuestions.data && productQuestions.data[item_id] && hasError) return;
                  this.props.onCancelEditCartItem();
                }}
                disabled={(productQuestions.data && productQuestions.data[item_id] && hasError)}
              >Save</TemplateButton>
            </Col>}
          </Row>
        </div>
      </div>;
    }

    return (
      <div ref={this.root} id={id} style={{ paddingTop: 16, }}>
        {this.renderItemHeader()}
        {this.renderOptionSelector(false)}
        <div className="prdct-info">
          {this.renderOptionSelector(true)}
          {this.renderItemBreakdownsTable()}
        </div>
        {this.renderProductQuestions()}
        {this.renderItemArtworks()}
        <div style={{ clear: "both" }}></div>
      </div>
    );
  }
}

export default CartItem;
