import { get, first, last } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, colors, DoubleArrowIcon, fontFamilies, Grid, GridItem, LabeledIconInput, LabeledInput, Popup, Row, SearchIcon, Text, } from '@commonsku/styles';
import { getContactName } from '../../contact/utils';
import { hexToHSL, validateEmail } from '../../../utils';
import { TemplateButton, TemplateTextButton } from '../helpers';
import { useContainerScroll } from '../../../hooks';
import { ScrollContainer } from '../../../hooks/useContainerScroll';

const VIEW_TYPES = {
  CONTACTS_LIST: 'CONTACTS_LIST',
  ADD: 'ADD',
  ADD_NEW: 'ADD_NEW',
  ADD_UPDATE: 'ADD_UPDATE',
};

const SelectContactPopup = (props) => {
  const {
    selectedId,
    contacts=[],
    customContact = {},
    color = colors.primary1.main,
    onClose,
    onSelectContact,
    onAddContact,
    hideSelect,
    canAddCustomContact = false,
  } = props;

  const customContactNameSplit = (get(customContact, 'contact_name') || '').split(' ');

  /**
   * @type {React.MutableRefObject<HTMLDivElement>} popupRef
   */
  const popupRef = useRef(null);
  /**
   * @type {React.MutableRefObject<HTMLDivElement>} ref
   */
  const ref = useRef(null);

  const popupRect = popupRef.current ? popupRef.current.getBoundingClientRect() : {
    top: 0,
    bottom: 0,
    height: 0,
  };

  const [searchQuery, setSearchQuery] = useState('');
  const [viewType, setViewType] = useState(VIEW_TYPES.CONTACTS_LIST);
  const [showErrors, setShowErrors] = useState(false);
  const [newContact, setNewContact] = useState({
    contact_first_name: first(customContactNameSplit) || '',
    contact_last_name: (customContactNameSplit.length > 1 ? last(customContactNameSplit) : '') || '',
    contact_email: get(customContact, 'contact_email') || '',
  });

  const isAddView = [VIEW_TYPES.ADD_NEW, VIEW_TYPES.ADD_UPDATE].includes(viewType);
  const hasErrors = !newContact.contact_first_name || !validateEmail(newContact.contact_email);

  const popupMaxHeight = isAddView ? (showErrors && hasErrors ? 440 : 400) : 637;
  const {
    innerWidth,
    canScrollDown,
    canScrollUp,
    scrollDown,
    scrollUp,
  } = useContainerScroll(ref, [viewType, showErrors, searchQuery]);

  const contactsFilter = useMemo(() => {
    if (!searchQuery) { return contacts; }

    const query = searchQuery.toLowerCase().trim();
    return contacts.filter(
      con => getContactName(con).toLowerCase().includes(query)
          || (con.contact_email || '').toLowerCase().includes(query)
    );
  }, [contacts, searchQuery]);

  const onUpdateContactField = useCallback((field, value) => {
    setNewContact(s => ({ ...s, [field]: value }));
  }, []);

  const onCreateNewContact = () => {
    if (!newContact.contact_first_name || !validateEmail(newContact.contact_email)) {
      setShowErrors(true);
      return;
    }

    onAddContact({
      ...newContact,
      contact_name: `${newContact.contact_first_name || ''} ${newContact.contact_last_name || ''}`.trim(),
    });
    onClose();
  };

  useEffect(() => {
    if (contacts.length === 0
        && canAddCustomContact
        && (!get(customContact, 'contact_name')
          || !get(customContact, 'contact_email')
        )
    ) {
      setViewType(VIEW_TYPES.ADD_NEW);
    }
  }, [contacts, canAddCustomContact, customContact]);

  return (
    <Popup
      ref={popupRef}
      contentClassName={`select-contacts-popup-content ${isAddView ? 'add-new' : 'select-existing'}`}
      style={{
        maxWidth: isAddView ? 400 : 750,
        maxHeight: popupMaxHeight,
        overflow: 'hidden',
      }}
      header={<Row style={{ paddingBottom: 8 }}>
        <Col xs sm={8}>
          <Text style={{
            fontSize: '30px',
            fontFamily: fontFamilies.bold,
            color: 'var(--color-neutrals-100)',
          }} className="title">{isAddView ? 'Custom Contact' : 'Select Contact'}</Text>
        </Col>
        {!isAddView && <Col xs sm={4} style={{ textAlign: 'right' }}>
          <TemplateButton className="close_popup_btn" template_color={color} onClick={onClose}>Close</TemplateButton>
        </Col>}
      </Row>}
    >
      {viewType === VIEW_TYPES.CONTACTS_LIST && <Row>
        <Col
          xs
          padded
          style={{
            paddingTop: 24,
            position: 'sticky',
            top: 0,
            bottom: 'auto',
            background: '#fff',
          }}
        >
          <LabeledIconInput
            Icon={<SearchIcon />}
            label=''
            placeholder='Search'
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            iconLabelStyles={{ background: 'white', }}
            iconColor={colors.neutrals[50]}
          />
        </Col>
        <Col ref={ref} xs style={{
          paddingBottom: 10,
          minHeight: 100,
          maxHeight: `calc(${popupRect.height}px - ${innerWidth > 640 ? 220 : 280}px)`,
          overflow: 'auto',
        }}>
          <ContactsGrid
            contacts={contactsFilter}
            color={color}
            onSelectContact={(contactId) => {
              if (contactId === 'ADD') {
                setViewType(VIEW_TYPES.ADD_UPDATE);
                return;
              }
              onSelectContact && onSelectContact(contactId);
              onClose && onClose();
            }}
            selectedId={selectedId}
            customContact={newContact.contact_email ? newContact : null}
          />

          {contactsFilter.length > 0 && ref.current ? <>
            <ScrollContainer
              isUp
              onClick={scrollUp}
              canScroll={canScrollUp()}
              color={color}
              width={ref.current.clientWidth - 10}
              zIndex={4}
              top={150-18 + (innerWidth > 640 ? 0 : 40)}
              bottom={'auto'}
              left={22}
            />

            <ScrollContainer
              isUp={false}
              onClick={scrollDown}
              canScroll={canScrollDown()}
              color={color}
              width={ref.current.clientWidth - 10}
              zIndex={4}
              top={'auto'}
              bottom={innerWidth > 640 ? 44 : 64}
              left={22}
            />
          </> : null}
        </Col>
        {canAddCustomContact && <Col xs style={{
          textAlign: 'center',
          position: 'sticky',
          top: 'auto',
          bottom: 0,
          zIndex: 9,
          background: '#fff',
        }}>
          <TemplateTextButton
            className="add_custom_contact_btn"
            id="add_custom_contact_btn"
            template_color={color}
            onClick={() => {
              setViewType(VIEW_TYPES.ADD_UPDATE);
            }}
          >+ Add Custom Contact</TemplateTextButton>
        </Col>}
      </Row>}
      {isAddView && canAddCustomContact && <Row>
        <Col xs padded>
          <NewContactForm
            form={newContact}
            showErrors={showErrors}
            updateField={onUpdateContactField}
            onSubmit={onCreateNewContact}
            color={color}
            onCancel={() => {
              if (viewType === VIEW_TYPES.ADD_NEW) {
                onClose();
              } else {
                setViewType(VIEW_TYPES.CONTACTS_LIST);
              }
            }}
          />
        </Col>
      </Row>}
    </Popup>
  );
};

const NewContactForm = ({
  form,
  updateField,
  onSubmit,
  onCancel,
  color,
  showErrors=false
}) => {
  return (
    <Grid
      gap={15}
      columns={2}
      className="new_contact_form"
    >
      <GridItem colSpan={[2, 2, 1]}>
        <LabeledInput
          name='contact_first_name'
          id='contact_first_name'
          label='First Name'
          value={form.contact_first_name}
          onChange={e => updateField('contact_first_name', e.target.value)}
          style={{ marginBottom: 0 }}
          required
        />
        {showErrors && !form.contact_first_name && <Text as={"p"} style={{ color: colors.errors.main, marginBottom: 0 }}>
          This field is required
        </Text>}
      </GridItem>
      <GridItem colSpan={[2, 2, 1]}>
        <LabeledInput
          name='contact_last_name'
          id='contact_last_name'
          label='Last Name'
          value={form.contact_last_name}
          onChange={e => updateField('contact_last_name', e.target.value)}
          style={{ marginBottom: 0 }}
        />
      </GridItem>
      <GridItem colSpan={2} style={{ paddingBottom: 14, }}>
        <LabeledInput
          label='Email Address'
          name='contact_email'
          id='contact_email'
          value={form.contact_email}
          onChange={e => updateField('contact_email', e.target.value)}
          required
          style={{ marginBottom: 0 }}
        />
        {showErrors && !validateEmail(form.contact_email) && <Text as={"p"} style={{ color: colors.errors.main, marginBottom: 0 }}>
          This field is required
        </Text>}
      </GridItem>
      <GridItem colSpan={2}>
        <TemplateButton
          template_color={color}
          style={{ width: '100%' }}
          onClick={onSubmit}
          id='add_contact_btn'
        >Add Contact</TemplateButton>
      </GridItem>
      <GridItem colSpan={2}>
        <TemplateButton
          template_color={color}
          style={{ width: '100%' }}
          onClick={onCancel}
          isSecondary
          id='cancel_new_contact_btn'
        >Cancel</TemplateButton>
      </GridItem>
    </Grid>
  );
};

const ContactsGrid = React.forwardRef(
  ({
    color,
    selectedId,
    customContact,
    contacts=[],
    onSelectContact,
  }, ref) => {
    const hslColor = hexToHSL(color);
    return (
      <Grid
        ref={ref}
        gap={15}
        columns={3}
        style={{ padding: 10 }}
        className="contacts-grid"
      >
        {contacts.map(con => (
          <GridItem
            key={'contact-' + con.contact_id}
            id={'contact-' + con.contact_id}
            colSpan={{ xs: 3, sm: 1 }}
            style={{
              overflowWrap: 'break-word',
              padding: 10,
              background: color,
              borderRadius: 5,
              cursor: 'pointer',
              ...(selectedId === con.contact_id ? {
                background: `hsl(${hslColor[0]}, 100%, 35%)`,
              } : {}),
            }}
            onClick={() => {
              onSelectContact && onSelectContact(con.contact_id);
            }}
          >
            <Text
              as="p"
              style={{
                color: '#fff',
                fontWeight: 'bold',
                marginBottom: 5,
              }}>{getContactName(con)}</Text>
            <Text as="p" style={{ color: '#fff' }}>{con.contact_email}</Text>
          </GridItem>
        ))}
        {customContact && <GridItem
            key={'contact-custom'}
            colSpan={{ xs: 3, sm: 1 }}
            style={{
              overflowWrap: 'break-word',
              padding: 10,
              background: color,
              borderRadius: 5,
              cursor: 'pointer',
              ...(selectedId === 'ADD' ? {
                background: `hsl(${hslColor[0]}, 100%, 35%)`,
              } : {}),
            }}
            onClick={() => {
              onSelectContact && onSelectContact('ADD');
            }}
          >
            <Text
              as="p"
              style={{
                color: '#fff',
                fontWeight: 'bold',
                marginBottom: 5,
              }}>{getContactName(customContact)}</Text>
            <Text as="p" style={{ color: '#fff' }}>{customContact.contact_email}</Text>
          </GridItem>}
      </Grid>
    );
  }
);

export default SelectContactPopup;
