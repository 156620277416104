import React, { useRef } from "react";
import { Col, Csku, Popup, Row, Text, XIcon } from "@commonsku/styles";
import { getShopHelpScreenImage, TemplateButton } from './helpers';
import { useContainerScroll } from "../../hooks";
import { ArrowsIcon } from "../../hooks/useContainerScroll";
import { BASE_ZINDEX } from "../../popup-factory";
import { usePublicViewEntityContext } from "../../context/PublicViewEntityProvider";
import { usePublicViewTemplateDataContext } from "../../context/PublicViewTemplateDataProvider";

function IntroPopup({ onClose }) {
  const {
    isShop,
    entityBuyInventory,
    introduction,
    contactName,
    contactEmail,
  } = usePublicViewEntityContext();
  const {
    templateColor: template_color,
    template_data,
    template: { public_view_template_properties }
  } = usePublicViewTemplateDataContext();

  const helpScreenImage = getShopHelpScreenImage(template_data, public_view_template_properties);
  const hasImage = Boolean(helpScreenImage);

  const ref = useRef(null);
  const {
    canScrollDown,
    canScrollUp,
    scrollDown,
    scrollUp,
  } = useContainerScroll(ref, [
    entityBuyInventory,
    contactEmail,
    contactName,
    introduction
  ]);

  const scrollDownArrow = ref.current && <div onClick={scrollDown} style={{
    color: 'teal',
    fontSize: '3em',
    position: 'absolute',
    zIndex: BASE_ZINDEX + 999,
    bottom: '29%',
    width: ref.current.clientWidth,
    transition: 'visibility 0s linear 300ms, opacity 300ms',
    visibility: canScrollDown() ? 'visible' : 'hidden',
    opacity: canScrollDown() ? 1 : 0,
    right: 14,
    left: 0,
  }}>
    <ArrowsIcon direction="down" color={template_color} style={{ margin: 0 }} />
  </div>;

  const scrollUpArrow = ref.current && <div onClick={scrollUp} style={{
    color: 'teal',
    fontSize: '3em',
    position: 'absolute',
    zIndex: BASE_ZINDEX + 999,
    top: -20,
    width: ref.current.clientWidth,
    transition: 'visibility 0s linear 300ms, opacity 300ms',
    visibility: canScrollUp() ? 'visible' : 'hidden',
    opacity: canScrollUp() ? 1 : 0,
    right: 14,
  }}>
    <ArrowsIcon direction="up" color={template_color} style={{ margin: 0 }} />
  </div>;

  return (
    <Popup className="popup intro-popup" contentClassName="intro-popup-content" noHeader header={576} style={{ maxHeight: 576, maxWidth: 896 }} padding="0px">
      <Row style={{ height: '100%' }}>
        {hasImage && <Col xs={false} md={6} >
          <div style={{
            width: '100%',
            height: '100%',
            background: `url('${helpScreenImage}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}></div>
        </Col>}
        <Col xs md={hasImage ? 6 : 12} style={{ background: '#fff', flexDirection: 'column', display: 'flex', height: '100%' }}>
          <Row style={{ position: 'relative', flex: '1 1 auto', overflow: 'hidden', }}>
            <Csku forceStyles sx={{
              xs: {
                top: 16,
                right: 16,
              },
              sm: {
                top: 24,
                right: 24,
              }
            }} style={{
              position: 'absolute',
              zIndex: BASE_ZINDEX + 99999,
              width: 24,
              height: 24,
              textAlign: 'right',
              cursor: 'pointer',
            }} onClick={onClose}>
              <XIcon color={template_color} />
            </Csku>
            <Col ref={ref} xsStyle={{ width: '100%', }} style={{
              // position: 'fixed',
              top: 0,
              padding: 16,
              overflowY: 'auto',
              height: '65%',
              flex: '1 1 auto',
            }}>
              {scrollUpArrow}
              <Text as="p" style={{
                fontFamily: 'var(--font-family-bold)',
                fontSize: 48,
                color: 'var(--neutrals-90-body-text-2-a-4-d-63, #2A4D63)',
              }}>Welcome!</Text>
              {+entityBuyInventory === 1 ? <Text as="p" style={{
                fontFamily: 'var(--font-family-regular)',
                fontSize: 14,
                color: 'var(--collor-neutrals-80)',
              }}>Order items to add them to the inventory of this shop.</Text> : null}
              {!(+entityBuyInventory === 1) && introduction && introduction.replace(/<(?:.|\n)*?>/gm, '') !== '' ?
                <div dangerouslySetInnerHTML={{ __html: introduction }} />
                : null}
            </Col>
            <Col xsStyle={{ width: '100%' }} style={{
              // position: 'fixed',
              bottom: 0,
              background: 'var(--color-neutrals-20)',
              padding: 16,
              height: 'auto',
              minHeight: 158,
              flex: '0 0 auto',
            }}>
              {scrollDownArrow}
              <Csku as="p" forceStyles
                sx={{ xs: { marginTop: 0, }, md: { marginTop: '5%', } }}
                style={{
                  fontFamily: 'var(--font-family-regular)',
                  color: 'var(--color-neutrals-90)',
                  marginBottom: 0,
                }}>If you have any questions, please contact:</Csku>
              {contactName || contactEmail ? <p style={{ fontFamily: 'var(--font-family-regular)', color: 'var(--color-neutrals-90)', }}>
                {contactName}
                {contactEmail ? ' - ' : null}
                {contactEmail
                  ? <a style={{ color: template_color }} href={`mailto:${contactEmail}`}>{contactEmail}</a>
                  : null}
              </p> : null}
              <p style={{ marginBottom: 0 }}>
                <TemplateButton
                  style={{ width: hasImage ? '98%' : '100%', }}
                  template_color={template_color}
                  onClick={onClose}
                >{isShop ? 'Start Shopping' : 'Browse Products'}</TemplateButton>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Popup>
  );
}

export default IntroPopup;
