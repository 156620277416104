import React, { useMemo } from 'react';
import loadable from '@loadable/component';
import { usePublicViewTemplateDataContext } from '../../context/PublicViewTemplateDataProvider';

const TEMPLATE_MAP = {
  DefaultTemplate: loadable(
    () => import('./templates/DefaultTemplate'),
    { ssr: false }
  ),
  HeroBannerTemplate: loadable(
    () => import('./templates/HeroBannerTemplate'),
    { ssr: false }
  ),
  Template2: loadable(
    () => import('./templates/Template2'),
    { ssr: false }
  ),
  Template3: loadable(
    () => import('./templates/Template3'),
    { ssr: false }
  ),
  LeftNavHeroBannerTemplate: loadable(
    () => import('./templates/LeftNavHeroBannerTemplate'),
    { ssr: false }
  ),
};

const PublicViewTemplateComponent = ({ public_view_templates, ...props }) => {
  const { templateId } = usePublicViewTemplateDataContext();
  const TemplateComponent = useMemo(() => {
    let public_view_template = public_view_templates.find(
      st => st.public_view_template_id === templateId
    );
    if (public_view_template?.public_view_template_component) {
      return TEMPLATE_MAP[public_view_template.public_view_template_component] || TEMPLATE_MAP['DefaultTemplate'];
    }
    return TEMPLATE_MAP['DefaultTemplate'];
  }, [public_view_templates, templateId]);

  if (!TemplateComponent) {
    return null;
  }

  return (
    <TemplateComponent isEditable={true} {...props} />
  );
};

export default PublicViewTemplateComponent;
