import React from 'react';
import { freeItemColStyle, freeItemRowStyle } from './helpers';
import FreeItemOption, { OptionLabel } from './helpers/FreeItemOption';

const FreeShopCartItem = ({
  item,
  sizes,
  colors,
  template_color,
  size_Selected_value,
  color_selected_array,
  color_selected_value,
  onChangeFreeShopColor,
  onChangeFreeShopSize,
}) => {
  return (
    <div style={{ width: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }} id="free_shop_item_container">
      <div id="free_shop_item_sizes_container">
        <OptionLabel id="free_shop_item_size_label">Size</OptionLabel>
        <div name="sizes" id="free_shop_sizes" style={{
          paddingBottom: 16,
          maxHeight: 170,
          ...freeItemRowStyle
        }}>
          {sizes.map((s, idx) =>
            <div key={s.size_id}
              className="free_shop_size_column"
              style={freeItemColStyle}
            >
              <FreeItemOption
                axis={s}
                id={s.size_id}
                value={item.breakdowns.length > 0 ? [item.breakdowns[0].size_id] : []}
                label={s.size_name}
                isSelected={size_Selected_value == s.size_id}
                template_color={template_color}
                onChangeFreeShopAxis={e => onChangeFreeShopSize({ target: { value: s.size_id }})}
                className="free_shop_size_option"
              />
            </div>)}
        </div>
      </div>
      {0 < colors.length && <div style={{ paddingBottom: 16, marginTop: 16 }} id="free_shop_item_color_container">
        <OptionLabel id="free_shop_item_color_label">Colors</OptionLabel>
        <div name="work_days" id="free_shop_colors" style={{ maxHeight: 250, ...freeItemRowStyle }}>
          {colors.map((c, idx) =>
            <div key={`free_shop_item_color_${c.color_id}`}
              className="free_shop_color_column"
              style={freeItemColStyle}
            >
            <FreeItemOption
              axis={c}
              id={c.color_id}
              value={color_selected_array}
              label={c.color_name}
              isSelected={color_selected_value == c.color_id}
              template_color={template_color}
              onChangeFreeShopAxis={e => onChangeFreeShopColor({ target: { value: c.color_id }})}
              className="free_shop_color_option"
            />
          </div>)}
        </div>
      </div>}
    </div>
  );
};

export default FreeShopCartItem;
