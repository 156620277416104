import React from "react";
import { ArrowIcon, CommentIcon, IconButton } from "@commonsku/styles";
import { Medium, SmallOnly } from "../../ScreenSize";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";
import useMobileScreen from "../../../hooks/useMobileScreen";

export default function DetailMenu(props) {
  const {
    item,
    fullscreen,
    productInPopup,
    productInDropdown,
    onSelectItem,
    onSelectPage,

    showComments = true,
    hasSidebar = false,
  } = props;
  const {
    templateColor: template_color,
  } = usePublicViewTemplateDataContext();
  const mobileScreen = useMobileScreen();

  if (fullscreen) {
    return null;
  }

  const activeStyle = {
    background: 'white',
    color: 'black',
    fontWeight: 'normal',
    textAlign: 'center'
  };

  const inactiveStyle = {
    background: '#edf2f5',
    color: '#5ca3b6',
    fontWeight: 'normal',
    textAlign: 'center'
  };

  const commentCount = Object.values(item.comments || {}).length;
  const CloseIcon = (
    <Medium>
      <ul className="menu" style={{ cursor: 'pointer', marginBottom: '0.5rem' }}>
        <li className="menu-text" onClick={e => onSelectItem(null)}>
          <ArrowIcon direction="left" size="large" color={template_color} />
        </li>
      </ul>
    </Medium>
  );

  return (
    <div
      className="row small-12 columns"
      style={{
        padding: 0,
        marginLeft: 0,
        marginRight: 0,
        width: '100%',
        height: '40px',
        ...(mobileScreen ? {
          height: 0,
        } : {}),
    }}>
      {!productInPopup && !productInDropdown && !hasSidebar ? CloseIcon : null}
      <SmallOnly>
        {showComments > 0 ? <IconButton
          variant='custom'
          Icon={CommentIcon}
          variantColor={template_color}
          variantBorderColor={'transparent'}
          variantBg={'transparent'}
          variantHoverColor={template_color}
          style={{ float: 'right' }}
          onClick={e => onSelectPage('comment')}
        >
          <span style={{ verticalAlign: 'top', }}>Comment ({commentCount})</span>
        </IconButton> : null}
      </SmallOnly>
    </div>
  );
}
