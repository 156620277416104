import React from 'react';
import { getImageSrc } from '../../../utils';
import Img from '../../Img';

export default function GalleryImage({
  productInPopup,
  popupHeight=300,
  imageStyle,
  image,
  style={},
}) {
  return (
    <div style={{ height: productInPopup ? popupHeight : '98%', ...style }}>
      <Img style={imageStyle} src={getImageSrc(image, 'large')} />
    </div>
  );
}
