import React from "react";
import { colors, LabeledInput, Text } from "@commonsku/styles";
import { LabeledSelect } from "../../helpers";
import { usePublicViewEntityContext } from "../../../context/PublicViewEntityProvider";

export default function MetadataList({
  errors,
  showErrors,
  order_metadata,
  customer_po=null,

  handleChangeMetaData,
  onChangeCustomerPo,
}) {
  const { entity, entityBuyInventory } = usePublicViewEntityContext();

  const hasCustomerPOError = showErrors && entity.collect_customer_po === 'REQUIRED' && !customer_po;
  if (!!entityBuyInventory) {
    return null;
  }

  return (
    <div className="small-12 columns" style={{ padding: 0, paddingTop: 16 }}>
      {entity.collect_customer_po !== 'NO' && <>
        <LabeledInput
          id="metadata-customer_po"
          label='Customer PO'
          value={customer_po}
          onChange={e => onChangeCustomerPo(e.target.value)}
          placeholder="Customer PO"
          required={entity.collect_customer_po === 'REQUIRED'}
          error={hasCustomerPOError}
          style={{
            ...(hasCustomerPOError ? { marginBottom: 0 } : {}),
          }}
        />
        {hasCustomerPOError && <Text key={"error-metadata-customer_po"} as="p" style={{ marginBottom: 16, color: colors.errors.main }}>This field is required</Text>}
      </>}
      {Object.values(entity.metadata_list).map(m => {
        const value = (order_metadata[m.metadata_id] || { value: '' }).value;
        const isRequired = (''+m.mandatory) === '1';
        const hasError = showErrors && isRequired && (errors[`metadata_${m.metadata_id}`] || !value.trim());
        const options = m.type === 'SELECT' ? JSON.parse(m.value || '[]').map(v => ({ key: v, value: v })) : [];
        return ([
          m.type === 'SELECT' ?
          <LabeledSelect
            key={m.metadata_id}
            name={'metadata-item-' + m.metadata_id}
            id={'metadata-item-' + m.metadata_id}
            required={isRequired}
            label={`${m.label}${isRequired ? '' : ' (optional)'}`}
            placeholder={m.label}
            value={value}
            options={options}
            onChange={e => handleChangeMetaData(m.label, e.value, m.metadata_id)}
            error={hasError}
            isClearable={!isRequired}
            parentStyle={{ marginBottom: 14, }}
          />
        : <LabeledInput
            key={m.metadata_id}
            id={'metadata-item-' + m.metadata_id}
            required={isRequired}
            label={`${m.label}${isRequired ? '' : ' (optional)'}`}
            value={value}
            placeholder={m.label}
            onChange={e => handleChangeMetaData(m.label, e.target.value, m.metadata_id)}
            error={hasError}
            style={{
              ...(hasError ? { marginBottom: 0 } : {}),
            }}
          />,
          hasError && <Text key={"error-"+m.metadata_id} as="p" style={{ marginBottom: 16, color: colors.errors.main }}>This field is required</Text>,
        ]);
      })}
    </div>
  );
}
