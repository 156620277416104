import React, { useMemo, useState } from 'react';

import { getImageSrc } from '../../utils';
import { Col, Csku } from '@commonsku/styles';
import TileComments from './TileComments';
import { useSelector } from 'react-redux';
import { getItemComments } from '../../selectors/items';
import { DEFAULT_COLOR, hasInventory } from './utils';
import Inventory from './Inventory';
import Orders from './Orders';

function DefaultItemView(props) {
  const {
    item,
    displayPrice,
    is_shop_free,
    template_color,
    imgSize='large',
    children,
    onClick,
  } = props;
  return (
    <div id="feat-prod" className='item-preview' onClick={onClick}>
      <div className="prod-img" style={{
        backgroundImage: `url('${getImageSrc(item.images[0], imgSize)}')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        textAlign: 'center'
      }}>
      </div>
      <h4>{item.item_name}</h4>
      {+is_shop_free !== 1 && <p style={{ color: template_color }}>{displayPrice}</p>}
      {children}
    </div>
  );
}

const ITEM_PREVIEW_V2_CONTAINER_STYLE = {
  border: 'none',
  boxShadow: 'none',
  marginBottom: 24,
  position: 'relative',
};

const itemPreviewV2ContainerResponsiveStyle = (index) => ({
  xs: {
    height: '200px',
    paddingLeft: index % 2 === 0 ? "0" : "8px",
    paddingRight: index % 2 === 0 ? "8px" : "0",
  },
  sm: {
    height: '408px',
    paddingLeft: index % 3 === 0 ? "0" : "12px",
    paddingRight: index % 3 === 2 ? "0" : "12px",
  },
  md: {
    height: '408px',
  },
});

const ITEM_PREVIEW_V2_IMAGE_STYLE = {
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  minWidth: '100%',
  height: '100%',
  backgroundPosition: 'center',
};

const ITEM_PREVIEW_V2_TEXT_AREA_STYLE = {
  position: 'absolute',
  bottom: 0,
  top: 'auto',
  color: '#fff',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: 8,
  height: 100,
  background: 'linear-gradient(180deg, rgba(18, 18, 18, 0.00) 0%, #121212 100%)',
  transition: 'height 0.3s ease-in-out',
};

const ITEM_PREVIEW_V2_TEXT_STYLE = {
  lineHeight: '24px',
  fontFamily: 'Poppins',
  color: '#fff',
};

function ItemV2View({
  item,
  displayPrice,
  is_shop_free,
  children,
  onClick,
  index,
  itemFlexOrder,
  imgSize='large',
}) {
  const [hover, setHover] = React.useState(false);
  return (
    <Csku
      as={Col}
      xs={6}
      sm={4}
      id={'item-'+item.item_id}
      onClick={onClick}
      forceStyles
      style={{order: itemFlexOrder, ...ITEM_PREVIEW_V2_CONTAINER_STYLE}}
      sx={itemPreviewV2ContainerResponsiveStyle(index)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Csku
        as="div"
        forceStyles
        style={{
          backgroundImage: `url('${getImageSrc(item.images[0], imgSize)}')`,
          ...ITEM_PREVIEW_V2_IMAGE_STYLE,
        }}
      />
      <Csku
        as="div"
        forceStyles
        style={{
          ...ITEM_PREVIEW_V2_TEXT_AREA_STYLE,
          ...(hover ? { height: "100%" } : {  }),
        }}
        sx={{
          xs: { width: '100%' },
          sm: { width: index % 3 === 1 ? 'calc(100% - 12px)' : "100%" }
        }}
      >
        <p style={ITEM_PREVIEW_V2_TEXT_STYLE}>{item.item_name}</p>
        {+is_shop_free !== 1 && (
          <p style={ITEM_PREVIEW_V2_TEXT_STYLE}>{displayPrice}</p>
        )}
      </Csku>
      {children}
    </Csku>
  );
}

const Grid = ({
  item,
  index,
  total,
  padding,
  aggregate,
  is_shop_free,
  showComments,
  showInventory,
  buyInventory,
  currency_symbol,
  onClick,
  onAddItem,
  onRemoveItem,
  item_preview_version = '1',
  itemFlexOrder = 1,
  hide_pricing = '0',
  template_color = DEFAULT_COLOR,
  showItemCount = true,
  useV2 = false,
  gridNumberTop = null,
  gridNumberLeft = null,
  imgSize='large',
}) => {
  const itemComments = useSelector(s => getItemComments(s, { item_id: item.item_id }));

  const [hover, setHover] = useState(false);

  const displayPrice = useMemo(() => {
    if (+hide_pricing === 1) {
      return null;
    }
    let item_prices = [];
    if (showInventory && !buyInventory && (item.inventory_items ?? []).length > 0) {
      item_prices = item.inventory_items.map(ii => parseFloat(ii.price));
    } else if (item.options && item.options.length) {
      item_prices = item.options.filter(o => 0 == o.hidden && 0 != o.unit_price).map(o => parseFloat(o.unit_price));
    } else if(item.min_price && item.max_price) {
      item_prices = [item.min_price, item.max_price];
    }
    const minimum_price = Math.min.apply(null, item_prices);
    const maximum_price = Math.max.apply(null, item_prices);
    if (item_prices.length) {
      return (
        minimum_price === maximum_price
          ? `${currency_symbol}${minimum_price.toFixed(2)}`
          : `${currency_symbol}${minimum_price.toFixed(2)} - ${currency_symbol}${maximum_price.toFixed(2)}`
      );
    }
    return null;
  }, [item, showInventory, buyInventory, currency_symbol, hide_pricing]);

  if ('OPTION' !== item.parent_type) {
    return null;
  }

  const Child = (
    <>
      <TileComments
        item={item}
        comments={itemComments}
        showComments={showComments}
        left={0}
        top={0}
        numberLeft={Object.values(itemComments || item.comments).length > 99 ? 0 : (gridNumberLeft === null ? 6 : gridNumberLeft)}
        numberTop={(gridNumberTop === null ? 15 : gridNumberTop)}
      />
      <Orders
        hover={hover}
        item={item}
        padding={padding}
        aggregate={aggregate}
        onClick={onClick}
        onAddItem={onAddItem}
        onRemoveItem={onRemoveItem}
        template_color={template_color}
        showItemCount={showItemCount}
        useV2={useV2}
      />
      <Inventory
        item={item}
        showInventory={showInventory}
        template_color={template_color}
      />
    </>
  );

  if (item_preview_version === "2") {
    return (
      <ItemV2View
        item={item}
        displayPrice={displayPrice}
        is_shop_free={is_shop_free}
        template_color={template_color}
        onClick={(e) => (onClick && onClick())}
        hasInventory={hasInventory({ item, showInventory })}
        index={index}
        itemFlexOrder={itemFlexOrder}
        imgSize={imgSize}
      >{Child}</ItemV2View>
    );
  }

  return (
    <Csku as="div"
      id={'item-'+item.item_id}
      className={index === total
        ? `product-column small-12 medium-4 columns end`
        : `product-column small-12 medium-4 large-4 columns`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <DefaultItemView
        item={item}
        displayPrice={displayPrice}
        is_shop_free={is_shop_free}
        template_color={template_color}
        onClick={e => onClick ? onClick() : null}
        hasInventory={hasInventory({ item, showInventory })}
        index={index}
        imgSize={imgSize}
      >{Child}</DefaultItemView>
    </Csku>
  );
};

export default Grid;
