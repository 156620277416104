import React from 'react';
import { Csku } from '@commonsku/styles';

const Overlay = ({ style, ...props }) => (
  <Csku
    as={'div'}
    {...props}
    style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: 'rgba(42, 56, 63, 0.45)',
      display: 'flex',
      zIndex: 9,
      marginLeft: 'auto',
      marginRight: 'auto',
      ...style
    }} />
);

export default Overlay;
