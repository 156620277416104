import { get } from 'lodash';
import React from 'react';
import { Button } from '@commonsku/styles';
import { oauth } from '../../../utils';
import { usePublicViewEntityContext } from '../../../context/PublicViewEntityProvider';

const AvalaraShopTaxButton = ({ items, shipping_cost, address, discount, onChange, style={}, disabled=false }) => {
  const { entityId, entityType, isShop, } = usePublicViewEntityContext();

  return (
    <Button style={style} disabled={disabled} onClick={() => {
      oauth(
        'POST', 'avalara',
        {
          action: entityType.toLowerCase(),
          ...(isShop ? { shop_id: entityId } : { order_id: entityId }),
          shipping_cost,
          items,
          address,
          discount
        }
      ).then((response) => {
        onChange(get(response, 'json.transaction.summary', []));
      });
    }}>Estimate Tax</Button>
  );
};

export default AvalaraShopTaxButton;
